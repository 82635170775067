import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import {
    Button,
    Input,
    InputLabel,
} from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import upload from '../../assest/svgs/upload.svg';
import UploadPinkIcon from '../../assest/svgs/upload-pink-icon.svg';
import getImageUrls from "../../api/uploadImageOnS3";
import Loader from "../../assest/images/preloader.gif";
import { useState } from 'react';

const useStyle = makeStyles({
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    tableNew: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        overflowX: 'auto',
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        height: '70px',
        width: '17%'
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '10px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '80px',
        height: '20px',
        marginBottom: '4px'
    },
})

const OtherTable = (props) => {
    const { type } = useSelector(
        (state) => state.university
    );
    const [loaderIndex, setLoaderIndex] = useState();
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyle();
    const uploadImage = async(e, index) => {
        setLoaderIndex(index);
        const response = await getImageUrls({
            image: e.target.files[0],
            setLoading,
            setErrors,
        });
        props.profilePic[index] = response?.data?.urls[0];
    }
    return (
        <>
            <InputLabel className={classes.formInputLabel}>
                {props.title} {props.isOptionalText && <span className="optional-text-style">(Optional)</span>}
            </InputLabel>
            <div className={classes.tableNew} style={{width: props.tableWidth}}>
                <div className={classes.tableTh}>
                    <div className={classes.tableTd+' table-td'}>
                        {props?.subTitle[0]}
                    </div>
                    {props.names.map((item, index) => (
                        <div className={classes.tableTd} key={index} style={{padding: 0}}>
                            <input 
                                type={props?.title === "Placement Records" ? 
                                'number' : 'text'}
                                value={item} 
                                onChange={(e) => {
                                    let names = e.target.value;
                                    props.names[index] = names;
                                    props.setNames(props.names);
                                }}
                                style={{
                                    padding: '12px',
                                    width: '100%',
                                    // backgroundColor: 'var(--primary-background)',
                                    height: '44px',
                                    border: 'none'}}/>
                        </div>
                    ))}
                </div>
                {(props.isRank && props?.title !== "Placement Records") && 
                <div className={classes.tableTh}>
                    <div className={classes.tableTd+' table-td'}>
                        {props?.subTitle[1]}
                    </div>
                    {props.rank.map((item, index) => (
                        <div className={classes.tableTd} key={index} style={{padding: 0}}>
                            <input type={props?.subTitle[1] === 'Company name' ? "text" : "number"} value={item}
                                onChange={(e) => {
                                    let ranks = e.target.value;
                                    props.rank[index] = ranks;
                                    props.setRank(props.rank);
                                }}
                                style={{
                                    padding: '12px',
                                    width: '100%',
                                    height: '44px',
                                    border: 'none'}} />
                        </div>
                    ))}
                </div>}
                <div className={classes.tableTh}>
                    <div className={classes.tableTd+' table-td'}>
                        {props?.title !== "Placement Records" ? props?.subTitle[2] : 
                        props?.subTitle[1]}
                    </div>
                    {props.experience.map((item, index) => (
                        <div className={classes.tableTd} key={index} style={{padding: 0}}>
                            <input type="number" value={item}
                                onChange={(e) => {
                                    let experience = e.target.value;
                                    props.experience[index] = experience;
                                    props.setExperience(props.experience);
                                }}
                                style={{
                                    padding: '12px',
                                    width: '100%',
                                    height: '44px',
                                    border: 'none'}} />
                        </div>
                    ))}
                </div>
                <div className={classes.tableTh} style={{border: 'none'}}>
                    <div className={classes.tableTd+' table-td'}>
                        {props?.title !== "Placement Records" ?
                        props?.subTitle[3] : props?.subTitle[2]}
                    </div>
                    {props.profilePic.map((item, index) => (
                        <div className={classes.tableTd} key={index} style={{
                            padding: 0}}>
                            <label htmlFor={`${props.uploadPicFor}_${index}`} style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'center'
                            }}>
                                {item ? 
                                    <span style={{
                                        fontSize: '12px',
                                        color: 'rgb(0, 0, 0, 0.5)',
                                        lineHeight: '14px',
                                        marginBottom: '4px'
                                    }}>{item.split('/')[3]?.split("-")[1]}</span>
                                    : <img src={type === "University" ? upload : 
                                        UploadPinkIcon} 
                                    alt="upload" width="20" height="32" />}
                                <Input
                                    id={`${props.uploadPicFor}_${index}`}
                                    name="logo-upload"
                                    type="file"
                                    accept="application/pdf"
                                    sx={{display: 'none'}}
                                    onChange={(e) => {uploadImage(e, index)}}
                                />
                                <Button component="div" className={classes.upload}>
                                    <UploadFileIcon fontSize="15" />
                                    {(loading && (loaderIndex === index)) ? 
                                    <img src={Loader} width="12" alt="Loader" /> : 
                                        <span>{'Upload'}</span>}
                                </Button>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default OtherTable;