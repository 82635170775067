import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  IconButton, 
  Typography, 
  TextField } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import PartnerTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Spinner from "../../components/Spinner/Spinner";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoaderIcon from "../../components/loader/Loader";
import { toast } from "react-toastify";
import { 
    getLeads,
    getLeadById,
    leadStatusUpdate
  } from "../../redux/lgs/lgsSlice";

let requestBody = {
  id: "",
  status: "rejected",
  reject_reason: "",
  from: "reject"
};

let reviewBody = {
  id: "",
  request_status: "review",
  changes: [],
  from: "review",
}
const LeadPending = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRejectModel, setIsRejectModel] = useState(false);
  const [requestFormBody, setRequestFormBody] = useState(requestBody);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isOnApprove, setIsOnApprove] = useState(false);
  const [instId, setInstId] = useState("");
  const colR = ["SNo", "Name", "Phone Number", "Email ID", "Status", "actions"];

  const { 
    isLoading,
    isError, 
    message,
    totalCount,
    searchText,
    leadData } = useSelector(
    (state) => state.lead
  );

  useEffect(() => {
    const res = dispatch(getLeads({ limit, offset, searchText, status: "pending" }));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getLeads({ limit, offset, searchText, status: "pending" }))
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };
 
  if (isLoading ) return <Spinner />;
  if (isError ) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnLoader(true);
    const res = dispatch(leadStatusUpdate(requestFormBody));
    res.then((r) => {
        setBtnLoader(false);
        if(r.payload.statusCode === 200){
            toast.success("LGS Request Rejected");
            dispatch(getLeads({ limit, offset, searchText: "", status: "pending" }));
            setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
    })
  }
  const openApproveAlert = (id) => {
    setIsDialogOpen(true);
    setIsOnApprove(true);
    setIsRejectModel(false);
    setInstId(id);
  }
  const approveClicked = (id, from) => {
    const res = dispatch(leadStatusUpdate({id, from, status: 'accepted'}));
      res.then((r) => {
        if(r.payload.statusCode === 200){
          toast.success("LGS Accepted Successfully");
          dispatch(getLeads({ limit, offset, searchText: "", status: "pending" }));
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
    })
  }

  const viewDetail = (id) => {
    const res = dispatch(getLeadById({id}));
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        navigate(routes.LEAD_DETAIL)
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  return (
    <>
      <Dialog maxWidth={isOnApprove ? "xs" : "md"} open={isDialogOpen} onClose={handleClose} fullWidth>
        {!isOnApprove ?
        <form onSubmit={handleSubmit} style={{padding: '0 20% 2% 20%'}}>
          <DialogContent>
            <Typography component="h2" variant="h2" 
              sx={{
                color: '#D60000',
                textAlign: 'center',
                fontSize: '28px',
                fontWeight: 'bold',
                margin: '24px 0 48px'}}>
              Rejected
            </Typography>
            <div style={{
              width: '100%',
              border: '1px solid #000000',
              height: '290px'
            }} className="reject-buttons">
              <TextField fullWidth 
                placeholder={"Write a reason for rejection"} 
                variant="outlined"
                multiline
                rows={7}
                sx={{border: 'none'}}
                required
                onChange={(e) => 
                  setRequestFormBody({...requestFormBody, reject_reason: e.target.value})} />
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '36px 12px 0 0'
              }}>
                <Button 
                  variant="contained" 
                  type="submit" 
                  disabled={btnLoader}
                  sx={{
                    backgroundColor: btnLoader ? 'var(--disable-color)' : '#07AB35',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '140px',
                    height: '44px',
                    marginLeft: '12px',
                    borderRadius: '8px'
                    }}>
                  {btnLoader ? <LoaderIcon /> :'Submit'}
                </Button>
              </div>
            </div>
          </DialogContent>
        </form>: 
        <div>
          <Typography component="p" variant="p" 
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              margin: '28px 0 48px'}}>
            Are you sure you want to<br/> approve the LGS request?
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: '0px 16px 28px 16px'
          }}>
            <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
              textTransform: 'capitalize'
            }}>
              Cancel
            </Button>
            <Button onClick={() => {approveClicked(instId, "Approve")}} variant="contained" sx={{
              backgroundColor: 'var(--primary-color)',
              width: '84px',
              marginRight: '12px',
              textTransform: 'capitalize'
            }}>
              Yes
            </Button>
          </div>
        </div>
        }
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="lead"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <PartnerTable
          columns={colR}
          where="recipes"
          rows={leadData && leadData?.map((item) => {
            const res = {
              ...item,
              Status: (
                <Typography component="p" variant="p" 
                sx={{
                    color: '#ECAB2D',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    }}>
                    Pending
                </Typography>
              ),
              actions: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={() => viewDetail(item?._id)}
                  >
                    <VisibilityIcon sx={{color: '#3C1CDE'}} />
                    <Typography component="p" variant="p" 
                    sx={{
                        color: '#3C1CDE',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                    View
                    </Typography>
                  </IconButton>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '8px'
                    }}
                    onClick={() => {
                      setIsDialogOpen(true);
                      setIsRejectModel(true);
                      setIsOnApprove(false);
                      setRequestFormBody({...requestFormBody, id: item._id})
                    }}
                  >
                    <DoDisturbIcon sx={{color: '#EF0000'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#EF0000',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Reject
                    </Typography>
                  </IconButton>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 8px'
                    }}
                    onClick={() => openApproveAlert(item._id)}
                  >
                    <CheckCircleOutlineIcon sx={{color: '#07AB35'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#07AB35',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Approve
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          })}
        />
      </div>
    </>
  );
};

export default LeadPending;
