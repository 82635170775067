import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  IconButton, 
  Typography, 
  TextField } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import PartnerTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import { 
  getPartners, 
  setPartnerById,
  partnerStatusUpdate
} from "../../redux/partner/partnerSlice";
import Spinner from "../../components/Spinner/Spinner";
import NotesIcon from "../../assest/images/notes.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoaderIcon from "../../components/loader/Loader";
import { toast } from "react-toastify";

let requestBody = {
  id: "",
  request_status: "rejected",
  reject_reason: "",
  from: "reject",
};

let reviewBody = {
  id: "",
  request_status: "review",
  changes: [],
  from: "review",
}
const PartnerRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
    isCLoading,
    isCError } = useSelector((state) => state.coaching);
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRejectModel, setIsRejectModel] = useState(false);
  const [isRequestModel, setIsRequestModel] = useState(false);
  const [requestFormBody, setRequestFormBody] = useState(requestBody);
  const [reviewFormBody, setReviewFormBody] = useState(reviewBody);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isOnApprove, setIsOnApprove] = useState(false);
  const [instId, setInstId] = useState("");
  const [actionFrom, setActionFrom] = useState("");
  const colR = ["SNo", "Name", "Experience", "Type", "Category Domain", "actions"];

  const { 
    isLoading,
    isError, 
    message,
    totalCount,
    searchText,
    partnersData } = useSelector(
    (state) => state.partner
  );

  useEffect(() => {
    const res = dispatch(getPartners({ limit, offset, searchText, request_status: "review" }));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getPartners({ limit, offset, searchText, request_status: "review" }))
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };
 
  if (isLoading || isCLoading) return <Spinner />;
  if (isError || isCError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(isRejectModel){
      setBtnLoader(true);
      const res = dispatch(partnerStatusUpdate(requestFormBody));
      res.then((r) => {
        setBtnLoader(false);
        if(r.payload.statusCode === 200){
          toast.success("Partner Request Rejected");
          dispatch(getPartners({ limit, offset, searchText: "", request_status: "review" }));
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }
    if(isRequestModel){
      setBtnLoader(true);
      const res = dispatch(partnerStatusUpdate(reviewFormBody));
      res.then((r) => {
        setBtnLoader(false);
        if(r.payload.statusCode === 200){
          toast.success("Partner Request Changes Sent");
          dispatch(getPartners({ limit, offset, searchText: "", request_status: "review" }));
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }
  }
  const openApproveAlert = (id, from) => {
    setIsDialogOpen(true);
    setIsOnApprove(true);
    setIsRejectModel(false);
    setIsRequestModel(false);
    setInstId(id);
    setActionFrom(from);
  }
  const approveClicked = (id, from) => {
    const res = dispatch(partnerStatusUpdate({id, from, request_status: 'registration'}));
      res.then((r) => {
        if(r.payload.statusCode === 200){
          toast.success("Request Accepted For Document Uploads");
          dispatch(getPartners({ limit, offset, searchText: "", request_status: "review" }));
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
    })
  }
  return (
    <>
      <Dialog maxWidth={isOnApprove ? "xs" : "md"} open={isDialogOpen} onClose={handleClose} fullWidth>
        {!isOnApprove ?
        <form onSubmit={handleSubmit} style={{padding: '0 20% 2% 20%'}}>
          <DialogContent>
            <Typography component="h2" variant="h2" 
              sx={{
                color: '#D60000',
                textAlign: 'center',
                fontSize: '28px',
                fontWeight: 'bold',
                margin: '24px 0 48px'}}>
              {isRequestModel ? 'Request changes' : 'Rejected'}
            </Typography>
            <div style={{
              width: '100%',
              border: '1px solid #000000',
              height: '290px'
            }} className="reject-buttons">
              <TextField fullWidth 
                placeholder={isRequestModel ? "What changes do you need ?" :"Write a reason for rejection"} 
                variant="outlined"
                multiline
                rows={7}
                sx={{border: 'none'}}
                required
                onChange={(e) => 
                  !isRequestModel ? 
                  setRequestFormBody({...requestFormBody, reject_reason: e.target.value}):
                  setReviewFormBody({
                    ...reviewFormBody, changes: e.target.value.split("\n")
                  })} />
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '36px 12px 0 0'
              }}>
                <Button 
                  variant="contained" 
                  type="submit" 
                  disabled={btnLoader}
                  sx={{
                    backgroundColor: btnLoader ? 'var(--disable-color)' : '#07AB35',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '140px',
                    height: '44px',
                    marginLeft: '12px',
                    borderRadius: '8px'
                    }}>
                  {btnLoader ? <LoaderIcon /> :'Submit'}
                </Button>
              </div>
            </div>
          </DialogContent>
        </form>: 
        <div>
          <Typography component="p" variant="p" 
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              margin: '28px 0 48px'}}>
            Are you sure you want to<br/> approve the application form?
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: '0px 16px 28px 16px'
          }}>
            <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
              textTransform: 'capitalize'
            }}>
              Cancel
            </Button>
            <Button onClick={() => {approveClicked(instId, actionFrom)}} variant="contained" sx={{
              backgroundColor: 'var(--primary-color)',
              width: '84px',
              marginRight: '12px',
              textTransform: 'capitalize'
            }}>
              Yes
            </Button>
          </div>
        </div>
        }
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="partner"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <PartnerTable
          columns={colR}
          where="recipes"
          rows={partnersData && partnersData?.map((item) => {
            const res = {
              ...item,
              actions: (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={() => {
                      dispatch(setPartnerById(item._id));
                    }}
                  >
                    <Link to={routes.PARTNER_DETAILS} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                    </Link>
                  </IconButton>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 8px'
                    }}
                    onClick={() => openApproveAlert(item._id, "approve")}
                  >
                    <CheckCircleOutlineIcon sx={{color: '#07AB35'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#07AB35',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Approve
                    </Typography>
                  </IconButton>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={() => {
                      setIsRejectModel(false);
                      setIsDialogOpen(true);
                      setIsRequestModel(true);
                      setIsOnApprove(false);
                      setReviewFormBody({...reviewFormBody, id: item._id})
                    }}
                  >
                    <img src={NotesIcon} alt="notes_logo" width="20" />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#ECAB2D',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '9px',
                        }}>
                      Requested
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          })}
        />
      </div>
    </>
  );
};

export default PartnerRequest;
