import axios from "axios";
import endpoint from "../../index.routes";

const getPartners = async (params) => {
    const { limit, offset, searchText, request_status } = params;
    const response = await axios.get(
      endpoint.PARTNER,
      {
        params: searchText ? { limit, offset, searchText, request_status } : { limit, offset, request_status },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    console.log("data=============>mu>>>>",response.data);
    return response.data;
};

const getPartnerById = async (params) => {
    console.log("hello",params)
  const response = await axios.get(
    endpoint.PARTNER + "/" + params.partnerId,
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const partnerStatusUpdate = async (params) => {
  let body = {}
  let id = params.id;
  if(params.from === "reject"){
    body = {
        request_status: params.request_status,
        reject_reason: params.reject_reason,
    }
  }
  if(params.from === "review"){
    body = {
        request_status: params.request_status,
        changes : params.changes,
    }
  }
  if(params.from === "approve"){
    body = {
        request_status: params.request_status,
    }
  }
  const response = await axios.put(
    endpoint.PARTNER + "/" + id, body,
    {
      headers: { token: localStorage.getItem("hp_token") }
    }
  );
  return response.data;
}

const productUpdate = async (params) => {
  let id = params._id;
  let body = {};
  if(params?.coaching_id){
    body = {
      name: params.name,
      category: params.category,
      product_type: params.product_type,
      payment_mode: params.payment_mode,
      plan: params.plan,
      description: params.description,
      syllabus_url: params.syllabus_url,
      images_url: params.images_url,
      top_faculties: params.top_faculties,
      toppers: params.toppers,
      sub_products: params.sub_products,
      add_ons: params.add_ons
    }
  }else{
    body = {
      name: params.name,
      category: params.category,
      product_type: params.product_type,
      branches: params.branches,
      course_type: params.course_type,
      description: params.description,
      syllabus_url: params.syllabus_url,
      images_url: params.images_url,
      toppers: params.toppers,
      sub_products: params.sub_products,
      add_ons: params.add_ons
    }
  }
  console.log("=========================|========",endpoint.INSTITUTE_PROD+"/"+id)
  const response = await axios.put(endpoint.INSTITUTE_PROD + "/" + id, body,
    {
      headers: { token: localStorage.getItem("hp_token") }
    }
  );
  return response.data;
}

const partnerServices = { 
    getPartners,
    partnerStatusUpdate,
    productUpdate,
    getPartnerById
};

export default partnerServices;
