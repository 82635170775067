import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  Typography, 
  InputLabel,
  TextField } from "@mui/material";
import routes from "../../react_routes_enum";
import Spinner from "../../components/Spinner/Spinner";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { isEmpty } from "../../utils/isObjectEmpty";
import { getUniversityById } from "../../redux/universities/universitySlice";
import { getCoachingById } from "../../redux/coaching/coachingSlice";

const useStyles = makeStyles( theme => ({
  startButton: {
    backgroundColor: '#672F8F !important', 
    color: '#fff !important', 
    width: '140px',
    marginTop: '16px !important',
    "&:hover": {
        backgroundColor: "#672F8F !important",
    },
}
}))
const Details = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { 
    universityId,
    universityById, 
    isLoading,
    isError,
    message } = useSelector(
    (state) => state.university
  );
  const { coachingById } = useSelector((state) => state.coaching);
  const { type } = useSelector(
    (state) => state.university
  );

  useEffect(() => {
    let res = null;
    if(!universityId) naviagte(-1)
    if(type === "University"){
      res = dispatch(getUniversityById({universityId}));
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          naviagte(routes.LOGIN);
        }
      })
    }else{
      res = dispatch(getCoachingById({universityId}));
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          naviagte(routes.LOGIN);
        }
      })
    }
  }, [universityId, type]);

  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;

  const handleChange = () => {

  }
  return (
    <>
      <h3 style={{
          fontSize: '30px',
          textAlign: 'center',
          marginBottom: '0px'
        }}>
          {'Details'}
        </h3>
      <div style={{
        textAlign: 'right'
      }}>
        {console.log("=========",isEmpty(universityById), isEmpty(coachingById))}
        <Button 
          disabled={
            (!universityById && !coachingById) || 
            (isEmpty(universityById) && isEmpty(coachingById))}
          className={classes.startButton} size="large">
          <Link to={routes.CREATE_UNIVERSITY} style={{
              color: '#fff',
              textTransform: 'capitalize'
          }}>
              Full Detail
          </Link>
        </Button>
      </div>
      <div style={{
        marginTop: '3%',
        display: 'flex',
        justifyContent: 'flex-start',
        height: '100%',
        }}>
        <form onSubmit={handleChange} style={{
          width: '52%',
          borderRight: '2px solid rgb(0, 0, 0, 0.5)',
          marginRight: '3%'
        }}>
            <div style={{
              display: 'flex',
            }}>
              <div>
                <InputLabel sx={{
                  color: '#000',
                  fontSize: '20px',
                  fontWeight: 'bold'
                }}>{type === "University" ? "Name of University" : "Name Of Owner"}</InputLabel>
                <TextField 
                  fullWidth
                  type="text" 
                  variant="outlined" 
                  sx={{width: '280px'}} 
                  value={(universityById || coachingById) ? 
                    (type === "University" ? universityById?.university_name : coachingById?.owner_name) : ""} />
              </div>
              <div style={{
                marginLeft: '4%'
              }}>
                <InputLabel sx={{
                  color: '#000',
                  fontSize: '20px',
                  fontWeight: 'bold'
                }}>Email ID</InputLabel>
                <TextField 
                  fullWidth 
                  type="email"
                  variant="outlined" 
                  sx={{width: '280px'}}
                  value={(universityById || coachingById) ? 
                    (type === "University" ? universityById?.email : coachingById?.email) : ""} />
              </div>
            </div>
            <div style={{width: '100%', height: '24px'}}></div>
            <div style={{
              display: 'flex',
            }}>
              <div>
                <InputLabel sx={{
                  color: '#000',
                  fontSize: '20px',
                  fontWeight: 'bold'
                }}>Phone Number</InputLabel>
                <TextField 
                  fullWidth
                  type="number"
                  variant="outlined" 
                  sx={{width: '280px'}}
                  value={(universityById || coachingById) ? 
                    (type === "University" ? universityById?.phone : coachingById?.phone) : ""} />
              </div>
              <div style={{
                marginLeft: '4%'
              }}>
                <InputLabel sx={{
                  color: '#000',
                  fontSize: '20px',
                  fontWeight: 'bold'
                }}>{type === "University" ? "Name of Chairperson or HODs" : "Name Of Coaching Center"}</InputLabel>
                <TextField 
                  fullWidth
                  type="text"
                  variant="outlined" 
                  sx={{width: '280px'}}
                  value={(universityById || coachingById) ? 
                    (type === "University" ? universityById?.name : coachingById?.coaching_name) : ""} />
              </div>
            </div>
            <div style={{width: '100%', height: '24px'}}></div>
            <InputLabel sx={{
              color: '#000',
              fontSize: '20px',
              fontWeight: 'bold'
            }}>Address Proof</InputLabel>
            {(universityById?.address_proof || coachingById?.address_proof) ?
            <a href={(universityById || coachingById) ? (type === "University" ? universityById?.address_proof : 
              coachingById?.address_proof) : ''} target="_blank" style={{color: '#3C1CDE', margin: 0}}
              rel="noreferrer">
              {(universityById || coachingById) && (type === "University" ? 
              universityById?.address_proof.split("/")[3]?.split("-")[1] :
              coachingById?.address_proof.split("/")[3]?.split("-")[1])}
            </a>:
            <p style={{
              margin: 0
            }}>
              Not Available
            </p>
            }
        </form>
        <div>
          <Typography component="h4" variant="h4" sx={{
              color: '#000',
              fontSize: '20px',
              fontWeight: 'bold'
            }}>
            Previous Changes requested 
          </Typography>
          {(coachingById?.requested_changes?.length > 0 ||
          universityById?.requested_changes?.length > 0) ?
          <ul style={{
            listStyleType: 'decimal',
            padding: 0
          }}>
            {coachingById?.requested_changes?.length > 0 && 
              coachingById?.requested_changes.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
            {universityById?.requested_changes?.length > 0 && 
              universityById?.requested_changes.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>:
          <p>No Previous Changes Found</p>
          }
        </div>
      </div>
    </>
  );
};

export default Details;
