import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import lgsServices from "./lgsServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalCount: 0,
  searchText: "",
  leadData: null,
  leadById: null,
};

export const getLeads = createAsyncThunk(
    "lead/getLeads",
    async (params, thunkAPI) => {
      try {
        return await lgsServices.getLeads(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getLeadById = createAsyncThunk(
  "lead/getLeadById",
  async (params, thunkAPI) => {
    try {
      return await lgsServices.getLeadById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const leadStatusUpdate = createAsyncThunk(
    "lead/leadStatusUpdate",
    async (params, thunkAPI) => {
      try {
        return await lgsServices.leadStatusUpdate(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

const leadSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setLeadSearchText: (state, action) => {
      state.searchText = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeads.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeads.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getLeads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.leadData = action.payload.data.result;
      })
      .addCase(getLeadById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeadById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getLeadById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.leadById = action.payload.data;
      })
      .addCase(leadStatusUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(leadStatusUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(leadStatusUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
  },
});

export const { setLeadSearchText } = leadSlice.actions;

export default leadSlice.reducer;
