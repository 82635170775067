import axios from "axios";
import endpoint from "../../index.routes";

const getImageUrls = async (params) => {
  const { image } = params;
  // console.log("service", image);
  const formData = new FormData();

  formData.append("image", image.image);
  formData.append("image", image.gif);

  // formData.append("gif", uploadImages.gif);
  console.log(formData);
  const response = await axios({
    method: "post",
    url: endpoint.UPLOAD_ON_S3,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data;
};

const uploadDataOnS3Services = { getImageUrls };

export default uploadDataOnS3Services;
