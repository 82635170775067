import * as React from 'react';
import { makeStyles } from "@mui/styles";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import upload from '../../assest/svgs/upload.svg';
import UploadPinkIcon from '../../assest/svgs/upload-pink-icon.svg';
import getImageUrls from "../../api/uploadImageOnS3";
import LoaderIcon from "../../components/loader/Loader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    Button,
    Input,
    Typography,
  } from "@mui/material";

const useStyle = makeStyles({
    root: {
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    }
})

const UploadBox = (props) => {
    const { type } = useSelector(
        (state) => state.university
    );
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState(false);
    const classes = useStyle();

    
    const handleChange = async(event) => {
        if(props.name === "syllabus" || props.name === "brochour-upload"){
            if(event.target.files[0]?.type !== "application/pdf"){
                toast.error("Invalid file type");
                return;
            }
            if(event.target.files[0]?.size > 1049000){
                toast.error("Image size must be less than 1MB");
                return;
            }else{
                const response = await getImageUrls({
                    image: event.target.files[0],
                    setLoading,
                    setErrors,
                });
                props.handleUpload(event.target.name, response?.data?.urls[0])
            }
            return;
        }
        if(props.name === "video"){
            if(event.target.files[0]?.type !== "video/mp4"){
                toast.error("Invalid file type");
                return;
            }
            if(event.target.files[0]?.size > 10490000){
                toast.error("Video size must be less than 10MB");
                return;
            }else{
                const response = await getImageUrls({
                    image: event.target.files[0],
                    setLoading,
                    setErrors,
                });
                props.handleUpload(event.target.name, response?.data?.urls[0])
            }
            return;
        }else{
            if(event.target.files[0]?.type !== "image/png" &&
            event.target.files[0]?.type !== "image/jpeg"){
                toast.error("Invalid file type");
                return;
            }
            if(event.target.files[0]?.size > 1049000){
                toast.error("Image size must be less than 1MB");
                return;
            }else{
                const response = await getImageUrls({
                    image: event.target.files[0],
                    setLoading,
                    setErrors,
                });
                props.handleUpload(event.target.name, response?.data?.urls[0])
            }
        }
        // const response = await getImageUrls({
        //     image: event.target.files[0],
        //     setLoading,
        //     setErrors,
        // });
        // props.handleUpload(event.target.name, response?.data?.urls[0])
    }
    return (
        <div className={classes.root} style={{
            margin: '8px 0 0 0',
            height: props.height ? props.height : '200px',
            width: props.width ? props.width : '200px'
        }}>
            <label htmlFor={props.name} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                justifyContent: 'center'
            }}>
                <img src={type === "University" ? upload : UploadPinkIcon} 
                    alt="upload" width="76" height="68" />
                <Typography component="h2" variant="h2" sx={{
                    fontSize: '15px',
                    margin: '12px 0 24px 0',
                    textAlign: 'center'
                    }}>
                    {props?.value ? (props?.value?.split("/")[3]?.split("-")[1]) : (
                        props.name === "video" ? ".mp4 format" : 
                            ((props.name === "syllabus" || 
                            props.name === "brochour-upload") ? ".pdf format" : 
                            ".png or .jpg format")
                        )}
                </Typography>
                <Input
                    id={props.name}
                    name={props.name}
                    type="file"
                    accept="application/pdf"
                    sx={{display: 'none'}}
                    onChange={handleChange}
                />
                <Button component="div" className={classes.upload}>
                    <UploadFileIcon fontSize="15" />
                    {loading ? <LoaderIcon/> : <span>{'Upload'}</span>}
                </Button>
            </label>
        </div>
    )
}

export default UploadBox;