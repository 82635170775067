import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instituteProdServices from "./instituteProdServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalCount: 0,
  searchText: "",
  productByInstitute: null,
  productById: null,
  instituteIdForProduct: null,
  addProductRes: null,
};

export const getProductByInstitute = createAsyncThunk(
    "product/getProductByInstitute",
    async (params, thunkAPI) => {
      try {
        return await instituteProdServices.getProductByInstitute(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const addProduct = createAsyncThunk(
  "product/addProduct",
  async (params, thunkAPI) => {
    try {
      return await instituteProdServices.addProduct(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProductById = createAsyncThunk(
    "product/getProductById",
    async (params, thunkAPI) => {
      try {
        return await instituteProdServices.getProductById(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const productStatusUpdate = createAsyncThunk(
  "product/productStatusUpdate",
  async (params, thunkAPI) => {
    try{
      return await instituteProdServices.productStatusUpdate(params);
    }catch(error){
      const message = 
        (error.response && 
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
          return thunkAPI.rejectWithValue(message);
    }
  }
);

export const productUpdate = createAsyncThunk(
  "product/productUpdate",
  async (params, thunkAPI) => {
    try{
      return await instituteProdServices.productUpdate(params);
    }catch(error){
      const message = 
        (error.response && 
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
          return thunkAPI.rejectWithValue(message);
    }
  }
);

const instituteProdSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setInstituteProdFilterDetails: (state, action) => {
      state.searchText = action.payload;
    },
    setInstituteIdForProduct: (state, action) => {
      state.instituteIdForProduct = action.payload;
    },
    setProductDetailsById: (state, action) => {
      state.productById = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductByInstitute.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductByInstitute.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getProductByInstitute.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.productByInstitute = action.payload.data.result;
      })
      .addCase(addProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.addProductRes = action.payload;
      })
      .addCase(getProductById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.productById = action.payload.data;
      })
      .addCase(productStatusUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(productStatusUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(productStatusUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(productUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(productUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(productUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
  },
});

export const { 
    setInstituteProdFilterDetails,
    setInstituteIdForProduct,
    setProductDetailsById } = instituteProdSlice.actions;

export default instituteProdSlice.reducer;
