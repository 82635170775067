import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  IconButton, 
  Typography } from "@mui/material";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import PartnerTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import { 
  getPartners, 
  setPartnerById,
  partnerStatusUpdate
} from "../../redux/partner/partnerSlice";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Spinner from "../../components/Spinner/Spinner";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from "react-toastify";

const PartnerRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const colR = ["SNo", "Name", "Experience", "Type", "Category Domain", "actions"];

  const { 
    isLoading,
    isError, 
    message,
    totalCount,
    searchText,
    partnersData } = useSelector(
    (state) => state.partner
  );

  useEffect(() => {
    const res = dispatch(getPartners({ limit, offset, searchText, request_status: "rejected" }));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getPartners({ limit, offset, searchText, request_status: "rejected" }))
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };
 
  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;
  
  const unDoReject = (id, from) => {
    const res = dispatch(partnerStatusUpdate({id: id, from: from, request_status: "pending"}));
    res.then((r) => {
        if(r.payload.statusCode === 200){
          toast.success("Partner Sent To Pending List");
          dispatch(getPartners({ limit, offset, searchText: "", request_status: "rejected" }));
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
    })
  }
  return (
    <>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="partner"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <PartnerTable
          columns={colR}
          where="recipes"
          rows={partnersData && partnersData?.map((item) => {
            const res = {
              ...item,
              actions: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={() => {
                      dispatch(setPartnerById(item._id));
                    }}
                  >
                    <Link to={routes.PARTNER_DETAILS} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                    </Link>
                  </IconButton>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 8px'
                    }}
                    onClick={() => unDoReject(item._id, "approve")}
                  >
                    <DoDisturbIcon sx={{color: '#07AB35'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#07AB35',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Undo
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          })}
        />
      </div>
    </>
  );
};

export default PartnerRequest;
