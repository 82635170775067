import * as React from 'react';
import { useSelector } from "react-redux";
import { Box, InputLabel, Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import BudgetIcon from "../../assest/images/budget_icon.png";
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import SchoolIcon from '@mui/icons-material/School';
import CurrentQual from  "../../assest/images/coaching_grey.png";
import CourseIcon from "../../assest/images/course_icon.png";
import Moment from "moment";

const budgetArray = [
  "Less than 500000", 
  "Between 500000-1000000", 
  "Between 1000000-2000000",
  "Above 2000000"
]

const leadData = {
  email: "",
  address: "",
  name: "",
  dob: "2011-04-14",
  phone: "",
  phone_country_code: "+91",
  current_school: "",
  current_qualification: "",
  courses: "",
  budget_in_lakhs: "",
  location: "",
  parent: {
    name: "",
    phone:"",
    phone_country_code:  "+91"
  }
}

export default function NewLead() {
  const [leadBody, setLeadBody] = React.useState(leadData);

  const { leadById } = useSelector((state) => state.lead);

  React.useEffect(() => {
      leadById && setLeadBody(leadById)
  },[leadById]);
  
  return (
    <>
      <div className="content-box table-box question-box-in">
        <h2 style={{textAlign: "center", marginTop: "24px"}}>
          Detail
        </h2>
        <form className="register-form-control">
          <div style={{display: "flex", marginBottom: "24px"}}>
            <div style={{width: "100%"}}>
              <InputLabel className="input-label-text">Name</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="name"
                  sx={{
                    width: '100%'
                  }}
                  value={leadBody?.name}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />                     
            </div>
            <div style={{width: "100%", marginLeft: '24px'}}>
              <InputLabel className="input-label-text">Address</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="address" 
                  sx={{
                    width: '100%'
                  }}
                  value={leadBody?.address}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                  />
            </div>
          </div>
          <div style={{display: "flex", marginBottom: "24px"}}>
            <div style={{width: "100%"}}>
              <InputLabel className="input-label-text">Date of Birth</InputLabel>
                <TextField 
                  placeholder="" 
                  type="date" 
                  inputProps={{
                    max: "2011-04-14"
                  }}
                  sx={{
                    width: '100%'
                  }}
                  variant="outlined" 
                  name="dob"
                  value={Moment(leadBody?.dob).format("YYYY-MM-DD")}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CakeIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />                     
            </div>
            <div style={{width: "100%", marginLeft: '24px'}}>
              <InputLabel className="input-label-text">Email Id</InputLabel>
                <TextField 
                  placeholder="" 
                  type="email" 
                  variant="outlined" 
                  name="email" 
                  sx={{
                    width: '100%'
                  }}
                  value={leadBody?.email}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
          </div>
          <div style={{display: "flex", marginBottom: "24px"}}>
            <div style={{width: "100%"}}>
              <InputLabel className="input-label-text">Phone Number</InputLabel>
              <div style={{display: 'flex'}}>
                <TextField 
                  className="country-code" 
                  value={leadBody?.phone_country_code}
                  name="phone_country_code"
                  sx={{
                    color: 'rgb(0, 0, 0)',
                    marginRight: '6px'}}>
                </TextField>
                <TextField 
                  placeholder="" 
                  type="number" 
                  variant="outlined" 
                  name="phone_number"
                  sx={{width: '100%'}}
                  value={leadBody?.phone}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />   
              </div>                  
            </div>
            <div style={{width: "100%", marginLeft: '24px'}}>
              <InputLabel className="input-label-text">Currently School/College
              <span style={{color:'#00000080', fontSize: '12px'}}>(Optional)</span></InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="current_college"
                  sx={{width: '100%'}} 
                  value={leadBody?.current_school}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SchoolIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
          </div>
          <div style={{display: "flex", marginBottom: "24px"}}>
            <div style={{width: "100%"}}>
              <InputLabel className="input-label-text">Current Qualification</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="current_qualification"
                  sx={{width: '100%'}}
                  value={leadBody?.current_qualification}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={CurrentQual} alt="" width="18" />
                      </InputAdornment>
                    ),
                  }}
                />                     
            </div>
            <div style={{width: "100%", marginLeft: '24px'}}>
              <InputLabel className="input-label-text">Courses</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="course" 
                  value={leadBody?.courses}
                  sx={{width: '100%'}}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={CourseIcon} alt="" width="18" />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
          </div>
          <div style={{display: "flex", marginBottom: "24px"}}>
            <div style={{width: "100%"}}>
              <InputLabel className="input-label-text">
                Budget{" "}
                <span style={{color:'#00000080', fontSize: '12px'}}>(in lakh)</span>
              </InputLabel>
              <Select
                name="budget"
                defaultValue=""
                displayEmpty
                value={leadBody?.budget_in_lakhs}
                sx={{width: '100%'}}
                renderValue={(value) => {
                  return (
                    <Box sx={{ 
                      display: "flex", 
                      gap: 1, 
                      alignItems: 'center'}}>
                      <img src={BudgetIcon} alt="" width="16"
                        style={value ? {marginTop: 0} : {marginTop: '8px'}}/>
                      {value}
                    </Box>
                  );
                }}
              >
                {budgetArray.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>))}
              </Select>
            </div>
            <div style={{width: "100%", marginLeft: '24px'}}>
              <InputLabel className="input-label-text">Location</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="location" 
                  value={leadBody?.location}
                  sx={{width: '100%'}}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
          </div>
          <div style={{display: "flex", marginBottom: "24px"}}>
            <div style={{width: '100%'}}>
              <InputLabel className="input-label-text">Parent's Name</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="p_name" 
                  value={leadBody?.parent?.name}
                  sx={{width: '100%'}}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
            <div style={{width: '100%', marginLeft: '24px'}}>
              <InputLabel className="input-label-text">Parent's Contact Number</InputLabel>
              <div style={{display: 'flex', width: '100%'}}>
                <TextField 
                  className="country-code" 
                  value={leadBody?.parent?.phone_country_code} 
                  name="p_phone_country_code"
                  sx={{
                    color: 'rgb(0, 0, 0)',
                    marginRight: '6px'}} />
                <TextField 
                  placeholder="" 
                  type="number" 
                  variant="outlined" 
                  name="p_phone_number"
                  value={leadBody?.parent?.phone}
                  sx={{width: '100%'}}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />   
              </div>                  
            </div>
          </div>
        </form>
      </div>
    </>
  );
}