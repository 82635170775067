import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { 
  Button, 
  TextField,
  Divider,
  InputLabel,
  IconButton,
  Typography } from "@mui/material";
import routes from "../../react_routes_enum";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import UserTable from "../../components/BasicTable/BasicTable";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useNavigate } from "react-router-dom";
import { 
  addSubAdmin, 
  getSubAdmin, 
  deleteSubAdmin,
  updateSubAdmin
} from "../../redux/sub-admin/subAdminSlice";
import VisibilityIcon from '@mui/icons-material/Visibility';
import validator from "validator";
import LoaderIcon from "../../components/loader/Loader";

var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

let subadmin = {
  name: "",
  phone: "",
  email: "",
  designation: ""
}
const SubscribedUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subAdminInfo, SetSubAdminInfo] = useState(subadmin);
  const [isSubmitAdmin, setIsSubmitAdmin] = useState(false);
 
  const {
    subAdminData,
    isError,
    message,
    isLoading,
    totalCount,
    searchText
    } = useSelector((state) => state.subAdmin);

  const [userListView, setUserListView] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [subAdminId, setSubAdminId] = useState("");

  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);

  const columns = [
    "SNo",
    "name",
    "phone",
    "email",
    "designation",
    "Action",
  ];

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      let res = null;
      searchText !== "" ?
      res = dispatch(
        getSubAdmin({
          limit: limit,
          offset: offset,
          searchText,
        })):
        res = dispatch(
        getSubAdmin({
          limit: limit,
          offset: offset,
        }))

      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" ||
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
      })
    }
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!isViewDetails && !isEditDetails){
      if(validate().status){
        setIsSubmitAdmin(true);
        const res = dispatch(addSubAdmin(subAdminInfo));
        res.then((r) => {
          setIsSubmitAdmin(false)
          if(r.payload.statusCode === 200){ 
            dispatch(getSubAdmin({ limit, offset }));
            toast.success("Sub Admin Added");
            setIsDialogOpen(false);
            return;
          }
          if(r?.payload?.message.toLowerCase() === "unauthorized" ||
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        })
      }else{
        toast.error(validate().message)
      }
    }else{
      if(isViewDetails){
        setIsSubmitAdmin(true);
        const res = dispatch(deleteSubAdmin(subAdminId));
        res.then((r) => {
          setIsSubmitAdmin(false)
          if(r.payload.statusCode === 200){ 
            dispatch(getSubAdmin({ limit, offset }));
            toast.success("Sub Admin Deleted");
            setIsDialogOpen(false);
          }else{
            if(r?.payload?.message.toLowerCase() === "unauthorized" ||
              r?.payload?.message === "Session Expired"){
              toast.error("Session expired, please login again");
              localStorage.removeItem("hp_token");
              localStorage.removeItem("hp_user");
              navigate(routes.LOGIN);
              return;
            }
            toast.error(r?.payload?.message);
          }
        })
      }else{
        let params = {
          id: subAdminId,
          name: subAdminInfo.name,
          designation: subAdminInfo.designation
        };
        if(validate().status){
          setIsSubmitAdmin(true);
          const res = dispatch(updateSubAdmin(params));
          res.then((r) => {
            setIsSubmitAdmin(false)
            if(r.payload.statusCode === 200){ 
              dispatch(getSubAdmin({ limit, offset }));
              toast.success("Sub Admin Updated");
              setIsDialogOpen(false);
              return;
            }else{
              if(r?.payload?.message.toLowerCase() === "unauthorized" ||
                r?.payload?.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
                return;
              }
              toast.error(r?.payload?.message);
            }
          })
        }else{
          toast.error(validate().message)
        }
      }
    }
    
  };

  useEffect(() => {
    const res = dispatch(getSubAdmin({ limit, offset }));
    res.then((r) => {
      if(r.payload.message.toLowerCase() === "unauthorized" || 
        r.payload.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes?.LOGIN);
        }
    })
  }, [limit, offset]);

  const inputValue = (e) => {
    e.target.name === "name" && SetSubAdminInfo({...subAdminInfo, name: e.target.value});
    e.target.name === "email" && SetSubAdminInfo({...subAdminInfo, email: e.target.value});
    e.target.name === "phone" && SetSubAdminInfo({...subAdminInfo, phone: e.target.value});
    e.target.name === "designation" && SetSubAdminInfo({...subAdminInfo, designation: e.target.value})
  }

  const validate = () => {
    let error = {status: true, message: ""}
    if(subAdminInfo.name.length < 4 || !nameValidator.test(subAdminInfo.name) || subAdminInfo.name.length > 20){
      subAdminInfo.name.length < 4 ? (error = {status: false, message: "Name must be at least 4 characters"}) :
        (error = {status: false, message: "Invalid Name"})
      subAdminInfo.name.length > 20 && (error = {status: false, message: "Name must be less than 20 characters"})
      return error;
    }
    if(!validator.isEmail(subAdminInfo.email)){
      error.status = false;
      error.message = "Invalid Email";
      return error;
    }
    if(subAdminInfo.phone === "" || subAdminInfo.phone.length !== 10){
      error.status = false;
      error.message = "Invalid Phone Number";
      return error;
    }
    if(subAdminInfo.designation.length < 4 || !nameValidator.test(subAdminInfo.designation) || 
        subAdminInfo.designation.length > 20){
      subAdminInfo.designation.length < 4 ? (error = {status: false, message: "Designation must be at least 4 characters"}) :
        (error = {status: false, message: "Invalid designation"})
      subAdminInfo.designation.length > 20 && (error = {status: false, message: "Designation must be less than 20 characters"})
      return error;
    }
    return error;
  }

  if (isLoading) return <Spinner />;

  if (isError) return <h1 style={{ color: "red" }}>{message}</h1>;
  
  return (
    <div style={{
      marginTop: '5%'
    }}>
      <Dialog maxWidth="md" open={isDialogOpen} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit} style={{padding: '0 20% 2% 20%'}}>
          <DialogContent>
            <h3 style={{
              fontSize: '28px',
              textAlign: 'center',
              marginBottom: '0px'
            }}>
              {isViewDetails || isEditDetails ? 
              (isViewDetails ? 'Detail' : 'Edit Sub Admin') : 'Create Sub-Admin'}
            </h3>
            {(!isViewDetails && !isEditDetails) && <Typography 
              variant="p" 
              component="p"
              sx={{
                fontSize: '16px',
                color: '#000',
                opacity: 0.5,
                textAlign: 'center',
                marginBottom: '24px',
                fontWeight: 'bold'
              }}>
              Create sub admin and assign a role
            </Typography>}
            {(!isViewDetails && !isEditDetails) && <Divider sx={{ background: "rgb(0, 0, 0, 0.1)" }} />}
            {(!isViewDetails && !isEditDetails) && 
              <h3 style={{
                fontSize: '20px',
                textAlign: 'center',
                marginBottom: '0px',
                fontWeight: 'bold'
              }}>Sub-Admin Details</h3>
            }
            <div style={{
              height: '40px',
              width: '100%'
            }}></div>
            <div>
              <InputLabel sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 'bold'
              }}>Name</InputLabel>
              <TextField 
                fullWidth 
                value={subAdminInfo.name} 
                disabled={isViewDetails}
                type="text" 
                name="name" 
                variant="outlined" 
                onChange={inputValue} 
                required />
            </div>
            <div style={{width: '100%', height: '24px'}}></div>
            <div>
              <InputLabel sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 'bold'
              }}>Email</InputLabel>
              <TextField 
                fullWidth 
                value={subAdminInfo.email} 
                disabled={(isViewDetails || isEditDetails) && true}
                type="email" 
                name="email" 
                variant="outlined" 
                onChange={inputValue} 
                required />
            </div>
            <div style={{width: '100%', height: '24px'}}></div>
            <div>
              <InputLabel sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 'bold'
              }}>Phone Number</InputLabel>
              <TextField 
                fullWidth 
                value={subAdminInfo.phone} 
                disabled={(isViewDetails || isEditDetails) && true}
                type="number" 
                name="phone" 
                onChange={inputValue} 
                variant="outlined" 
                required />
            </div>
            <div style={{width: '100%', height: '24px'}}></div>
            <div>
              <InputLabel sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 'bold'
              }}>Designation</InputLabel>
              <TextField 
                fullWidth 
                disabled={isViewDetails}
                value={subAdminInfo.designation} 
                type="text" 
                name="designation" 
                onChange={inputValue} 
                variant="outlined" 
                required />
            </div>
          </DialogContent>
          <DialogActions sx={{justifyContent: 'center'}}>
            <Button 
              variant="contained" 
              type="submit" 
              disabled={isSubmitAdmin}
              sx={{
                backgroundColor: isSubmitAdmin ? 'var(--disable-color)' : 'var(--primary-color)',
                textTransform: 'capitalize',
                fontSize: '18px',
                width: '144px',
                height: '44px'
                }}>
              {isSubmitAdmin ?
              <LoaderIcon /> : (isViewDetails || isEditDetails ? 
                (isViewDetails ? 'Delete' : 'Update') : 'Submit')}
            </Button>
            {(!isViewDetails && !isEditDetails) &&
            <Button 
              variant="outlined" 
              onClick={handleClose} 
              sx={{
                color: 'var(--primary-color)',
                textTransform: 'capitalize',
                borderColor: 'var(--primary-color)',
                fontSize: '18px',
                height: '44px'
                }}
                className="cancel-form">
              Cancel
            </Button>}
            {(isViewDetails || isEditDetails) &&
            <Button 
              variant="outlined" 
              onClick={() => {
                setIsViewDetails(false)
                setIsEditDetails(true)
              }} 
              sx={{
                color: 'var(--primary-color)',
                textTransform: 'capitalize',
                borderColor: 'var(--primary-color)',
                fontSize: '18px',
                height: '44px'
                }}
                className="cancel-form">
              Edit
            </Button>}
          </DialogActions>
        </form>
      </Dialog>
      {userListView && (
        <>
          <SearchTextField
            searchText={searchText}
            handleSearchText={handleSearchText}
            fromWhere="subscribedUsers"
          />
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
          <div style={{
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginTop: '12px',
            borderBottom: '1px solid rgb(0, 0, 0, 0.25)',
            paddingBottom: '4px',
            marginBottom: '20px'
            }}>
            <div>
              <Typography 
                variant="h2" 
                component="h2"
                sx={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  marginBottom: '12px'
                }}>
                Manage Sub Admins
              </Typography>
              <Typography 
                variant="p" 
                component="p"
                sx={{
                  fontSize: '16px',
                  color: '#000',
                  opacity: 0.5
                }}>
                Create and manage the Sub-Admins
              </Typography>
            </div>
            <div>
              <Button variant="contained" onClick={() => {
                SetSubAdminInfo({
                  name: '',
                  email: '',
                  phone: '',
                  designation: ''
                })
                setIsEditDetails(false)
                setIsViewDetails(false)
                setIsDialogOpen(true)
              }}
                sx={{
                  backgroundColor: 'var(--primary-color)',
                  textTransform: 'capitalize'
                  }}>
                <ControlPointIcon sx={{marginRight: '4px'}} />
                Create Sub Admin
              </Button>
            </div>
          </div>
          <Typography 
            variant="h2" 
            component="h2"
            sx={{
              fontSize: '24px',
              fontWeight: 'bold',
            }}>
            Sub-Admins ({totalCount && totalCount})
          </Typography>
          <UserTable
            columns={columns}
            rows={
              subAdminData &&
              subAdminData?.map((item) => {
                const res = {
                  ...item,
                  Action: (
                    <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={() => {
                      SetSubAdminInfo({
                        name: item.name,
                        email: item.email,
                        phone: item.phone,
                        designation: item.designation
                      })
                      setSubAdminId(item._id)
                      setIsViewDetails(true)
                      setIsEditDetails(false)
                      setIsDialogOpen(true)}
                    }
                    >
                    <VisibilityIcon sx={{color: '#3C1CDE'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#3C1CDE',
                        fontSize: '18px',
                        fontWeight: 'bold'
                        }}>
                      View
                    </Typography>
                  </IconButton>
                  )
                };
                return res;
              })
            }
          />
        </>
      )}
    </div>
  );
};

export default SubscribedUser;
