import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { 
  Button, 
  IconButton, 
  Typography, 
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  Input,
  TextField } from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { makeStyles } from "@mui/styles";
import UploadIcon from "../../assest/images/upload_icon.png";
import Loader from "../../assest/images/preloader.gif";
import getImageUrls from "../../api/uploadImageOnS3";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import { postNewFormRequest, verify, getUniversityByToken } from "../../redux/universities/universitySlice";
import { getCoachingByToken } from "../../redux/coaching/coachingSlice";
import LoaderIcon from "../../components/loader/Loader";
import UploadIconPink from "../../assest/svgs/upload-pink-icon.svg";
import OtpInput from 'react-otp-input';
import { toast } from "react-toastify";
import validator from "validator";
import routes from "../../react_routes_enum";
const countryList = require("../../data/countryForCode.json");
const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    topHeader: {
        fontSize: '34px',
        fontWeight: 'bold'
    },
    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    horizontalLine: {
        width: '200px',
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        height: '52px',
        width: '52px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '800px'
    },
    icon: {
        color: '#fff',
    },
    iconText: {
        color: 'var(--primary-color)',
        fontSize: '18px !important',
        marginTop: '8px !important',
        fontWeight: 'bold',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
        top: -24
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    table: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    },
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    buttonStyle: {
        background: 'none',
        textTransform: 'capitalize !important',
        position: 'absolute !important',
        right: 0,
        top: 8,
    },
    verifySubmit: {
        height: '36px',
        textTransform: 'capitalize !important',
        color: '#FFFFFF !important',
        width: '150px',
        '&:hover':{
            backgroundColor: '#DC0707 !important',
        },
    },
    marginBot24: {
        marginBottom: '24px'
    },
}))
let formInfo = {
    email: "",
    password: "",
    name: "",
    university_name: "",
    designation: "",
    address: "",
    phone: "",
    whatsapp_number: "",
    address_proof: "",
    phone_country_code: "91",
}
let formInfo2 = {
    email: "",
    password: "",
    owner_name: "",
    coaching_name: "",
    address: "",
    phone: "",
    whatsapp_number: "",
    address_proof: "",
    phone_country_code: "91",
}

var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

const Listing = ({back, section}) => {
    const { 
        type,
        universityById } = useSelector(
        (state) => state.university
    );
    const { coachingById } = useSelector(
        (state) => state.coaching
    );
    const [loading, setLoading] = useState(false);
    const [formInformation, setFormInformation] = React.useState(
        type === "University" ? formInfo : formInfo2);
    const [isMobileVerified, setIsMobileVerified] = React.useState(false);
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [image, setImage] = React.useState('');
    const [errors, setErrors] = React.useState(false);
    const [mobileLoading, setMobileLoading] = React.useState(false);
    const [emailLoading, setEmailLoading] = React.useState(false);
    const [requestLoading, setRequestLoading] = React.useState(false);
    const [isEmailOtpField, setIsEmailOtpField] = React.useState(false);
    const [isPhoneOtpField, setIsPhoneOtpField] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [otp, setOtp] = React.useState();
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    
    const { university_designation } = useSelector(
        (state) => state.config
    );
    useEffect(() => {
        if(!university_designation) dispatch(getConfigDetails());
        if(universityById){
            setFormInformation({
                university_name: universityById?.university_name,
                email: universityById?.email,
                name: universityById?.name,
                designation: universityById?.designation,
                address: universityById?.address,
                phone: universityById?.phone,
                whatsapp_number: universityById?.whatsapp_number,
                address_proof: universityById?.address_proof,
                phone_country_code: universityById?.phone_country_code,
            })
            setImage(universityById?.address_proof)
        }
        if(coachingById){
            setFormInformation({
                email: coachingById?.email,
                owner_name: coachingById?.owner_name,
                coaching_name: coachingById?.coaching_name,
                address: coachingById?.address,
                phone: coachingById?.phone,
                whatsapp_number: coachingById?.whatsapp_number,
                address_proof: coachingById?.address_proof,
                phone_country_code: coachingById?.phone_country_code,
            })
        }
    },[])
    const saveAndNext = () => {
        if((universityById || coachingById) && !isEdit){
            navigate(routes.INFORMATION);
        }else{
            if(validate().status){
                let asArray = Object.entries(formInformation);
                let filtered = asArray.filter(([key, value]) => value !== '');
                let body = Object.fromEntries(filtered);
                setRequestLoading(true);
                const res = dispatch(postNewFormRequest(body));
                res.then((r) => {
                    setRequestLoading(false)
                    if(r.payload.statusCode === 200){
                        toast.success(r?.payload?.message);
                        if(type === "University"){
                            localStorage.setItem("new_institute_token", r?.payload.data.token)
                            dispatch(getUniversityByToken())
                            navigate(routes.INFORMATION);
                        }else{
                            localStorage.setItem("new_coaching_token", r?.payload.data.token)
                            dispatch(getCoachingByToken())
                            navigate(routes.COACHING_INFORMATION);
                        }
                    }else{
                        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                            r?.payload?.message === "Session Expired"){
                            toast.error("Session expired, please login again");
                            localStorage.removeItem("hp_token");
                            localStorage.removeItem("hp_user");
                            navigate(routes.LOGIN);
                            return;
                        }
                        toast.error(r?.payload?.message);
                    }
                });
            }else{
                toast.error(validate().message)
            }
        }
    }
    const validate = () => {
        let error = {status: true, message: ""}
        if(type === "University"){
            if(formInformation.university_name === ""){
                error.status = false;
                error.message = "University Name Required";
                return error;
            }
            
        }else{
            if(formInformation.owner_name === ""){
                error.status = false;
                error.message = "Owner Name Required";
                return error;
            }
            if(formInformation.owner_name.length < 4 || !nameValidator.test(formInformation.owner_name) || 
                formInformation.owner_name.length > 20){
                formInformation.owner_name.length < 4 ? (error = {status: false, message: "Owner name must be at least 4 characters"}) :
                (error = {status: false, message: "Invalid Owner Name"})
                formInformation.owner_name.length > 20 && (error = {status: false, message: "Owner name must be less than 20 characters"})
                return error;
            }
            if(formInformation.coaching_name === ""){
                error.status = false;
                error.message = "Coaching Name Required";
                return error;
            }
            if(formInformation.coaching_name.length < 4 || !nameValidator.test(formInformation.coaching_name)){
                formInformation.coaching_name.length < 4 ? (error = {status: false, message: "Coaching name must be at least 4 characters"}) :
                (error = {status: false, message: "Invalid Coaching Name"})
                return error;
            }
            
        }
        //coaching
        if(formInformation.address === "" || formInformation.address.length < 6){
            error.status = false;
            error.message = formInformation.address === "" ? "Address Required" : "Address must be at least 6 characters long";
            return error;
        }
        
        if(formInformation.phone_country_code === ""){
            error.status = false;
            error.message = "Phone Country Code Required";
            return error;
        }
        if(formInformation.phone === ""){
            error.status = false;
            error.message = "Phone Number Required";
            return error;
        }
        if(formInformation.phone.length !== 10){
            error.status = false;
            error.message = "Invalid Phone Number";
            return error;
        }
        if(formInformation.whatsapp_number !== "" && formInformation.whatsapp_number.length !== 10){
            error.status = false;
            error.message = "Invalid WhatsApp Number";
            return error;
        }
        if(formInformation.email === ""){
            error.status = false;
            error.message = "Email Id Required";
            return error;
        }
        if(!validator.isEmail(formInformation.email)){
            error.status = false;
            error.message = "Invalid Email";
            return error;
        }
        if(formInformation.password === ""){
            error.status = false;
            error.message = "Password Required";
            return error;
        }
        return error;
    }
    const handleChange = (event) => {
        event.target.name === 'name' && (type === "University" ? setFormInformation({...formInformation, university_name: event.target.value}) :
            setFormInformation({...formInformation, owner_name: event.target.value}))
        event.target.name === 'coaching-name' && setFormInformation({...formInformation, coaching_name: event.target.value})
        event.target.name === 'chairperson-name' && setFormInformation({...formInformation, name: event.target.value})
        event.target.name === 'designation' && setFormInformation({...formInformation, designation: event.target.value})
        event.target.name === 'address' && setFormInformation({...formInformation, address: event.target.value})
        event.target.name === 'phone-country-code' && setFormInformation({...formInformation, phone_country_code: event.target.value})
        event.target.name === 'phone-number' && setFormInformation({...formInformation, phone: event.target.value})
        event.target.name === 'whatsapp-number' && setFormInformation({...formInformation, whatsapp_number: event.target.value})
        event.target.name === 'email' && setFormInformation({...formInformation, email: event.target.value})
        event.target.name === 'password' && setFormInformation({...formInformation, password: event.target.value})
        if(event.target.name === "image-upload"){
            handleUploadFilesOnS3(event.target.files[0]);
            setImage(event.target.value);
        }
    };
    const handleUploadFilesOnS3 = async(image) => {
        const response = await getImageUrls({
            image: image,
            setLoading,
            setErrors,
        });
        setFormInformation({...formInformation, address_proof: response?.data?.urls[0]})
    }
    const verifyFunc = (type) => {
        if(type === "Mobile"){
            if(formInformation.phone === "" || formInformation.phone.length !== 10){
                toast.error("Invalid Mobile Number");
                return;
            }else{
                setMobileLoading(true);
                let verifyInfo = {
                    phone: formInformation.phone,
                    phone_country_code: formInformation.phone_country_code,
                    type: "phone"
                }
                const res = dispatch(verify(verifyInfo));
                res.then((r) => {
                    setMobileLoading(false);
                    if(r.payload.statusCode === 200){ 
                        setIsMobileVerified(true);
                        setIsPhoneOtpField(false);
                        setFormInformation({...formInformation, verification_id_phone: r?.payload?.data})
                    }
                    else{
                        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                            r?.payload?.message === "Session Expired"){
                            toast.error("Session expired, please login again");
                            localStorage.removeItem("hp_token");
                            localStorage.removeItem("hp_user");
                            navigate(routes.LOGIN);
                            return;
                        }
                        toast.error(r?.payload?.message);
                    }
                });
            }
        }else{
            if(!validator.isEmail(formInformation.email)){
                toast.error("Invalid Email");
                return;
            }else{
                setEmailLoading(true);
                let verifyInfo = {
                    email: formInformation.email,
                    type: "email"
                }
                const res = dispatch(verify(verifyInfo));
                res.then((r) => {
                    setEmailLoading(false);
                    if(r.payload.statusCode === 200){ 
                        setIsEmailVerified(true);
                        setIsEmailOtpField(false);
                        setFormInformation({...formInformation, verification_id_email: r?.payload?.data})
                    }
                    else{
                        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                        r?.payload?.message === "Session Expired"){
                        toast.error("Session expired, please login again");
                        localStorage.removeItem("hp_token");
                        localStorage.removeItem("hp_user");
                        navigate(routes.LOGIN);
                        return;
                      }
                      toast.error(r?.payload?.message);
                    }
                });
            }
        }
    }
    const handleOtpChange = (value) => {
        setOtp(value);
    }
    return (
        <>
        <div style={{
            marginTop: '3%'
        }}>
            <div className={classes.topHeading}>
                <div></div>
                <Typography component="h2" variant="h2" 
                    className={classes.topHeader}>Details</Typography>
                <div>
                    <Button sx={{
                        backgroundColor: 'var(--primary-color)',
                        width: '130px'
                    }} variant="contained" onClick={saveAndNext} disabled={requestLoading}>
                        {requestLoading ? <LoaderIcon />  : 
                        (((universityById || coachingById) && !isEdit) ? 'Next' : 
                            'Save & Next')}
                    </Button>
                </div>
            </div>
            <div className={classes.formIconsBox}>
                <div className={classes.iconBox}>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine}></div>
                        <div className={classes.iconBox2} style={{left: 72}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    }}>
                                <BorderColorIcon fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText}>
                                Create
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 48}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    fontSize: '24px',
                                    backgroundColor: 'var(--disable-color)'
                                    }}>
                                i
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                Information
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <CameraAltIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                Uploads
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <MenuBookIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                {type === "University" ? 'Courses' : 'Faculties'}
                            </Typography>
                        </div>
                    </div>
                    {type === "University" && 
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 24}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <BusinessCenterIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                Placement Records
                            </Typography>
                        </div>
                    </div>}
                </div>
            </div>
            <div style={{
                marginTop: '10%'
            }}>
                <form style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
                    // onSubmit={requestBtnClicked}
                    >
                    <div style={{
                        width: '60%',
                        marginRight: '32px'
                    }}>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                {type === 'University' ? 'Name of University' : 'Name of Owner'}
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                name="name" sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={handleChange} 
                                value={type === "University" ? 
                                formInformation.university_name : formInformation.owner_name} 
                                required/>
                        </div>
                        {type === 'Coaching' &&
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                {'Name of Coaching Center'}
                            </InputLabel>
                            <TextField placeholder="" type="text" variant="outlined" name="coaching-name" sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} value={formInformation.coaching_name} 
                            required/>
                        </div>}
                        {type === 'University' &&
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Name of Chairperson or HODs <span style={{
                                    color: 'rgb(0, 0, 0, 0.4)',
                                    fontSize: '16px',
                                }}>(Optional)</span>
                            </InputLabel>
                            <div className="form-two-inputs">
                                <Select
                                    name="designation"
                                    value={formInformation.designation}
                                    onChange={handleChange}
                                    placeholder=""
                                    autoWidth
                                    required
                                    sx={{
                                        width: '88%',
                                        height: '56px',
                                        position: 'relative',
                                        marginRight: '12px'
                                    }}
                                    >
                                        {university_designation && university_designation[0].data?.map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    name="chairperson-name" 
                                    variant="outlined" 
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    onChange={handleChange} 
                                    value={formInformation.name} 
                                    required/>
                            </div>
                            
                        </div>}
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>Full Address</InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                name="address" 
                                onChange={handleChange} 
                                value={formInformation.address} 
                                required/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Phone Number and Whatsapp
                            </InputLabel>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div style={{
                                    width: '34%',
                                    marginRight: '12px'
                                }}>
                                    <Select
                                        value={formInformation?.phone_country_code}
                                        onChange={handleChange}
                                        placeholder="+91"
                                        name="phone-country-code"
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                                        autoWidth
                                        required
                                        sx={{
                                            width: '100%',
                                            height: '56px',
                                            position: 'relative',
                                        }}
                                        >
                                        {countryList?.map((item, index) => (
                                            <MenuItem value={item?.phonecode} key={index} selected={index === 1 && true}>
                                                {item?.phonecode}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{
                                    width: '100%',
                                    marginRight: '12px',
                                    position: 'relative',
                                }}>
                                    <TextField 
                                        placeholder="" 
                                        type="number" 
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} 
                                        name="phone-number" 
                                        onChange={handleChange} 
                                        value={formInformation.phone} 
                                        required/>
                                </div>
                                <div style={{
                                    width: '100%',
                                }}>
                                    <TextField 
                                        placeholder="" 
                                        type="number" 
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} 
                                        name="whatsapp-number" 
                                        onChange={handleChange} 
                                        value={formInformation.whatsapp_number} 
                                        required/>
                                </div>
                            </div>
                            {isPhoneOtpField &&
                            <div>
                                <OtpAria 
                                    otp={otp} 
                                    type="Mobile"
                                    loading={mobileLoading}
                                    title="OTP send on your above mention phone number"
                                    btnTitle="Verify Phone"
                                    verifyClicked={(type) => verifyFunc(type)}
                                    handleOChange={handleOtpChange} />
                            </div>}
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                {type === 'University' ? 'University Official Email ID' : 
                                'Email ID'}
                            </InputLabel>
                            <div style={{
                                    position: 'relative',
                                }}>
                                <TextField 
                                    placeholder="" 
                                    type="email" 
                                    variant="outlined" 
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    name="email" 
                                    onChange={handleChange} 
                                    value={formInformation.email} 
                                    required/>
                            </div>
                            {isEmailOtpField &&
                            <div>
                                <OtpAria 
                                    otp={otp} 
                                    type="Email"
                                    title="OTP send on your above mention email ID"
                                    loading={emailLoading}
                                    btnTitle="Verify Email"
                                    verifyClicked={(type) => verifyFunc(type)}
                                    handleOChange={handleOtpChange} />
                            </div>}
                        </div>
                        {(!universityById && !coachingById) &&
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>Password</InputLabel>
                            <TextField
                                id="password"
                                name="password"
                                // type={showPassword ? "text" : "password"}
                                type="password"
                                value={formInformation.password}
                                onChange={handleChange}
                                autoComplete="current-password"
                                margin="normal"
                                required
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        // onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                    >
                                        {/* {showPassword ? <Visibility /> : <VisibilityOff />} */}
                                    </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                                // error={passwordError}
                                // helperText={passwordError ? "required field" : ""}
                                fullWidth
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }}
                            />
                        </div>}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div>
                            <InputLabel className={classes.formInputLabel}>
                                Address Proof {" "}
                                <span style={{
                                    color: 'rgb(0, 0, 0, 0.4)',
                                    fontSize: '16px',
                                }}>(Optional)</span>
                            </InputLabel>
                            <InputLabel sx={{
                                fontSize: '12px',
                                color: 'rgb(0, 0, 0, 0.6)'
                            }}>Upload the document for address proof</InputLabel>
                        </div>
                        <div className="upload-addr-btn-box" style={{position: 'relative'}}>
                            <label htmlFor="workout-images">
                                <Input
                                    accept="image/*"
                                    id="workout-images"
                                    name="image-upload"
                                    type="file"
                                    sx={{display: 'none'}}
                                    onChange={handleChange}
                                />
                                <Button component="div" className="upload-addr-btn">
                                    {/* <FileUploadOutlinedIcon /> */}
                                    <img src={type === "University" ? UploadIcon : UploadIconPink} alt="upload logo" width="40" />
                                    {loading ? <img src={Loader} width="24" alt="Loader" /> : 
                                        <span>{formInformation?.address_proof ? 
                                            formInformation?.address_proof?.split("/")[3]?.split("-")[1] : 
                                        'Upload Image'}</span>}
                                </Button>
                            </label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
};

const OtpAria = (props) => {
    const classess = useStyles()
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
            }}>
                <Typography sx={{
                    color: '#EF4840',
                    fontSize: '12px'
                }} component="p" variant="p">
                    {props.title}
                </Typography>
                <Button sx={{
                    textTransform: 'capitalize',
                    fontSize: '12px',
                    color: '#000',
                    background: 'none'
                }}>
                    Resend OTP
                </Button>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '12px'
            }}>
                <OtpInput
                    value={props.otp}
                    onChange={props.handleOChange}
                    numInputs={4}
                    inputStyle={{
                        width: '80px',
                        height: '36px',
                        borderTop: 'none',
                        borderRight: 'none',
                        borderLeft: 'none',
                        "&:focusVisible":{
                            borderTop: 'none',
                            borderRight: 'none',
                            borderLeft: 'none',
                        }
                    }}
                    isInputNum={true}
                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Button 
                    className={classess.verifySubmit} 
                    disabled={props.loading}
                    sx={props.loading ? {backgroundColor: 'var(--disable-color)'} : {backgroundColor: '#DC0707'}}
                    onClick={() => props.verifyClicked(props.type === "Mobile" ? "Mobile" : "Email")}>
                    {props.loading ? 
                        <LoaderIcon />
                     : 
                    props.btnTitle}
                </Button>
            </div>
        </>
    )
}

export default Listing;
