import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Box,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    FormControlLabel,
    RadioGroup,
    Radio
  } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import routes from "../../react_routes_enum";
import Spinner from "../../components/Spinner/Spinner";
import { 
    getStudentById,
  } from "../../redux/students/studentsSlice";

const countryList = require("../../data/countryForCode.json");

const useStyle = makeStyles({
    benefitSingleBox: {
        display: "flex",
        marginBottom: '24px',
        alignItems: "center",
    },
    howItWorkSingleBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: '24px',
        alignItems: "center",
    },
    buttonStyle: {
        background: 'none',
        textTransform: 'capitalize !important',
        position: 'absolute !important',
        right: 0,
        top: 8,
    },
    verifySubmit: {
        height: '36px',
        textTransform: 'capitalize !important',
        color: '#FFFFFF !important',
        width: '150px',
        '&:hover':{
            backgroundColor: '#DC0707 !important',
        },
    },
    marginBot24: {
        marginBottom: '24px'
    },
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    uploadButton: {
        height: '80px',
        border: '2px dashed rgb(0,0,0,0.5) !important',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF !important',
        "&:hover": {
            backgroundColor: '#ffffff !important',
        }
    },
    requestButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '100%',
        height: '44px',
        fontSize: '16px !important',
        borderRadius: '8px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    }
})

let formData = {
    email: "",
    password: "",
    name: "",
    address: "",
    phone: "",
    dob: "",
    id_proof: "",
    phone_country_code: "91",
    verification_id_email: "",
    verification_id_phone: "",
    registered_for: "university",
    course_interested_in: "",
    subjects: [],
    countries_interested_in: [],
    budget_in_lakhs: "",
    education_loan_assistance_needed: true,
    is_first_attempt: false
}

export default function StudentDetails() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formInformation, setFormInformation] = React.useState(formData);
    const { 
        isLoading, 
        isError, 
        message, 
        studentId
    } = useSelector((state) => state.students);
    
    React.useEffect(() => {
        const res = dispatch(getStudentById(studentId))
        res.then((r) => {
            if(r?.payload?.statusCode === 200){
                setFormInformation(r.payload.data)
            }
            else{
                if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                    r?.payload?.message === "Session Expired"){
                    toast.error("Session expired, please login again");
                    localStorage.removeItem("hp_token");
                    localStorage.removeItem("hp_user");
                    navigate(routes.LOGIN);
                    return;
                }
                toast.error(r?.payload?.message);
            }
        })
    },[studentId])
    if (isLoading) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
        <>
        <Grid
            container
            component="main"
            sx={{ height: "100vh" }}
            className="login-form"
        >
            <Box
                sx={{
                my: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: '100%'
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    width: '100%'
                }}>
                    <div style={{
                        width: '45%',
                        marginRight: '24px'
                    }}>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Name
                            </InputLabel>
                            <TextField placeholder="" type="text" variant="outlined" name="name" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }}value={formInformation.name} required/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Email
                            </InputLabel>
                            <div style={{
                                    position: 'relative',
                                }}>
                                <TextField 
                                    placeholder="" 
                                    type="email" 
                                    variant="outlined" 
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    name="email" 
                                    value={formInformation.email} 
                                    required/>
                            </div>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>Phone number</InputLabel>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div style={{
                                    width: '34%',
                                    marginRight: '12px'
                                }}>
                                    <Select
                                        value={formInformation?.phone_country_code}
                                        placeholder="+91"
                                        name="phone-country-code"
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                                        autoWidth
                                        required
                                        sx={{
                                            width: '100%',
                                            height: '56px',
                                            position: 'relative',
                                        }}
                                        >
                                        {countryList.map((item, index) => 
                                        <MenuItem value={item.phonecode} key={index}>
                                            {item.phonecode}
                                        </MenuItem>)}
                                    </Select>
                                </div>
                                <div style={{
                                    width: '100%',
                                    marginRight: '12px',
                                    position: 'relative',
                                }}>
                                    <TextField 
                                        placeholder="" 
                                        type="number" 
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} 
                                        name="phone-number" 
                                        value={formInformation.phone} 
                                        required/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Date of birth
                            </InputLabel>
                            <TextField placeholder="" type="text" variant="outlined" name="dob" sx={{
                                width: '100%',
                                height: '62px'
                            }} value={formInformation.dob.split("T")[0]} required/>
                        </div>
                    </div>
                    <div style={{
                        width: '45%'
                    }}>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>Full Address</InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                multiline
                                rows={4}
                                name="address" 
                                value={formInformation.address} 
                                required/>
                        </div>
                        <div className={classes.marginBot24 +' upload-addr-box'} style={{
                            marginTop: '84px'
                        }}>
                            <InputLabel className={classes.formInputLabel}>ID Proof</InputLabel>
                            <div>
                                <img src={formInformation.id_proof} alt="student_id_proof" width="200" />
                            </div>
                        </div>
                        <div className={classes.marginBot24}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="registration-type"
                                defaultValue={formInformation.registered_for}
                            >
                                <FormControlLabel value={"university"} 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} label="University" sx={{
                                    fontWeight: 'bold',
                                }} />
                                <FormControlLabel value={"coaching"} 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} label="Coaching" />
                                <FormControlLabel value={"both"} 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} label="Both" />
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            </Box>
        </Grid>
        </>
    );
}