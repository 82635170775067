import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnerServices from "./partnerServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalCount: 0,
  searchText: "",
  partnersData: null,
  partnerId: null,
  partnerById: null,
};

export const getPartners = createAsyncThunk(
    "partner/getPartners",
    async (params, thunkAPI) => {
      try {
        return await partnerServices.getPartners(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getPartnerById = createAsyncThunk(
    "partner/getPartnerById",
    async (params, thunkAPI) => {
      try {
        return await partnerServices.getPartnerById(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const partnerStatusUpdate = createAsyncThunk(
    "partner/partnerStatusUpdate",
    async (params, thunkAPI) => {
      try {
        return await partnerServices.partnerStatusUpdate(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    setPartnerSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setInstituteIdForProduct: (state, action) => {
      state.instituteIdForProduct = action.payload;
    },
    setProductDetailsById: (state, action) => {
      state.productById = action.payload;
    },
    setPartnerById: (state, action) => {
        state.partnerId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPartners.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPartners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.partnersData = action.payload.data?.result;
      })
      .addCase(getPartnerById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPartnerById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPartnerById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.partnerById = action.payload.data;
      })
      .addCase(partnerStatusUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(partnerStatusUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(partnerStatusUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
  },
});

export const { 
    setPartnerSearchText,
    setInstituteIdForProduct,
    setProductDetailsById,
    setPartnerById } = partnerSlice.actions;

export default partnerSlice.reducer;
