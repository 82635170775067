import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import routes from "../../react_routes_enum";
import validator from "validator";
import { toast } from "react-toastify";
import Radio from '@mui/material/Radio';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import UploadBox from '../../components/UploadBox/UploadBox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { addReview } from "../../redux/students/studentsSlice";
import { getUniversities } from "../../redux/universities/universitySlice";
import { getCoaching } from "../../redux/coaching/coachingSlice";
import LoaderIcon from "../../components/loader/Loader";
import { 
    InputLabel,
    Button,
    Select,
    Rating,
    MenuItem,
    TextField } from "@mui/material";
import "./testimonial.css";

const reviewBody = {
    name: "",
    email: "",
    message: "",
    rating: 0,
    video_url: "",
    image_url: "",
    type: "university", //['university', 'coaching', 'course']
    typeId: "",
}

const AddTestimonial = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [btnLoader, setBtnLoader] = useState(false);
    const [formData, setFormData] = useState(reviewBody);
    const [universityList, setUniversityList] = useState([]);
    const [coachingList, setCoachingList] = useState([]);
    const { reviewById } = useSelector((state) => state.students);

    useEffect(() => {
        const res = dispatch(getUniversities({limit: 10000, offset: 0, request_status: "accepted" }));
        res?.then((r) => {
            r?.payload.statusCode === 200 && 
                setUniversityList(r?.payload?.data?.result)
        });
    },[])

    useEffect(() => {
        const res = dispatch(getCoaching({ limit: 10000, offset: 0, request_status: "accepted" }));
        res?.then((r) => {
            r?.payload.statusCode === 200 && 
            setCoachingList(r?.payload?.data?.result)
        })
    }, [])
    
    const handleChange = (e) => {
        e.target.name === "name" && setFormData({...formData, name: e.target.value});
        e.target.name === "email" && setFormData({...formData, email: e.target.value})
        e.target.name === "message" && setFormData({...formData, message: e.target.value, video_url: ""})
        e.target.name === "coaching" && setFormData({...formData, typeId: e.target.value})
        e.target.name === "university" && setFormData({...formData, typeId: e.target.value})
        e.target.name === "type" && setFormData({...formData, type: e.target.value, typeId: ""})
        e.target.name === "rating" && setFormData({...formData, rating: e.target.value})
    }

    const handleImageUploads = (name, value) => {
        name === "image" ? setFormData({...formData, image_url: value}) :
            setFormData({...formData, video_url: value, message: ""})
    }

    const valideForm = () => {
        let error = {message: '', status: true}
        if(formData?.rating === 0){
            error.message = "Rating must be greater than 0";
            error.status = false;
            return error;
        }
        if(!formData?.name || formData?.name === ""){
            error.message = "Name Required";
            error.status = false;
            return error;
        }
        if(!formData?.email || formData?.email === ""){
            error.message = "Email Required";
            error.status = false;
            return error;
        }
        if(!validator.isEmail(formData?.email)){
            error.message = "Invalid Email Entered";
            error.status = false;
            return error;
        }
        if(formData?.video_url === "" && formData?.message === ""){
            toast.error("Message or Video required");
            return;
        }
        if(!formData?.image_url || formData?.image_url === ""){
            error.message = "Image Required";
            error.status = false;
            return error;
        }
        if(!formData?.type || formData?.type === ""){
            error.message = "Please review type";
            error.status = false;
            return error;
        }
        if(!formData?.typeId || formData?.typeId === ""){
            error.message = "Please select a coaching or university";
            error.status = false;
            return error;
        }
        return error;
    }

    const submitTestimonial = () => {
        if(!valideForm().status){
            toast.error(valideForm().message);
            return;
        }
        let body = {}
        for (const key in formData) {
            if (formData.hasOwnProperty(key) && 
                formData[key] !== null && 
                formData[key] !== undefined && 
                formData[key] !== '') {
                body[key] = formData[key];
            }
        }
        setBtnLoader(true);
        const res = dispatch(addReview(body));
        res?.then((r) => {
            setBtnLoader(false);
            if(r?.payload?.statusCode === 200){
                toast.success("Testimonial Added Successfully");
                navigate(routes.TESTIMONIAL);
                return;
            }
            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                r?.payload?.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
                return;
            }
            toast.error(r?.payload?.message);
        })
    }

    return (
        <>
            {!reviewById 
            ?
            <section className="blog-box" style={{alignItems: 'flex-start'}}>
                <div style={{width: '950px'}}>
                    <div>
                        <InputLabel className="input-label-text">
                            Choose Rating
                        </InputLabel>
                        <Rating
                            className="rating-controlled"
                            name="rating"
                            value={Number(formData?.rating)}
                            onChange={handleChange}
                        />
                    </div>
                    <div style={{
                        width: '100%',
                        marginBottom: '20px'
                    }}>
                        <InputLabel className="input-label-text">
                            Name
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="name" 
                            type="text" 
                            variant="outlined" 
                            value={formData.name}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                    </div>
                    <div style={{
                        width: '100%',
                        marginBottom: '20px'
                    }}>
                        <InputLabel className="input-label-text">
                            Email
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="email" 
                            type="email" 
                            variant="outlined" 
                            value={formData.email}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                    </div>
                    <div style={{
                        marginBottom: '78px'
                    }}>
                        <InputLabel className="input-label-text">
                            Message
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="message" 
                            type="text" 
                            variant="outlined" 
                            value={formData.message}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required
                            rows={4}
                            multiline/>
                    </div>
                    <InputLabel className="input-label-text">
                        Select review type
                    </InputLabel>
                    <div className="four-input-box margin-bot-change" 
                        style={{width: '250px', marginBottom: '12px'}}>
                        <RadioGroup
                            row
                            name="type"
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={formData?.type === "University" ? "University" : "Coaching"}
                            onChange={(e) => handleChange(e)}
                        >
                            <FormControlLabel 
                                value="university" 
                                checked={formData?.type === "university"}
                                sx={{textTransform: 'capitalize'}}
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="university" />
                            <FormControlLabel 
                                value="coaching" 
                                checked={formData?.type === "coaching"}
                                sx={{textTransform: 'capitalize'}}
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="coaching" />
                        </RadioGroup>
                    </div>
                    <div className="blog-tag-box">
                        <div className="blog-tag-box-inp">
                            <InputLabel className="input-label-text">
                                Select University/College
                            </InputLabel>
                            {console.log("==========><<",formData?.type)}
                            <Select
                                required
                                name="university"
                                disabled={formData?.type === "coaching"}
                                value={formData?.type === "university" ? 
                                    formData?.typeId : ""}
                                onChange={handleChange}
                                placeholder="course"
                                autoWidth
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                    marginRight: '12px',
                                    border: 'none'
                                }}>
                                {universityList?.map((item, index) => (
                                    <MenuItem value={item?._id} key={index}>
                                        {item?.university_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                            <InputLabel className="input-label-text">
                                Select Coaching
                            </InputLabel>
                            <Select
                                required
                                name="coaching"
                                value={formData?.type === "coaching" ? 
                                    formData?.typeId : ""}
                                disabled={formData?.type === "university"}
                                onChange={handleChange}
                                placeholder="course"
                                autoWidth
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                    marginRight: '12px',
                                    border: 'none'
                                }}>
                                {coachingList?.map((item, index) => (
                                    <MenuItem value={item?._id} key={index}>
                                        {item?.coaching_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <p style={{
                        fontSize: '12px'
                        }}><span style={{color: '#EF4840'}}>*</span>
                        You can't write message or upload video at same time</p>
                    <div style={{display: 'flex'}}>
                        <div style={{marginRight: '24px'}}>
                            <InputLabel className="input-label-text">
                                Upload Images
                            </InputLabel>
                            <div>
                                <UploadBox
                                    name='image'
                                    height={'166px'}
                                    width={'216px'}
                                    value={formData?.image_url}
                                    handleUpload={
                                        (name, value) => handleImageUploads(name, value)
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <InputLabel className="input-label-text">
                                Upload Video
                            </InputLabel>
                            <div>
                                <UploadBox
                                    name='video'
                                    height={'166px'}
                                    width={'216px'}
                                    value={formData?.video_url}
                                    handleUpload={
                                        (name, value) => handleImageUploads(name, value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Button disabled={btnLoader} onClick={submitTestimonial}
                    style={{
                        width: '100px',
                        background: btnLoader ? 'rgba(0, 0, 0, 0.3)' : '#F69220',
                        color: '#FFFFFF',
                    }}>
                    {btnLoader ? <LoaderIcon /> : "Submit"}
                </Button>
            </section>
            :
            <section 
                className="blog-box" 
                style={{flexDirection: "column"}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'}}>
                    <InputLabel className="input-label-text" sx={{
                        width: '156px'
                    }}>
                        Name Of Student :
                    </InputLabel>
                    <InputLabel>
                        {reviewById && reviewById?.name}
                    </InputLabel>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'}}>
                    <InputLabel className="input-label-text" sx={{
                        width: '156px'
                    }}>
                        Email :
                    </InputLabel>
                    <InputLabel>
                        {reviewById && reviewById?.email}
                    </InputLabel>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'}}>
                    <InputLabel className="input-label-text" sx={{
                        width: '156px'
                    }}>
                        Rating :
                    </InputLabel>
                    <InputLabel>
                        {reviewById && reviewById?.rating}
                    </InputLabel>
                </div>
                {(reviewById && reviewById?.image_url) &&
                <div style={{
                    display: 'flex',
                    alignItems: 'center'}}>
                    <InputLabel className="input-label-text" sx={{
                        width: '156px'
                    }}>
                        Image :
                    </InputLabel>
                    <img src={reviewById?.image_url} alt="ProfileImage" />
                </div>}
                {(reviewById && reviewById?.video_url) &&
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '12px'}}>
                    <InputLabel className="input-label-text" sx={{
                        width: '156px'
                    }}>
                        Video :
                    </InputLabel>
                    <iframe 
                        style={{
                            borderRadius: '5px'
                        }}
                        width="550px" 
                        height="300px" 
                        src={reviewById?.video_url} 
                        title="Review Video" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen></iframe>
                </div>}
                {(reviewById && reviewById?.message) &&
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '12px'}}>
                    <InputLabel className="input-label-text" sx={{
                        width: '156px'
                    }}>
                        Message :
                    </InputLabel>
                    <div style={{
                        width: '850px',
                        color: 'rgb(0, 0, 0, 0.5)'
                    }}>
                        {reviewById && reviewById?.message}
                    </div>
                </div>}
                <div style={{width: '950px'}}>
                    {/* <div className="blog-tag-box">
                        <div className="blog-tag-box-inp">
                            <InputLabel className="input-label-text">
                                Title
                            </InputLabel>
                            <TextField placeholder="" name="university" type="text" variant="outlined" 
                                className="inputShadow"
                                value=""
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} onChange={handleChange} required/>
                        </div>
                        <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                            <InputLabel className="input-label-text">
                                Designation
                            </InputLabel>
                            <TextField placeholder="" name="university" type="text" variant="outlined" 
                                className="inputShadow"
                                value=""
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} onChange={handleChange} required/>
                        </div>
                    </div>
                    <div style={{
                        marginBottom: '20px'
                    }} className="rich-text-box">
                        <InputLabel className="input-label-text">Review</InputLabel>
                        <textarea rows={7}  style={{
                            width: '100%',
                            resize: 'none',
                            borderRadius: '4px',
                            boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)',
                            border: 0
                        }} />
                    </div>
                    <div className="blog-tag-box">
                        <div className="blog-tag-box-inp">
                            <InputLabel className="input-label-text">
                                Rating<span style={{
                                    color: 'rgb(0, 0, 0, 0.5)',
                                    fontSize: '16px',
                                    fontWeight: '400'
                                    }}>(Out Of 5)</span>
                            </InputLabel>
                            <TextField placeholder="" name="university" type="text" variant="outlined" 
                                className="inputShadow"
                                value=""
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} onChange={handleChange} required/>
                        </div>
                        <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                            
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                    }}>
                        <div>
                            <InputLabel className="input-label-text">
                                Upload Video
                            </InputLabel>
                            <div style={{display: 'inline-block', marginTop: '8px'}}>
                                <UploadBox
                                    name={`video_`}
                                    height={'212px'}
                                    width={'216px'}
                                    value={"hello"}
                                    handleUpload={(name, value) => handleImageChange(name, value)} />
                            </div>
                        </div>
                        <div style={{
                            marginLeft: '24px'
                        }}>
                            <InputLabel className="input-label-text">
                                Upload Image
                            </InputLabel>
                            <div style={{display: 'inline-block', marginTop: '8px'}}>
                                <UploadBox
                                    name={`img_`}
                                    height={'166px'}
                                    width={'216px'}
                                    value={"hello"}
                                    handleUpload={(name, value) => handleImageChange(name, value)} />
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            }
        </>
    )
}

export default AddTestimonial;