import * as React from "react";
import routes from "../../react_routes_enum";
import { useNavigate } from "react-router-dom";
import QIcon from "../../assest/svgs/q-icon.svg";
import "./help&support.css";

export default function HelpSupport() {

    // if (isLoading && isViewDetailClicked) return <Spinner />;
    // if (isError) return <h1>{message}</h1>;
    return (
        <>
            <div 
                className="content-box table-box" 
                style={{
                    marginTop: '3%',
                    color: '#000',
                    overflow: 'hidden'
                }}>
                <div className="ticket-top">
                    <p>All Tickets</p>
                    <div></div>
                </div>
                <div className="ticket-card-box">
                    <TicketCard />
                </div>
            </div>
        </>
    );
}

const TicketCard = () => {
    const navigate = useNavigate();
    const ticketDetail = (id) => {
        navigate(routes.HELP_SUPPORT_DETAIL);
    }

    return (

        <div className="ticket-card-aria" onClick={() => ticketDetail('idd')}>
            <div className="ticket-nom-date-time">
                <p className="ticket-number">
                    Ticket Number # 2022-321
                </p>
                <p className="ticket-date-time">
                    <span>
                        wednesday 3 March, 2022
                    </span>
                    <span style={{
                        marginLeft: '8px'
                    }}>
                        11:34 AM
                    </span>
                </p>
            </div>
            <div className="ticket-title-desc-box">
                <div>
                    <img src={QIcon} alt="" />
                </div>
                <div className="ticket-title-desc">
                    <p className="ticket-title">Lorem ipsum dolor sit amet.</p>
                    <p className="ticket-desc">
                        purus sit amet luctus venenatis, lectus magna fringilla urna, 
                        porttitor purus sit amet luctus venenatis, lectus magna fringilla 
                        urna, porttitor
                    </p>
                </div>
            </div>
            <div>
                <span style={{fontWeight: 'bold'}}>STATUS: </span><span>Pending </span>
            </div>
        </div>
    )
}
