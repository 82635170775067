import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userServices from "./userServices";

const initialState = {
  users: null,
  userDetails: null,
  subscribed_users: null,
  totalCount: 0,
  searchText: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//USER LIST - UNSUBSCRIBED
export const getUserList = createAsyncThunk(
  "users/getUserList",
  async (params, thunkAPI) => {
    console.log(params);

    try {
      return await userServices.users(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//USER LIST -SUBSCRIBED_USER
export const getSubscribedUsers = createAsyncThunk(
  "users/getSubscribedUsers",
  async (params, thunkAPI) => {
    console.log(params);

    try {
      return await userServices.subscribedUsers(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//USER DETAILS
export const getUserDetails = createAsyncThunk(
  "users/getUserDetails",
  async (params, thunkAPI) => {
    try {
      return await userServices.userDetails(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Change DETAILS
export const typeFatChange = createAsyncThunk(
  "users/typeFatChange",
  async (params, thunkAPI) => {
    try {
      return await userServices.typeFatChange(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.searchText = "";
    },
    setUserFilterDetails: (state, action) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.users = action.payload.data.result;
        // state.message = action.payload.message;
        state.totalCount = action.payload.data.totalCount;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.userDetails = action.payload;
        state.message = action.payload.message;
      })
      .addCase(typeFatChange.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(typeFatChange.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(typeFatChange.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.userDetails = action.payload;
        state.message = action.payload.message;
      })
      .addCase(getSubscribedUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscribedUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSubscribedUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscribed_users = action.payload.data.result;
        state.message = action.payload.message;
        state.totalCount = action.payload.data.totalCount;
      });
  },
});
export const { reset, setUserFilterDetails } = usersSlice.actions;
export default usersSlice.reducer;
