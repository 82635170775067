import axios from "axios";
import endpoint from "../../index.routes";

const users = async (params) => {
  const { limit, offset, searchText } = params;
  const response = await axios.get(endpoint.USERS, {
    params: { limit, offset, searchText },
    headers: { token: localStorage.getItem("hp_token") },
  });

  return response.data;
};
const typeFatChange = async (params) => {
  const { id, body } = params;
  const response = await axios.put(endpoint.USERS + "/" + id, body, {
    headers: { token: localStorage.getItem("hp_token") },
  });

  return response.data;
};
const subscribedUsers = async (params) => {
  const { limit, offset, searchText } = params;
  const response = await axios.get(endpoint.SUBSCRIBED_USERS, {
    params: { limit, offset, searchText },
    headers: { token: localStorage.getItem("hp_token") },
  });

  return response.data;
};
const userDetails = async (params) => {
  const { id } = params;
  const response = await axios.get(endpoint.USERS + "/" + id, {
    headers: { token: localStorage.getItem("hp_token") },
  });

  return response.data;
};

const userServices = { users, userDetails, subscribedUsers, typeFatChange };

export default userServices;
