import * as React from 'react';
import { useSelector } from "react-redux";
import {
    Button,
    Input,
    InputLabel,
    Typography,
    TextField
} from "@mui/material";
import Select from '../Select/Select';
import { makeStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import JoditEditor from 'jodit-react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyle = makeStyles({
    marginBot24: {
        marginBottom: '24px',
    },
})

const coachingCat = ["Online", "Offline", "Both"];
const universityCat = ["Engineering", "Medical"];

const SubProduct = (props) => {
    const editor = React.useRef(null);
    const { type } = useSelector(
        (state) => state.university
    );
    const classes = useStyle();
    return (
        <div>
            <Typography variant="h3" component="h3" sx={{
                color: type === "University" ? '#672F8F' : 'var(--tertiary-color)',
                fontSize: '24px',
                marginBottom: '8px',
                fontWeight: 'bold'
            }}>
                Sub-Products
            </Typography>
            <div className={[`${classes.marginBot24} form-part-1`]}>
                <div className='three-input-box margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Title</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        name={`sub_product_${props.index}`} 
                        value={props.item?.title}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
                <div className='three-input-box category-input margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Course Duration</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="number" 
                        variant="outlined" 
                        name={`course_duration_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        value={props.item?.duration}
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
                {/* <div className='three-input-box'>
                    <InputLabel className={classes.formInputLabel}>Timing</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        name={`timing_${props.index}`} 
                        value={props.item?.timings}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div> */}
                <div className='three-input-box pay-box' style={{width: '308px'}}>
                    <InputLabel className={classes.formInputLabel}>
                        How you want to do payment ?
                    </InputLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={props.item?.payment_procedure}
                        name={`sub_product_payment_process_${props.index}`}
                        className="how-pay"
                        onChange={(e) => props.handleChange(e, props.index)}
                    >
                        <FormControlLabel 
                            value="direct_pay" 
                            control={<Radio sx={{
                                color: 'var(--secondary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--secondary-color)',
                                    },
                            }} />} 
                            label="Direct Pay" />
                        <FormControlLabel 
                            value="full_process" 
                            control={<Radio sx={{
                                color: 'var(--secondary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--secondary-color)',
                                    },
                            }} />} 
                            label="Follow Full Process" />
                    </RadioGroup>
                </div>
            </div>
            <div className={[`${classes.marginBot24} description-box`]}>
                <div className="description-input">
                    <InputLabel className={classes.formInputLabel}>Description</InputLabel>
                    <JoditEditor 
                        ref={editor}
                        value={props.item?.description}
                        onChange={newText => props.textEditor(newText)}
                    />
                    {/* <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        name={`description_${props.index}`} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        value={props.item?.description}
                        rows={6}
                        multiline
                        required/> */}
                </div>
                {/* <div className="branch-course">
                    <div className="three-input-box margin-bot-change">
                        <InputLabel className={classes.formInputLabel}>
                            Fees (Rs)
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="number" 
                            variant="outlined" 
                            name={`fees_${props.index}`}  
                            value={props.item?.fees}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleChange(e, props.index)} 
                            required/>
                    </div>
                    <div className="three-input-box course-type-input margin-bot-change">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={props.item?.is_percentage_discount ? 'percentage' : 'flat'}
                            name={`per_${props.index}`}
                            onChange={(e) => props.handleChange(e, props.index)}
                            required
                        >
                            <FormControlLabel 
                                value="percentage" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Percentage Discount"/>
                            <FormControlLabel 
                                value="flat" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Flat Discount"/>
                        </RadioGroup>
                    </div>
                </div> */}
                <div className="branch-course">
                    <div className="three-input-box margin-bot-change sub-pro-fees sub-fee-type">
                        <div>
                            <InputLabel className={classes.formInputLabel}>
                                Fees per annum (Rs)
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="number" 
                                variant="outlined" 
                                name={`fees_${props.index}`}  
                                value={props.item?.fees}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={(e) => props.handleChange(e, props.index)} 
                                required/>
                        </div>
                        <div style={{marginLeft: '24px'}}>
                            <InputLabel className={classes.formInputLabel}>
                                GST (in %)
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="number" 
                                variant="outlined" 
                                name={`gst_${props.index}`}  
                                value={props.item?.gst}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={(e) => props.handleChange(e, props.index)} 
                                required/>
                        </div>
                    </div>
                    <div className="three-input-box course-type-input margin-bot-change">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={props.item?.is_percentage_discount ? 'percentage' : 'flat'}
                            name={`per_${props.index}`}
                            onChange={(e) => props.handleChange(e, props.index)}
                            required
                        >
                            <FormControlLabel 
                                value="percentage" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Percentage Discount"/>
                            <FormControlLabel 
                                value="flat" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Flat Discount"/>
                        </RadioGroup>
                    </div>
                </div>
            </div>
            <div className={[`${classes.marginBot24} form-part-1 semes-box`]}>
                <div className='three-input-box margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>
                        {type === "University" ? 'Semester' : 'Subject'}
                    </InputLabel>
                    <Select 
                        name={`s_${props.index}`}
                        value={type === "University"? props.item?.semester : props.item?.subjects}
                        option={type === "University" ? props.semesList : universityCat} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>
                {type === "University" &&
                <div className='three-input-box category-input margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Subject</InputLabel>
                    <Select 
                        name={`subj_${props.index}`}
                        value={props.item.subjects[0]}
                        option={universityCat} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>}
                {/* <div className={`three-input-box ${type !== "University" && 'leftMargin'}`}>
                    <InputLabel className={classes.formInputLabel}>Discount or offers</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="number" 
                        variant="outlined" 
                        value={props.item.discount}
                        name={`discount_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div> */}
                <div className={`three-input-box sub-pro-fees sub-fee-type 
                    ${type !== "University" && 'leftMargin'}`}>
                    <div>
                        <InputLabel className={classes.formInputLabel}>Discount or offers</InputLabel>
                        <TextField 
                            placeholder="" 
                            type="number" 
                            variant="outlined" 
                            value={props.item.discount}
                            name={`discount_${props.index}`} 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleChange(e, props.index)} 
                            required/>
                    </div>
                    <div style={{marginLeft: '24px'}}>
                        <InputLabel className={classes.formInputLabel}>Eligibility Criteria</InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            variant="outlined" 
                            value={props.item.eligibility_criteria}
                            name={`eligibility_${props.index}`} 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleChange(e, props.index)} 
                            required/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubProduct