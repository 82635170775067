import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  IconButton, 
  Typography, 
  TextField } from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import BlogTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Spinner from "../../components/Spinner/Spinner";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoaderIcon from "../../components/loader/Loader";
import { toast } from "react-toastify";
import { 
  getBlogs,
  updateBlog,
  getBlogById
} from "../../redux/students/studentsSlice";

const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
    isLoading,
    isError,
    totalCount,
    message,
    searchText,
    blogData,
  } = useSelector((state) => state.students);
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rejectBody, setRejectBody] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);
  const [isOnApprove, setIsOnApprove] = useState(false);
  const [blogId, setBlogId] = useState("");

  const colR = ["SNo", "Title", "Tags", "Category", "actions"];

  useEffect(() => {
    const res = dispatch(getBlogs({limit, offset, status: 'accepted'}));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getBlogs({ limit, offset, searchText, status: 'accepted' }));
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnLoader(true);
    const res = dispatch(updateBlog({
      status: 'rejected', 
      reject_reason: rejectBody?.reject_reason,
      id: blogId
    }));
    res.then((r) => {
      setBtnLoader(false);
      if(r.payload.statusCode === 200){
        toast.success("Blog Rejected");
        dispatch(getBlogs({ limit, offset, searchText, status: 'accepted' }))
        setIsDialogOpen(false);
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  const approveClicked = () => {
    const res = dispatch(updateBlog({id: blogId, status: 'accepted'}));
      res.then((r) => {
        if(r.payload.statusCode === 200){
          toast.success("Blog Accepted Successfully");
          dispatch(getBlogs({ limit, offset, searchText, status: 'accepted' }))
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
    })
  }
  const openApproveAlert = (id) => {
    setIsDialogOpen(true);
    setIsOnApprove(true);
    setBlogId(id);
  }
  const viewDetail = (id) => {
    const res = dispatch(getBlogById({id}));
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        navigate(routes.BLOG_DETAIL);
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }
  return (
    <>
      <Dialog maxWidth={isOnApprove ? "xs" : "md"} open={isDialogOpen} onClose={handleClose} fullWidth>
        {!isOnApprove ?
        <form onSubmit={handleSubmit} style={{padding: '0 20% 2% 20%'}}>
          <DialogContent>
            <Typography component="h2" variant="h2" 
              sx={{
                color: 'var(--primary-color)',
                fontSize: '28px',
                fontWeight: 'bold',
                margin: '24px 0 12px'}}>
              Reason <span style={{
                fontSize: '18px',
                color: 'rgb(0, 0, 0, 0.4)',
                fontWeight: '400'
              }}>(mention the reason why are you deleting)</span>
            </Typography>
            <div style={{
              width: '100%',
              border: '1px solid #000000',
              height: '290px'
            }} className="reject-buttons">
              <TextField fullWidth 
                placeholder="Write a reason for deletion" 
                variant="outlined"
                multiline
                rows={7}
                sx={{border: 'none'}}
                required
                onChange={(e) => setRejectBody({reject_reason: e.target.value})}/>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '36px 12px 0 0'
              }}>
                <Button 
                  variant="contained" 
                  type="submit" 
                  disabled={btnLoader}
                  sx={{
                    backgroundColor: btnLoader ? 'var(--disable-color)' : 'var(--primary-color)',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '140px',
                    marginLeft: '12px',
                    borderRadius: '8px'
                    }}>
                  {btnLoader ? <LoaderIcon /> :'Submit'}
                </Button>
              </div>
            </div>
          </DialogContent>
        </form> :
        <div>
          <Typography component="p" variant="p" 
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              margin: '28px 0 48px'}}>
            Are you sure you want to<br/> approve the application form?
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: '0px 16px 28px 16px'
          }}>
            <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
              textTransform: 'capitalize'
            }}>
              Cancel
            </Button>
            <Button onClick={() => {approveClicked()}} variant="contained" sx={{
              backgroundColor: 'var(--primary-color)',
              width: '84px',
              marginRight: '12px',
              textTransform: 'capitalize'
            }}>
              Yes
            </Button>
          </div>
        </div>}
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="students"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
          <div style={{
            display: 'flex', 
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: '12px',
            }}>
            <Button 
              variant="contained"   
              onClick={() => {navigate(routes.BLOG_FORM)}}
              sx={{
                backgroundColor: '#F69220',
                textTransform: 'capitalize',
                borderRadius: '8px'
                }}>
              <ControlPointIcon sx={{marginRight: '4px'}} />
                Create Blog
            </Button>
          </div>
        </div>
        <BlogTable
          columns={colR}
          where="recipes"
          rows={blogData && blogData?.map((item) => {
            const res = {
              ...item,
              actions: (
                <div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <IconButton
                        color="inherit"
                        edge="start"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '8px'
                        }}
                        onClick={() => {
                            setIsDialogOpen(true);
                            setIsOnApprove(false)
                            setBlogId(item._id);
                        }}
                    >
                        <DoDisturbIcon sx={{color: '#EF0000'}} />
                        <Typography component="p" variant="p" 
                        sx={{
                            color: '#EF0000',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginTop: '6px'
                            }}>
                            Delete
                        </Typography>
                    </IconButton>
                    {/* <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 8px'
                      }}
                      onClick={() => openApproveAlert(item._id)}
                    >
                      <CheckCircleOutlineIcon sx={{color: '#07AB35'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#07AB35',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '6px'
                          }}>
                        Approved
                      </Typography>
                    </IconButton> */}
                    <IconButton
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 2px'
                      }}
                      color="inherit"
                      edge="start"
                      onClick={() => {viewDetail(item._id)}}>
                        <VisibilityIcon sx={{color: '#3C1CDE'}} />
                        <Typography component="p" variant="p" 
                          sx={{
                            color: '#3C1CDE',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginTop: '6px'
                            }}>
                          View
                        </Typography>
                    </IconButton>
                  </div>
                </div>
              )
            };
            return res;
          })}
        />
      </div>
    </>
  );
};

export default Blogs;
