import React, { useState } from "react";
import { useSelector } from "react-redux";
import { 
  Button, 
  Typography, 
  InputLabel,
  TextField } from "@mui/material";

import UploadFileIcon from '@mui/icons-material/UploadFile';
import upload from '../../assest/svgs/upload.svg';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    topHeader: {
        fontSize: '34px',
        fontWeight: 'bold'
    },
    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    horizontalLine: {
        width: '200px',
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        height: '72px',
        width: '72px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '800px'
    },
    icon: {
        color: '#fff',
    },
    iconText: {
        color: 'var(--primary-color)',
        fontSize: '20px !important',
        marginTop: '8px !important',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
        top: -36
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    table: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    }
}))
const Listing = ({back, section}) => {
  const [progress, setProgress] = useState(0);
  const classes = useStyles();
  const { type } = useSelector(
    (state) => state.university
  );
  const saveAndNext = () => {
    progress === 3 ? setProgress(0) : setProgress(progress+1);
  }
  return (
    <>
      <div style={{
        marginTop: '3%'
      }}>
        <div className={classes.topHeading}>
            <div></div>
            <Typography component="h2" variant="h2" 
                className={classes.topHeader}>Details</Typography>
            <div>
                <Button sx={{
                    backgroundColor: 'var(--primary-color)'
                }} variant="contained" onClick={saveAndNext}>
                    Save & Next
                </Button>
                <Button variant="outlined" sx={{
                    color: 'var(--primary-color)',
                    marginLeft: '8px'
                    }}>
                    Edit
                </Button>
            </div>
        </div>
        <div className={classes.formIconsBox}>
            <div className={classes.iconBox}>
                <div style={{position: 'relative'}}>
                    <div className={classes.horizontalLine}></div>
                    <div className={classes.iconBox2} style={{left: 48}}>
                        <div className={classes.iconStyle} 
                            style={{
                                color: '#fff', 
                                fontSize: '32px'
                                }}>
                            i
                        </div>
                        <Typography component="h2" variant="h2" className={classes.iconText}>
                            Information
                        </Typography>
                    </div>
                </div>
                <div style={{position: 'relative'}}>
                    <div className={classes.horizontalLine} 
                        style={progress < 1 ? 
                        {backgroundColor: 'var(--disable-color)'} : 
                        {backgroundColor: 'var(--primary-color)'}}></div>
                    <div className={classes.iconBox2} style={{left: 68}}>
                        <div className={classes.iconStyle} style={progress < 1 ? 
                        {backgroundColor: 'var(--disable-color)'} : 
                        {backgroundColor: 'var(--primary-color)'}}>
                            <CameraAltIcon className={classes.icon} fontSize="large" />
                        </div>
                        <Typography component="h2" variant="h2" className={classes.iconText} style={progress < 1 ? 
                            {color: 'var(--disable-color)'} : 
                            {color: 'var(--primary-color)'}}>
                            Uploads
                        </Typography>
                    </div>
                </div>
                <div style={{position: 'relative'}}>
                    <div className={classes.horizontalLine} style={progress < 2 ? 
                        {backgroundColor: 'var(--disable-color)'} : 
                        {backgroundColor: 'var(--primary-color)'}}></div>
                    <div className={classes.iconBox2} style={{left: 68}}>
                        <div className={classes.iconStyle} style={progress < 2 ? 
                        {backgroundColor: 'var(--disable-color)'} : 
                        {backgroundColor: 'var(--primary-color)'}}>
                            <MenuBookIcon className={classes.icon} fontSize="large" />
                        </div>
                        <Typography component="h2" variant="h2" className={classes.iconText} style={progress < 2 ? 
                            {color: 'var(--disable-color)'} : 
                            {color: 'var(--primary-color)'}}>
                            Courses
                        </Typography>
                    </div>
                </div>
                <div style={{position: 'relative'}}>
                    <div className={classes.horizontalLine} style={progress < 3 ? 
                        {backgroundColor: 'var(--disable-color)'} : 
                        {backgroundColor: 'var(--primary-color)'}}></div>
                    <div className={classes.iconBox2} style={{left: 24}}>
                        <div className={classes.iconStyle} style={progress < 3 ? 
                        {backgroundColor: 'var(--disable-color)'} : 
                        {backgroundColor: 'var(--primary-color)'}}>
                            <BusinessCenterIcon className={classes.icon} fontSize="large" />
                        </div>
                        <Typography component="h2" variant="h2" className={classes.iconText} style={progress < 3 ? 
                            {color: 'var(--disable-color)'} : 
                            {color: 'var(--primary-color)'}}>
                            Placement Records
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
        {progress === 0 &&
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10%'
            }}>
                <div style={{
                    width: "48%",
                }}>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        marginBottom: '4%'
                    }}>
                        <div style={{
                            width: '50%',
                            marginRight: '5%',
                        }}>
                            <InputLabel sx={{
                                color: '#000',
                                fontSize: '26px'
                                }}>Name of University</InputLabel>
                            <TextField placeholder="XYZ University" variant="outlined" sx={{
                                width: '100%',
                                height: '62px'
                            }} />
                        </div>
                        <div style={{
                            width: '40%',
                        }}>
                            <InputLabel sx={{
                                color: '#000',
                                fontSize: '26px'
                                }}>City</InputLabel>
                            <TextField placeholder="Delhi" variant="outlined" sx={{
                                width: '100%',
                                height: '62px'
                            }} />
                        </div>
                    </div>
                    <div style={{
                        marginBottom: '4%'
                    }}>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '26px'
                            }}>Full Address / Google Map Link</InputLabel>
                        <TextField placeholder="" variant="outlined" sx={{
                            width: '564px',
                            height: '62px'
                        }} />
                    </div>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '26px'
                            }}>About Institute / Years of Excellence</InputLabel>
                        <TextField placeholder="" variant="outlined" sx={{
                            width: '564px',
                            height: '62px'
                        }} multiline rows={4}/>
                    </div>
                </div>
                <div style={{
                    width: "48%",
                }}>
                    <div style={{
                        marginBottom: '4%'
                    }}>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '26px'
                            }}>University Official Website Link</InputLabel>
                        <TextField placeholder="" variant="outlined" sx={{
                            width: '564px',
                            height: '62px'
                        }} />
                    </div>
                    <div style={{
                        marginBottom: '4%'
                    }}>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '26px'
                            }}>Relationship Manager Name</InputLabel>
                        <TextField placeholder="" variant="outlined" sx={{
                            width: '564px',
                            height: '62px'
                        }} />
                    </div>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '26px'
                            }}>Relationship Manager Contact Details</InputLabel>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            marginBottom: '4%'
                        }}>
                            <div style={{
                                width: '50%',
                                marginRight: '5%',
                            }}>
                                <InputLabel sx={{
                                    color: '#000',
                                    fontSize: '20px'
                                    }}>Phone Number</InputLabel>
                                <TextField placeholder="" variant="outlined" sx={{
                                    width: '100%',
                                    height: '62px'
                                }} />
                            </div>
                            <div style={{
                                width: '40%',
                            }}>
                                <InputLabel sx={{
                                    color: '#000',
                                    fontSize: '20px'
                                    }}>Email</InputLabel>
                                <TextField placeholder="" variant="outlined" sx={{
                                    width: '100%',
                                    height: '62px'
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {progress === 1 &&
            <div style={{
                marginTop: '10%',
                display: 'flex',
            }}>
                <div style={{
                    width: '28%',
                    marginRight: '2%'
                }}>
                    <div>
                        <Typography component="h2" variant="h2" 
                            sx={{
                                color: '#000',
                                fontSize: '20px'
                            }}>1. Upload University Logo</Typography>
                        <div className={classes.leftBox} style={{
                            margin: '5% 0 5% 12%'
                        }}>
                            <img src={upload} alt="upload" width="52" height="52" />
                            <Typography component="h2" variant="h2" sx={{
                                fontSize: '15px',
                                margin: '16px 0 10% 0'
                                }}>
                                PNG or JPG format</Typography>
                            <Button className={classes.upload}>
                                <UploadFileIcon fontSize="15" /> Upload
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Typography component="h2" variant="h2" 
                            sx={{
                                color: '#000',
                                fontSize: '20px',
                                marginBottom: '5%'
                            }}>2. Upload University Introductory/ Promotional video (optional)</Typography>
                        <TextField placeholder="" variant="outlined" sx={{
                                width: '100%',
                                height: '62px'
                            }} />
                    </div>
                </div>
                <div style={{
                    width: '68%',
                }}>
                    <Typography component="h2" variant="h2" 
                        sx={{
                            color: '#000',
                            fontSize: '20px'
                        }}>3. Upload photo-gallery of your institute</Typography>
                    <div style={{
                        display: 'flex',
                        marginTop: '2%'
                    }}>
                        <div className={classes.leftBox} style={{marginRight: '8px'}}>
                            <img src={upload} alt="upload" width="52" height="52" />
                            <Typography component="h2" variant="h2" sx={{
                                fontSize: '15px',
                                margin: '16px 0 10% 0'
                                }}>
                                PNG or JPG format</Typography>
                            <Button className={classes.upload}>
                                <UploadFileIcon fontSize="15" /> Upload
                            </Button>
                        </div>
                        <div className={classes.leftBox} style={{marginRight: '8px'}}>
                            <img src={upload} alt="upload" width="52" height="52" />
                            <Typography component="h2" variant="h2" sx={{
                                fontSize: '15px',
                                margin: '16px 0 10% 0'
                                }}>
                                PNG or JPG format</Typography>
                            <Button className={classes.upload}>
                                <UploadFileIcon fontSize="15" /> Upload
                            </Button>
                        </div>
                        <div className={classes.leftBox} style={{marginRight: '8px'}}>
                            <img src={upload} alt="upload" width="52" height="52" />
                            <Typography component="h2" variant="h2" sx={{
                                fontSize: '15px',
                                margin: '16px 0 10% 0'
                                }}>
                                PNG or JPG format</Typography>
                            <Button className={classes.upload}>
                                <UploadFileIcon fontSize="15" /> Upload
                            </Button>
                        </div>
                        <div className={classes.leftBox}>
                            <img src={upload} alt="upload" width="52" height="52" />
                            <Typography component="h2" variant="h2" sx={{
                                fontSize: '15px',
                                margin: '16px 0 10% 0'
                                }}>
                                PNG or JPG format</Typography>
                            <Button className={classes.upload}>
                                <UploadFileIcon fontSize="15" /> Upload
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }
        {progress === 2 &&
            <div style={{
                marginTop: '10%',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Checkbox className={classes.checkBox} defaultChecked />
                    <InputLabel sx={{
                        color: '#000',
                        fontSize: '20px',
                        marginRight: '6%'
                        }}>Under Graduation</InputLabel>
                    <Checkbox className={classes.checkBox} defaultChecked />
                    <InputLabel sx={{
                        color: '#000',
                        fontSize: '20px'
                        }}>Post Graduation</InputLabel>
                </div>
                <Typography component="h2" variant="h2" 
                    sx={{
                        color: '#000',
                        fontSize: '26px',
                        margin: '3% 0 1% 0.8%'
                    }}>Fees structure for courses</Typography>
                <div className={classes.table}>
                    <div className={classes.tableTh}>
                        <div className={classes.tableTd}>Fees for calender year 2023</div>
                        <div className={classes.tableTd}>Year 1</div>
                        <div className={classes.tableTd}>Year 2</div>
                        <div className={classes.tableTd}>Year 3</div>
                        <div className={classes.tableTd}>Year 4</div>
                        <div className={classes.tableTd}>year 5</div>
                        <div className={classes.tableTd} style={{borderRight: 'none'}}>Total Fees</div>
                    </div>
                    <div className={classes.tableTh}>
                        <div className={classes.tableTd}>Course 1</div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd} style={{borderRight: 'none'}}></div>
                    </div>
                    <div className={classes.tableTh}>
                        <div className={classes.tableTd}>Course 2</div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd} style={{borderRight: 'none'}}></div>
                    </div>
                    <div className={classes.tableTh} style={{border: 'none'}}>
                        <div className={classes.tableTd}>Course 3</div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd}></div>
                        <div className={classes.tableTd} style={{borderRight: 'none'}}></div>
                    </div>
                </div>
            </div>
        }
        {progress === 3 &&
            <div style={{
                marginTop: '10%',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '2%'
                }}>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '20px',
                            }}>Year</InputLabel>
                        <TextField placeholder="2021-2022" variant="outlined" sx={{
                            width: '52%',
                            height: '62px'
                        }}/>
                    </div>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '20px'
                            }}>Name of Course</InputLabel>
                        <TextField placeholder="Mechanical Engineering" variant="outlined" sx={{
                            width: '100%',
                            height: '62px'
                        }}/>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div style={{
                        width: '40%',
                    }}>
                        <Typography component="h2" variant="h2" 
                            sx={{
                                color: '#000',
                                fontSize: '20px',
                                marginBottom: '4px'
                            }}>Description</Typography>
                        <div>
                            <TextField placeholder="Write description here..." variant="outlined" sx={{
                                width: '100%',
                            }} multiline rows="8"/>
                        </div>
                    </div>
                    <div style={{
                        width: '25%',
                    }}>
                        <Typography component="h2" variant="h2" 
                            sx={{
                                color: '#000',
                                fontSize: '20px',
                                marginBottom: '4px'
                            }}>Upload companies logo (optional)</Typography>
                        <div className={classes.leftBox} style={{
                            marginTop: '12px',
                            marginLeft: '14%'}}>
                            <img src={upload} alt="upload" width="52" height="52" />
                            <Typography component="h2" variant="h2" sx={{
                                fontSize: '15px',
                                margin: '16px 0 10% 0'
                                }}>
                                PNG or JPG format</Typography>
                            <Button className={classes.upload}>
                                <UploadFileIcon fontSize="15" /> Upload
                            </Button>
                        </div>
                    </div>
                    <div style={{
                        width: '25%',
                    }}>
                        <Typography component="h2" variant="h2" 
                            sx={{
                                color: '#000',
                                fontSize: '20px',
                                marginBottom: '4px'
                            }}>Brochure of the year (optional)</Typography>
                        <div className={classes.leftBox} style={{
                            marginTop: '12px',
                            marginLeft: '12%'
                            }}>
                            <img src={upload} alt="upload" width="52" height="52" />
                            <Typography component="h2" variant="h2" sx={{
                                fontSize: '15px',
                                margin: '16px 0 10% 0'
                                }}>
                                PNG or JPG format</Typography>
                            <Button className={classes.upload}>
                                <UploadFileIcon fontSize="15" /> Upload
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }
      </div>
    </>
  );
};

export default Listing;
