import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  Button, 
  IconButton, 
  Typography } from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import ReviewTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import Spinner from "../../components/Spinner/Spinner";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { 
  getReviews,
  deleteReview,
  getReviewById,
  setReviewById
} from "../../redux/students/studentsSlice";
import { toast } from "react-toastify";

const Testimonial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [instId, setInstId] = useState("");

  const colR = ["SNo", "Name of person", "Review", "Rating", "actions"];
  const { 
    isLoading,
    isError,
    message,
    totalCount,
    searchText,
    reviewsData } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    const res = dispatch(getReviews({limit, offset}));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset, dispatch]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getReviews({ limit, offset, searchText }));
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  
  const deleteItem = () => {
    const res = dispatch(deleteReview({id: instId}));
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        toast.success("Review Deleted Successfully");
        dispatch(getReviews({ limit, offset, searchText }));
        setIsDialogOpen(false);
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  const viewDetail = (id) => {
    const res = dispatch(getReviewById({id}));
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        navigate(routes.CREATE_TESTIMONIAL)
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }
  return (
    <>
      <Dialog maxWidth={"xs"} open={isDialogOpen} onClose={handleClose} fullWidth>
        <div>
          <Typography component="p" variant="p" 
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              margin: '28px 0 48px'}}>
            Are you sure you want to<br/> delete the review?
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: '0px 16px 28px 16px'
          }}>
            <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
              textTransform: 'capitalize'
            }}>
              Cancel
            </Button>
            <Button 
              onClick={deleteItem}
              variant="contained" 
              sx={{
                backgroundColor: 'var(--primary-color)',
                width: '84px',
                marginRight: '12px',
                textTransform: 'capitalize'
              }}>
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
      <div style={{marginTop: '3%'}}>
      <SearchTextField
        searchText={searchText}
        handleSearchText={handleSearchText}
        fromWhere="students"
      />
      <div className="pagination-addPost">
        <Pagination
          totalCount={totalCount}
          limit={limit}
          page={page}
          changeLimit={setLimit}
          changeOffset={setOffset}
          changePage={setPage}
        />
        <div style={{
          display: 'flex', 
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: '12px',
          }}>
          <Button 
            variant="contained"   
            onClick={() => {
              dispatch(setReviewById(null))
              navigate(routes.CREATE_TESTIMONIAL)
            }}
            sx={{
              backgroundColor: '#F69220',
              textTransform: 'capitalize',
              borderRadius: '8px'
              }}>
            <ControlPointIcon sx={{marginRight: '4px'}} />
              Add Testimonial
          </Button>
        </div>
      </div>
      <ReviewTable
        columns={colR}
        where="recipes"
        rows={reviewsData && reviewsData?.map((item) => {
          const res = {
            ...item,
            actions: (
              <div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginRight: '4px'
                      }}
                      onClick={() => {
                          setIsDialogOpen(true);
                          setInstId(item._id);
                      }}
                  >
                      <DeleteIcon sx={{color: '#EF0000'}} />
                      <Typography component="p" variant="p" 
                      sx={{
                          color: '#EF0000',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '6px'
                          }}>
                          Delete
                      </Typography>
                  </IconButton>
                  <IconButton
                    onClick={() => viewDetail(item._id)}
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                  }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '6px'
                          }}>
                        View
                      </Typography>
                  </IconButton>
                </div>
              </div>
            )
          };
          return res;
        })
      }
      />
      </div>
    </>
  );
};

export default Testimonial;
