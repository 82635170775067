import axios from "axios";
import endpoint from "../../index.routes";

const getStudents = async (params) => {
    const { limit, offset, searchText } = params;
    // console.log("limit===",limit, "offset===",offset, "searchText===",searchText);
    const response = await axios.get(
      endpoint.STUDENTS,
      {
        params: { limit, offset, searchText },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    console.log("data=============",response.data);
    return response.data;
};

const getStudentById = async (params) => {
  
  const response = await axios.get(
    endpoint.STUDENTS + "/" + params,
    {
      // params: { limit, offset },
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const getApplication = async (params) => {
  let { limit, offset, searchText, student_id } = params;
  console.log("check",limit, offset, searchText, student_id);
  const response = await axios.get(
    endpoint.STUDENT_APPLICATION, 
    {
      params: { limit, offset, searchText },
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const getBlogs = async (params) => {
  let { limit, offset, searchText, blog_id, status } = params;
  const response = await axios.get(
    endpoint.BLOGS, 
    {
      params: { limit, offset, searchText, status },
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const addBlog = async (params) => {
  const response = await axios.post(endpoint.BLOGS, params,
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const getApplicationById = async (params) => {
  let { id } = params;
  const response = await axios.get(
    endpoint.STUDENT_APPLICATION+"/"+id, 
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const updateApplication = async (params) => {
  let body = {}
  let { id, status, reject_reason} = params;
  reject_reason ? body = {status,reject_reason} : body = {status}
  const response = await axios.put(
    endpoint.STUDENT_APPLICATION + "/" + id, body, 
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const updateBlog = async (params) => {
  let { id, status, reject_reason} = params;
  const response = await axios.put(
    endpoint.BLOGS + "/" + id, {status, reject_reason}, 
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const getBlogById = async (params) => {
  let { id } = params;
  const response = await axios.get(
    endpoint.BLOGS + "/" + id, 
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const getReviews = async (params) => {
  let { limit, offset, searchText } = params;
  const response = await axios.get(
    endpoint.REVIEWS, 
    {
      params: { limit, offset, searchText },
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const getContactUs = async (params) => {
  let { limit, offset, searchText } = params;
  const response = await axios.get(
    endpoint.CONTACT_US, 
    {
      params: { limit, offset, searchText },
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const addReview = async (params) => {
  const response = await axios.post(endpoint.ADD_REVIEW, params,
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const deleteReview = async (params) => {
  let { id } = params;
  const response = await axios.delete(
    endpoint.REVIEWS+"/"+id, 
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const getReviewById = async (params) => {
  let { id } = params;
  const response = await axios.get(
    endpoint.REVIEWS + "/" + id, 
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const studentsServices = { 
  addReview,
  getStudents,
  getStudentById,
  updateApplication,
  getApplication,
  getApplicationById,
  getBlogs,
  addBlog,
  updateBlog,
  getBlogById,
  getReviews,
  deleteReview,
  getReviewById,
  getContactUs
};

export default studentsServices;
