import { ConstructionOutlined } from "@mui/icons-material";
import axios from "axios";
import endpoint from "../../index.routes";

const getCoaching = async (params) => {
    const { limit, offset, searchText, request_status } = params;
    console.log("search====",searchText)
    const response = await axios.get(
      endpoint.COACHING,
      {
        params: searchText ? { limit, offset, searchText, request_status } : { limit, offset, request_status },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    return response.data;
};

const getCoachingById = async (params) => {
  const { universityId } = params;
  const response = await axios.get(
    endpoint.COACHING + "/" + universityId,
    {
      // params: { limit, offset },
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const updateCoachingData = async (params) => {

  const response = await axios.put(`${endpoint.UPDATE_COACHING_DATA}/${params?.id}`, 
    params?.body, {
    headers: { token: localStorage.getItem("hp_token") }, 
  });
  return response.data;
}

const getCoachingByToken = async() => {
  const response = await axios.get(endpoint.COACHING_BY_TOKEN, {
      headers: { token: localStorage.getItem("new_coaching_token") },
  });
  return response.data;
}

const coachingStatusUpdate = async (params) => {
  const {
    request_status, 
    reject_reason, 
    id,
    from,
    changes,
    information_check } = params;
  let body = {};
  if(from === "reject"){
    body = {request_status, reject_reason}
  }
  if(from === "review"){
    body = {request_status, changes, information_check}
  }
  if(from === "approve"){
    body = {request_status}
  }
  if(from === "registration"){
    body = {request_status, changes}
  }
  if(from === "unblock"){
    body = {request_status}
  }
  if(from === "block"){
    body = {request_status}
  }
  const response = await axios.put(
    endpoint.COACHING + "/" + id, body,
    {
      headers: { token: localStorage.getItem("hp_token") }
    }
  );
  return response.data;
}
const coachingServices = { 
    getCoaching, 
    getCoachingById,
    coachingStatusUpdate,
    getCoachingByToken,
    updateCoachingData
};

export default coachingServices;
