import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { 
  Button, 
  IconButton, 
  Typography, 
  TextField } from "@mui/material";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import MealTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import Spinner from "../../components/Spinner/Spinner";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import DialogContent from "@mui/material/DialogContent";
import RejectDialogIcon from '../../assest/images/reject_dialog_icon.png';
import { 
  getStudents, 
  setStudentsById
} from "../../redux/students/studentsSlice";

const Students = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRequestModel, setIsRequestModel] = useState(false);

  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    studentsData,
  } = useSelector((state) => state.students);

  const colR = ["SNo", "Name Of Student", "Course Name", "Phone Number", "Email ID", "DOB", "action"];

  useEffect(() => {
    const res = dispatch(getStudents({limit, offset}));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset, dispatch]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getStudents({limit, offset, searchText}));
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleChange = () => {

  }
  
  const studentById = (id) => {
    dispatch(setStudentsById(id));
  }
  return (
    <>
      <Dialog maxWidth="lg" open={isDialogOpen} onClose={handleClose} fullWidth>
        <form onSubmit={handleChange} style={{padding: '0 20% 2% 20%'}}>
          <DialogContent>
            <Typography component="h2" variant="h2" 
              sx={{
                color: '#D60000',
                textAlign: 'center',
                fontSize: '46px',
                margin: '24px 0 48px'}}>
              {isRequestModel ? 'Request changes' : 'Rejected'}
            </Typography>
            {!isRequestModel && <div style={{textAlign: 'center', marginBottom: '36px'}}>
              <img src={RejectDialogIcon} alt="Rejected_Icon" />
            </div>}
            <div style={{
              width: '100%',
              border: '1px solid #000000',
              height: '290px'
            }} className="reject-buttons">
              <TextField fullWidth 
                placeholder="Write a reason for rejection" 
                id="outlined-basic" 
                variant="outlined"
                multiline
                rows={7}
                sx={{border: 'none'}} />
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '36px 12px 0 0'
              }}>
                {!isRequestModel && <Button 
                  variant="contained" 
                  type="submit" 
                  sx={{
                    backgroundColor: '#DC0707',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '170px',
                    marginRight: '12px',
                    borderRadius: '8px'
                    }}>
                  {'Block'}
                </Button>}
                <Button 
                  variant="contained" 
                  type="submit" 
                  sx={{
                    backgroundColor: '#07AB35',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '140px',
                    marginLeft: '12px',
                    borderRadius: '8px'
                    }}>
                  {'Submit'}
                </Button>
              </div>
            </div>
          </DialogContent>
        </form>
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="students"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <MealTable
          columns={colR}
          where="recipes"
          rows={studentsData && studentsData?.map((item) => {
            const res = {
              ...item,
              action: (
                <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => {studentById(item._id)}}
                    >
                      <Link to={routes.STUDENT_DETAILS}>
                        <VisibilityIcon sx={{color: '#3C1CDE'}} />
                        <Typography component="p" variant="p" 
                          sx={{
                            color: '#3C1CDE',
                            fontSize: '18px',
                            fontWeight: 'bold'
                            }}>
                          View
                        </Typography>
                      </Link>
                    </IconButton>
              )
            };
            return res;
          })}
        />
      </div>
    </>
  );
};

export default Students;
