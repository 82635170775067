import { configureStore } from "@reduxjs/toolkit";

// Slices
import authReducer from "./auth/authSlice";
import usersReducer from "./users/userSlice";
import routeReducer from "./routeSlice";
import configReducer from "./configurationDetails/configDetailsSlice";
import uploadOnS3Reducer from "./uploadDataOnS3/uploadDataS3.slice";
import subAdminSlice from "./sub-admin/subAdminSlice";
import universitySlice from "./universities/universitySlice";
import coachingSlice from "./coaching/coachingSlice";
import studentsSlice from "./students/studentsSlice";
import instituteProdSlice from "./instituteProd/instituteProdSlice";
import partnerSlice from "./partner/partnerSlice";
import customPageSlice from "./customPages/customPageSlice";
import leadSlice from "./lgs/lgsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    route: routeReducer,
    config: configReducer,
    uploadOnS3: uploadOnS3Reducer,
    subAdmin: subAdminSlice,
    university: universitySlice,
    coaching: coachingSlice,
    students: studentsSlice,
    institute_prod: instituteProdSlice,
    partner: partnerSlice,
    customPage: customPageSlice,
    lead: leadSlice,
  },
});

export default store;
