import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  Box,
  Button, 
  Select,
  IconButton, 
  Typography, 
  MenuItem } from "@mui/material";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import MealTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import Spinner from "../../components/Spinner/Spinner";
import NotesIcon from "../../assest/images/notes.png";
import Dialog from "@mui/material/Dialog";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { setPageType } from "../../redux/customPages/customPageSlice";
import { toast } from "react-toastify";
import { 
  getCustomPages,
  updateCustomPages,
  getCustomPagesById,
  deleteCustomPage } from "../../redux/customPages/customPageSlice";

const customPage = [
    "Country",
    "Program",
    "Exam",
    "Service"
]

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pageId, setPageId] = useState("");
  const [openFormDropdown, setOpenFormDropdown] = useState(false);

  const colR = ["SNo", "Name", "Type", "Status", "actions"];

  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    customPageData } = useSelector(
    (state) => state.customPage
  );

  useEffect(() => {
    localStorage.setItem("dataShow", "New");
    const res = dispatch(getCustomPages({ limit, offset, searchText: "" }));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getCustomPages({ limit, offset, searchText }));
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const openApproveAlert = (id) => {
    setIsDialogOpen(true);
    setPageId(id);
  }

  const changeStatus = (status, item) => {
    const mainRes = dispatch(getCustomPagesById({id: item?._id}));
    mainRes?.then((r) => {
      if(r?.payload?.statusCode === 200){
        let sectionArray = r?.payload?.data?.sections?.map(({ _id, __v, ...rest }) => rest);
        let body = {
          page_type: r?.payload?.data?.page_type,
          name: r?.payload?.data?.name,
          page_id: r?.payload?.data?.page_id,
          status: status,
          exam: r?.payload?.data?.exam,
          sections: sectionArray
        }
        const res = dispatch(updateCustomPages({id: item?._id, body}));
        res?.then((update) => {
          if(update?.payload?.statusCode === 200){
            toast.success("Status Updated Successfully");
            dispatch(getCustomPages({ limit, offset, searchText: "" }));
          }else{
            toast.error(update?.payload?.message);
          }
        })
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  const viewPage = (id) => {
    const res = dispatch(getCustomPagesById({id}));
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        let type = "";
        r?.payload?.data?.page_type === 'service' && (type = 'Service')
        r?.payload?.data?.page_type === 'exam' && (type = 'Exam')
        r?.payload?.data?.page_type === 'program' && (type = 'Program')
        r?.payload?.data?.page_type === 'country' && (type = 'Country')
        dispatch(setPageType(type));
        localStorage.setItem("dataShow", "View");
        navigate(routes.NEW_CUSTOM_PAGE);
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  const editPage = (id) => {
    const res = dispatch(getCustomPagesById({id}));
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        let type = "";
        r?.payload?.data?.page_type === 'service' && (type = 'Service')
        r?.payload?.data?.page_type === 'exam' && (type = 'Exam')
        r?.payload?.data?.page_type === 'program' && (type = 'Program')
        r?.payload?.data?.page_type === 'country' && (type = 'Country')
        dispatch(setPageType(type));
        localStorage.setItem("dataShow", "Edit");
        navigate(routes.NEW_CUSTOM_PAGE);
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  const deletePage = () => {
    const res = dispatch(deleteCustomPage({id: pageId}));
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        setIsDialogOpen(false);
        toast.success("Page Deleted Successfully");
        dispatch(getCustomPages({ limit, offset, searchText: "" }));
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  return (
    <>
      <Dialog maxWidth={"xs"} 
        open={isDialogOpen} 
        onClose={handleClose} 
        fullWidth>
        <div>
          <Typography component="p" variant="p" 
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              margin: '28px 0 48px'}}>
            Are you sure you want to<br/> delete the page
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: '0px 16px 28px 16px'
          }}>
            <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
              textTransform: 'capitalize'
            }}>
              Cancel
            </Button>
            <Button onClick={deletePage} variant="contained" sx={{
              backgroundColor: 'var(--primary-color)',
              width: '84px',
              marginRight: '12px',
              textTransform: 'capitalize'
            }}>
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="customPage"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <div style={{
          display: 'flex', 
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: '12px',
          }}>
          <Button variant="contained"
            onClick={() => setOpenFormDropdown(true)}
            sx={{
              backgroundColor: '#F69220',
              textTransform: 'capitalize',
              borderRadius: '8px'
              }}>
            <ControlPointIcon sx={{marginRight: '4px'}} />
              Add new custom page
          </Button>
        </div>
        <MealTable
          columns={colR}
          where="recipes"
          rows={customPageData && customPageData?.map((item) => {
            const res = {
              ...item,
              Status: (
                <div>
                  {item?.status !== 'active' ?
                  <Button sx={{
                    backgroundColor: '#07AB35',
                    color: '#ffffff',
                    width: '98px',
                    height: '35px',
                    '&:hover': {
                      backgroundColor: '#07AB35',
                    }
                  }} onClick={() => changeStatus("active", item)}>
                    Active
                  </Button>
                  :
                  <Button sx={{
                    backgroundColor: 'rgb(0, 0, 0, 0.1)',
                    color: 'rgb(0, 0, 0, 0.4)',
                    border: '1px solid rgb(0, 0, 0, 0.1)',
                    width: '98px',
                    height: '35px',
                    '&:hover': {
                      backgroundColor: 'rgb(0, 0, 0, 0.1)',
                    }
                  }} onClick={() => changeStatus("inactive", item)}>
                    Inactive
                  </Button>}
                </div>
              ),
              actions: (
                <div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      onClick={() => viewPage(item?._id)}
                    >
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                    </IconButton>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 16px 0 8px'
                      }}
                      onClick={() => editPage(item._id)}
                    >
                      <img src={NotesIcon} alt="notes_logo" width="20" />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#ECAB2D',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '9px'
                          }}>
                        Edit
                      </Typography>
                    </IconButton>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      onClick={() => openApproveAlert(item._id)}
                    >
                      <DeleteIcon sx={{color: '#EF4840'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#EF4840',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '6px'
                          }}>
                        Delete
                      </Typography>
                    </IconButton>
                  </div>
                </div>
              )
            };
            return res;
          })
        }
        />
      </div>
      <FormDropdown 
        open={openFormDropdown}
        handleClick={() => setOpenFormDropdown(!openFormDropdown)} />
      </>
  );
};

const FormDropdown = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pageType } = useSelector(
      (state) => state.customPage
    );
    const createNewPage = () => {
      if(pageType === ""){
        toast.error("Please Select Page Type");
        return;
      }
      localStorage.setItem("dataShow", "New");
      navigate(routes.NEW_CUSTOM_PAGE);
    }

    const handleChange = (e) => {
      dispatch(setPageType(e.target.value));
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
            // onClick={() => props.handleClick()}
        >
            <div className="notification-box" style={{
                top: '294px'
            }}>
                <h3 style={{
                    fontSize: '18px',
                    fontWeight: 400,
                    color: 'rgb(0, 0, 0, 0.5)'
                }}>
                    Which type of <b style={{color: '#000'}}>Custom Page</b> you want to create?
                </h3>
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <h3>
                        Type :
                    </h3>
                    <Select
                        sx={{
                            height: 40,
                            marginLeft: '8px',
                            width: 210
                        }}
                        name="budget"
                        defaultValue=""
                        displayEmpty
                        onChange={handleChange}
                        renderValue={(value) => {
                        return (
                            <Box sx={{ 
                            display: "flex", 
                            gap: 1, 
                            alignItems: 'center'}}>
                            {value}
                            </Box>
                        );
                        }}
                    >
                        {customPage.map((item, index) => (
                        <MenuItem value={item} key={index}>
                        {item}
                        </MenuItem>))}
                    </Select>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '24px 12px 12px 24px'
                }}>
                    <Button 
                        sx={{
                            textTransform: 'capitalize',
                            background: 'rgb(0, 0, 0, 0.2)',
                            "&:hover":{
                                background: 'rgb(0, 0, 0, 0.3)'
                            }
                        }}
                        onClick={() => props.handleClick()}>
                        Cancel
                    </Button>
                    <Button sx={{
                        background: 'var(--primary-color)',
                        color: '#fff',
                        textTransform: 'capitalize',
                        "&:hover":{
                            background: 'var(--primary-color)'
                        }
                    }} onClick={createNewPage}>
                        Create New
                    </Button>
                </div>
            </div>
        </Backdrop>
    )
}

export default Registration;
