import React, { useState } from "react";
import JoditEditor from 'jodit-react';
import { useSelector } from "react-redux";
import { 
    InputLabel,
    TextField } from "@mui/material";
import "./blogs.css";

const BlogsDetail = () => {
    const editor = React.useRef(null);
    const [formData, setFormData] = useState({})
    const { blogById } = useSelector((state) => state.students);
    React.useEffect(() => {
        setFormData(blogById);
    },[])
    const handleChange = () => {

    }
    return (
        <section className="blog-box">
            <div style={{width: '950px'}}>
                <div style={{
                    width: '100%',
                    marginBottom: '20px'
                }}>
                    <InputLabel className="input-label-text">
                        Title
                    </InputLabel>
                    <TextField 
                        placeholder="" 
                        name="title" 
                        type="text" 
                        variant="outlined" 
                        className="inputShadow"
                        value={formData.title}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} onChange={handleChange} required/>
                </div>
                <div style={{
                    marginBottom: '20px'
                }} className="rich-text-box">
                    <InputLabel className="input-label-text">Infrastructure</InputLabel>
                    <JoditEditor 
                        ref={editor}
                        value={formData.description}
                        // onChange={newContent => setContent(newContent)}
                    />
                </div>
                <div className="blog-tag-box">
                    <div className="blog-tag-box-inp">
                        <InputLabel className="input-label-text">
                            Tags
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="university" 
                            type="text" 
                            variant="outlined" 
                            className="inputShadow"
                            value={formData?.tags}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                    </div>
                    <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                        <InputLabel className="input-label-text">
                            Category Taxanomies
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="category" 
                            type="text" 
                            variant="outlined" 
                            className="inputShadow"
                            value={formData.category}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} onChange={handleChange} required/>
                    </div>
                    <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                        <InputLabel className="input-label-text">
                            Embedded Videos
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="embedded_video" 
                            type="text" 
                            variant="outlined" 
                            className="inputShadow"
                            value={formData.embedded_videos}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} onChange={handleChange} required/>
                    </div>
                </div>
                <div className="blog-tag-box">
                    <div className="blog-tag-box-inp">
                        <InputLabel className="input-label-text">
                            Meta Title
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="meta_title" 
                            type="text" 
                            variant="outlined" 
                            className="inputShadow"
                            value={formData?.meta?.title}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} onChange={handleChange} required/>
                    </div>
                    <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                        <InputLabel className="input-label-text">
                            Meta Descriptions
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="meta_desc" 
                            type="text" 
                            variant="outlined" 
                            className="inputShadow"
                            value={formData?.meta?.description}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                    </div>
                    <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                        <InputLabel className="input-label-text">
                            Meta Tags
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="university" 
                            type="text" 
                            variant="outlined" 
                            className="inputShadow"
                            value={formData?.meta?.keywords?.map((item, index) => (
                                index + 1 === formData?.meta?.keywords.length ? 
                                `${item}` : `${item}, `
                              ))}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                    </div>
                </div>
                <div>
                    <InputLabel className="input-label-text">
                        Upload Images
                    </InputLabel>
                    <div style={{display: 'inline-block', marginTop: '8px'}}>
                        {formData?.images?.map((item, index) => (
                            item !== "" && <div key={index} style={{
                                display: 'inline-block', 
                                marginRight: '16px',
                                marginBottom: '12px'
                                }}>
                                <img src={item} alt="imageName" width="216" height="128" />
                            </div>
                        ))} 
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogsDetail;