import * as React from 'react';
import debounce from "lodash.debounce";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import StudyIn from '../../assest/svgs/study_in.svg';
import StudyPlaces from '../../assest/svgs/study_places.svg';
import TopCourses from '../../assest/svgs/top_courses.svg';
import Admission from '../../assest/svgs/admission.svg';
import FeatureIcon from '../../assest/svgs/feature.svg';
import PlacementIcon from '../../assest/svgs/placement.svg';
import FaqsIcon from '../../assest/svgs/faqs.svg';
import LatestBlogsIcon from '../../assest/svgs/latest-blogs.svg';
import TestimonialIcon from '../../assest/svgs/testimonial.svg';
import Loader from "../../assest/images/preloader.gif";
import NewsIcon from '../../assest/svgs/news.svg';
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from '@mui/icons-material/Clear';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import Moment from "moment";
import { toast } from "react-toastify";
import UploadBox from '../../components/UploadBox/UploadBox';
import { makeStyles } from "@mui/styles";
import CustomPageForm from "./CustomPageForm";
import countryList from "../../data/countryList.json";
import { 
    Button, 
    Typography, 
    InputLabel,
    MenuItem,
    Select,
    TextField } from "@mui/material";
import { 
    getGroupList,
    updateCustomPages,
    addNewCustomPages } from "../../redux/customPages/customPageSlice";
import { 
    getUniversities,  
    setIsDropdown } from "../../redux/universities/universitySlice";

const useStyles = makeStyles( theme => ({
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    btnSubmit: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        "&:hover":{
            backgroundColor: '#07AB35',
        }
    },
    panelHeading: {
        fontSize: '18px !important',
        fontWeight: 'bold !important',
    },
    sideMenulist: {
        listStyleType: 'none',
        padding: 0
    },
    clearButton: {
        position: 'absolute !important',
        right: -8,
        top: 6,
        padding: '4px',
        backgroundColor: '#EF0000 !important',
        height: '16px',
        width: '16px !important',
        minWidth: '16px !important',
        borderRadius: '50% !important',
    }
}));

const sideMenu = [
    {
        icon: '',
        name: 'Banner image'
    },
    {
        icon: '',
        name: 'About course'
    },
    {
        icon: '',
        name: 'Features'
    },
    {
        icon: '',
        name: 'Career & placements'
    },
    {
        icon: '',
        name: 'Colleges'
    },
    {
        icon: '',
        name: 'Coaching'
    },
    {
        icon: '',
        name: 'Testimonials'
    },
    {
        icon: '',
        name: 'FAQs'
    },
    {
        icon: '',
        name: 'Latest blogs'
    },
    {
        icon: '',
        name: 'News & updates'
    }
]

const sideMenu2 = [
    {
        icon: '',
        name: 'Banner image'
    },
    {
        icon: '',
        name: 'Info of country'
    },
    {
        icon: '',
        name: 'Why study in'
    },
    {
        icon: '',
        name: 'Benefits'
    },
    {
        icon: '',
        name: 'Top courses'
    },
    {
        icon: '',
        name: 'Admission requirement'
    },
    {
        icon: '',
        name: 'Top colleges'
    },
    {
        icon: '',
        name: 'Top study places'
    },
    {
        icon: '',
        name: 'Testimonials'
    },
    {
        icon: '',
        name: 'FAQs'
    },
    {
        icon: '',
        name: 'Latest blogs'
    },
    {
        icon: '',
        name: 'News & updates'
    }
]

const examData = {
    page_type: '',
    name: '',
    page_id: '',
    status: 'active',
    exam: {
        exam_type: '',
        course_name: '',
        exam_category: '',
        exam_start_date: '',
        exam_end_date: '',
        logo: '',
    },
    sections: []
}

const formData = {
    page_type: '',
    name: '',
    page_id: '',
    status: 'active',
    meta_title: '',
    meta_description: '',
    sections: []
}

const examType = ["Online", "Offline", "Both"];
const courseName = ["MBBS", "BTECH", "BA"];
let collegeArray = [];
const NewCustom = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [reIndex, setReIndex] = React.useState(0);

    const { pageType, customPageById, groupList } = useSelector((state) => state.customPage)
    const [formInformation, setFormInformation] = React.useState(pageType === "Exam" ? examData : formData);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const addMoreFeature = (index, type) => {
        let oldArray = [...formInformation?.sections];
        let array = [...formInformation?.sections[index]?.options]
        if(type === "Features"){
            array.push({heading: '', text: ''});
        }
        if(type === "Career & placements"){
            array.push({job_name: '', pay_per_annum: '', text: ''});
        }
        if(type === "Info of country"){
            array.push({
                heading: '', 
                text: '', 
                image: ''
            });
        }
        if(type === "Colleges" || type === "Indian colleges"){
            array.push({
                city: '', 
                country: '', 
                college_logo: '',
                college_name: '',
                minimum_ielts: '',
                ielts_icon: '',
                ranking: '',
                ranking_icon: '',
                button_name: ''
            });
        }
        if(type === "Coaching"){
            array.push({
                city: '', 
                country: '', 
                image: '',
                title: '',
                minimum_ielts: '',
                ielts_icon: '',
                ranking: '',
                ranking_icon: '',
                button_name: ''
            });
        }
        if(type === "Testimonials"){
            array.push({
                student_name: '', 
                student_image: '', 
                university_name: '',
                review: '',
            });
        }
        if(type === "FAQs"){
            if(array?.length > 6){
                toast.error("Max 7 FAQs are allowed");
                return;
            }
            array.push({
                question: '', 
                answer: '', 
            });
        }
        if(type === "Latest blogs"){
            array.push({
                title: '',
                date: new Date(), 
                text: '',
                image: ''
            });
        }
        if(type === "News & updates"){
            array.push({
                heading: '', 
                date: new Date(), 
                text: '',
            });
        }
        if(type === "Benefits"){
            array.push({
                title: '', 
                text: '',
                image: ''
            });
        }
        if(type === "Top study places"){
            array.push({
                city: '', 
                image: ''
            });
        }
        if(type === "Admission requirement"){
            array.push({
                heading: '', 
                image: ''
            });
        }
        if(type === "Top courses"){
            array.push({
                total_colleges: '', 
                course_type: '',
                course_icon: '',
                course_name: ''
            });
        }
        oldArray[index].options = [...array];
        setFormInformation({...formInformation, sections: [...oldArray]})
    }

    const removeItem = (index, type) => {
        let oldArray = [...formInformation?.sections];
        let array = [...formInformation?.sections[index]?.options];
        array.pop();
        oldArray[index].options = [...array];
        setFormInformation({...formInformation, sections: [...oldArray]})
    }

    const removeItemCollege = (index, type, localI) => {
        let oldArray = [...formInformation?.sections];
        let array = [...formInformation?.sections[index]?.university_ids];
        array.splice(localI, 1);
        oldArray[index].university_ids = [...array];
        setFormInformation({...formInformation, sections: [...oldArray]})
    }

    const removeItemBlogs = (index, type, localI) => {
        let oldArray = [...formInformation?.sections];
        let array = [...formInformation?.sections[index]?.blogs_ids];
        array.splice(localI, 1);
        oldArray[index].blogs_ids = [...array];
        setFormInformation({...formInformation, sections: [...oldArray]})
    }

    const addItem = (text) => {
        let array = [...formInformation?.sections];
        let order = array.length;
        if(text === "Banner image"){
            let index = array.findIndex(item => item.section_type === "Banner image");
            if(index < 0){
                array.push({
                    section_type: 'Banner image',
                    order: order
                });
            }else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "About course"){
            let index = array.findIndex(item => item.section_type === "About course");
            if(index < 0){
                array.push({
                    section_type: 'About course',
                    order: order
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Info of country"){
            let index = array.findIndex(item => item.section_type === "Info of country");
            if(index < 0){
                array.push({
                    section_type: 'Info of country',
                    order: order,
                    options: [{
                        heading: '', 
                        text: '', 
                        image: ''
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Features"){
            let index = array.findIndex(item => item.section_type === "Features");
            if(index < 0){
                array.push({
                    section_type: 'Features', 
                    order: order,
                    options: [{heading: '', text: ''}]});
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Career & placements"){
            let index = array.findIndex(item => item.section_type === "Career & placements");
            if(index < 0){
                array.push({
                    section_type: 'Career & placements',
                    order: order,
                    options: [{job_name: '', pay_per_annum: '', text: ''}]});
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Colleges"){
            let index = array.findIndex(item => item.section_type === "Colleges");
            if(index < 0){
                array.push({
                    section_type: 'Colleges',
                    order: order,
                    options: [{
                        city: '', 
                        country: '', 
                        college_logo: '',
                        college_name: '',
                        minimum_ielts: '',
                        ielts_icon: '',
                        ranking: '',
                        ranking_icon: '',
                        button_name: ''
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Coaching"){
            let index = array.findIndex(item => item.section_type === "Coaching");
            if(index < 0){
                array.push({
                    section_type: 'Coaching',
                    order: order,
                    options: [{
                        city: '', 
                        country: '', 
                        image: '',
                        title: '',
                        minimum_ielts: '',
                        ielts_icon: '',
                        ranking: '',
                        ranking_icon: '',
                        button_name: ''
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Testimonials"){
            let index = array.findIndex(item => item.section_type === "Testimonials");
            if(index < 0){
                array.push({
                    section_type: 'Testimonials',
                    order: order,
                    options: [{
                        student_name: '', 
                        student_image: '', 
                        university_name: '',
                        review: '',
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "FAQs"){
            let index = array.findIndex(item => item.section_type === "FAQs");
            if(index < 0){
                array.push({
                    section_type: 'FAQs',
                    order: order,
                    options: [{
                        question: '', 
                        answer: '', 
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Latest blogs"){
            let index = array.findIndex(item => item.section_type === "Latest blogs");
            if(index < 0){
                array.push({
                    section_type: 'Latest blogs',
                    order: order,
                    blogs_ids: []
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "News & updates"){
            let index = array.findIndex(item => item.section_type === "News & updates");
            if(index < 0){
                array.push({
                    section_type: 'News & updates',
                    order: order,
                    options: [{
                        heading: '', 
                        date: new Date(), 
                        text: '',
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Why study in"){
            let index = array.findIndex(item => item.section_type === "Why study in");
            if(index < 0){
                array.push({
                    section_type: 'Why study in',
                    order: order
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Benefits"){
            let index = array.findIndex(item => item.section_type === "Benefits");
            if(index < 0){
                array.push({
                    section_type: 'Benefits',
                    order: order,
                    options: [{
                        title: '', 
                        text: '',
                        image: ''
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Top colleges"){
            let index = array.findIndex(item => item.section_type === "Top colleges");
            if(index < 0){
                array.push({
                    section_type: 'Top colleges',
                    order: order,
                    university_ids: []
                });
                setReIndex(array?.length-1);
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Top study places"){
            let index = array.findIndex(item => item.section_type === "Top study places");
            if(index < 0){
                array.push({
                    section_type: 'Top study places',
                    order: order,
                    options: [{
                        city: '', 
                        image: ''
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Admission requirement"){
            let index = array.findIndex(item => item.section_type === "Admission requirement");
            if(index < 0){
                array.push({
                    section_type: 'Admission requirement',
                    order: order,
                    options: [{
                        heading: '', 
                        image: ''
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
        if(text === "Top courses"){
            let index = array.findIndex(item => item.section_type === "Top courses");
            if(index < 0){
                array.push({
                    section_type: 'Top courses',
                    order: order,
                    options: [{
                        total_colleges: '', 
                        course_type: '',
                        course_icon: '',
                        course_name: ''
                    }]
                });
            }
            else{
                toast.error("Section Already Added");
                return;
            }
            setFormInformation(
                {...formInformation, sections: [...array]})
        }
    }

    const handleMainInputChange = (e) => {
        e.target.name === "name" && setFormInformation(
            {...formInformation, name: e.target.value})
            
        e.target.name === "country_name" && setFormInformation(
            {...formInformation, name: e.target.value})

        e.target.name === "meta_title" && setFormInformation(
            {...formInformation, meta_title: e.target.value})

        e.target.name === "meta_description" && setFormInformation(
            {...formInformation, meta_description: e.target.value})

        e.target.name === "country_group" && setFormInformation(
            {...formInformation, country_group_id: e.target.value})

        e.target.name === "exam_type" && setFormInformation(
            {...formInformation, exam: {...formInformation.exam, 
                exam_type: e.target.value}})

        e.target.name === "course_name" && setFormInformation(
            {...formInformation, exam: {...formInformation.exam, 
                course_name: e.target.value}})
                
        e.target.name === "exam_start_date" && setFormInformation(
            {...formInformation, exam: {...formInformation.exam, 
                exam_start_date: e.target.value}})

        e.target.name === "exam_end_date" && setFormInformation(
            {...formInformation, exam: {...formInformation.exam, 
                exam_end_date: e.target.value}})

        e.target.name === "page_id" && setFormInformation(
            {...formInformation, page_id: e.target.value})
            
        e.target.name === "exam_category" && setFormInformation(
            {...formInformation, exam: {...formInformation.exam, 
                exam_category: e.target.value}})
    }

    const handleImageUploads = (name, value) => {
        if(name === "exam_logo"){
            setFormInformation(
                {...formInformation, exam: {...formInformation.exam, 
                    logo: value}})
        }
        if(name === "banner_image_image"){
            let array = [...formInformation?.sections];
            array = array.map((element) => {
                return element.section_type === "Banner image" ? {...element, 
                    ...({image: value})
                }: element
            })
            setFormInformation({...formInformation, sections: [...array]})
        }
        if(name === "about_course_image"){
            let array = [...formInformation?.sections];
            array = array.map((element) => {
                return element.section_type === "About course" ? {...element, 
                    ...({image: value})
                }: element
            })
            setFormInformation({...formInformation, sections: [...array]})
        }
        if(name === "study_in_image"){
            let array = [...formInformation?.sections];
            array = array.map((element) => {
                return element.section_type === "Why study in" ? {...element, 
                    ...({image: value})
                }: element
            })
            setFormInformation({...formInformation, sections: [...array]})
        }
    }

    const handleSectionInput = (e, type) => {
        let array = [...formInformation?.sections];
        array = array.map((element) => {
            return type === element.section_type ? {...element, 
                ...(e.target.name === `banner_image_heading` && 
                {heading: e.target.value}),
                ...(e.target.name === `banner_image_cta_button` && 
                {button_name: e.target.value}),
                ...(e.target.name === `banner_image_text` && 
                {text: e.target.value}),
                ...(e.target.name === `about_course_title` && 
                {title: e.target.value}),
                ...(e.target.name === `about_course_heading` && 
                {heading: e.target.value}),
                ...(e.target.name === `about_course_cta_button` && 
                {button_name: e.target.value}),
                ...(e.target.name === `features_title` && 
                {title: e.target.value}),
                ...(e.target.name === `career_&_placements_title` && 
                {title: e.target.value}),
                ...(e.target.name === `career_&_placements_heading` && 
                {heading: e.target.value}),
                ...(e.target.name === `indian_colleges_title` && 
                {title: e.target.value}),
                ...(e.target.name === `colleges_title` && 
                {title: e.target.value}),
                ...(e.target.name === `testimonials_title` && 
                {title: e.target.value}),
                ...(e.target.name === `testimonials_heading` && 
                {heading: e.target.value}),
                ...(e.target.name === `faqs_title` && 
                {title: e.target.value}),
                ...(e.target.name === `blogs_title` && 
                {title: e.target.value}),
                ...(e.target.name === `news_&_updates_title` && 
                {title: e.target.value}),
                ...(e.target.name === `info_of_country_heading` && 
                {heading: e.target.value}),
                ...(e.target.name === `study_in_title` && 
                {title: e.target.value}),
                ...(e.target.name === `study_in_heading` && 
                {heading: e.target.value}),
                ...(e.target.name === `benefits_title` && 
                {title: e.target.value}),
                ...(e.target.name === `benefits_heading` && 
                {heading: e.target.value}),
                ...(e.target.name === `top_colleges_title` && 
                {title: e.target.value}),
                ...(e.target.name === `top_study_places_title` && 
                {title: e.target.value}),
                ...(e.target.name === `admission_requirement_text` && 
                {text: e.target.value}),
                ...(e.target.name === `admission_requirement_title` && 
                {title: e.target.value}),
                ...(e.target.name === `top_courses_title` && 
                {title: e.target.value}),
                ...(e.target.name === `coaching_title` && 
                {title: e.target.value})
            }: element
        })
        setFormInformation({...formInformation, sections: [...array]})

    }

    const textEditor = (value, fromWhere) => {
        let array = [...formInformation?.sections];
        if(fromWhere === "about_course"){
            array = array.map((element) => {
                return element.section_type === "About course" ? {...element, 
                    ...({text: value})
                }: element
            })
            setFormInformation({...formInformation, sections: [...array]})
        }
        if(fromWhere === "career_&_placements"){
            array = array.map((element) => {
                return element.section_type === "Career & placements" ? {...element, 
                    ...({text: value})
                }: element
            })
            setFormInformation({...formInformation, sections: [...array]})
        }
        if(fromWhere === "study_in"){
            array = array.map((element) => {
                return element.section_type === "Why study in" ? {...element, 
                    ...({text: value})
                }: element
            })
            setFormInformation({...formInformation, sections: [...array]})
        }
    }

    const handleArray = (e, index, from, mainIndex) => {
        let oldArray = [...formInformation?.sections];
        let array = oldArray[mainIndex]?.options;

        array = array.map((element, i) => {
            return i === index ? {...element, 
                ...(e.target.name === `features_heading_${index}` && {heading: e.target.value}),
                ...(e.target.name === `features_text_${index}` && {text: e.target.value}),
                ...(e.target.name === `career_&_placements_job_name_${index}` && {job_name: e.target.value}),
                ...(e.target.name === `career_&_placements_pay_per_annum_${index}` && {pay_per_annum: e.target.value}),
                ...(e.target.name === `career_&_placements_text_${index}` && {text: e.target.value}),
                ...(e.target.name === `colleges_city_${index}` && {city: e.target.value}),
                ...(e.target.name === `colleges_country_${index}` && {country: e.target.value}),
                ...(e.target.name === `colleges_name_of_college_${index}` && {college_name: e.target.value}),
                ...(e.target.name === `colleges_ielts_${index}` && {minimum_ielts: e.target.value}),
                ...(e.target.name === `colleges_ranking_${index}` && {ranking: e.target.value}),
                ...(e.target.name === `colleges_cta_button_${index}` && {button_name: e.target.value}),
                ...(e.target.name === `coaching_city_${index}` && {city: e.target.value}),
                ...(e.target.name === `coaching_country_${index}` && {country: e.target.value}),
                ...(e.target.name === `coaching_name_of_college_${index}` && {title: e.target.value}),
                ...(e.target.name === `coaching_ielts_${index}` && {minimum_ielts: e.target.value}),
                ...(e.target.name === `coaching_ranking_${index}` && {ranking: e.target.value}),
                ...(e.target.name === `coaching_cta_button_${index}` && {button_name: e.target.value}),
                ...(e.target.name === `testimonials_student_name_${index}` && {student_name: e.target.value}),
                ...(e.target.name === `testimonials_university_name_${index}` && {university_name: e.target.value}),
                ...(e.target.name === `testimonials_review_${index}` && {review: e.target.value}),
                ...(e.target.name === `faqs_question_${index}` && {question: e.target.value}),
                ...(e.target.name === `faqs_answer_${index}` && {answer: e.target.value}),
                ...(e.target.name === `blogs_title_${index}` && {title: e.target.value}),
                ...(e.target.name === `blogs_date_${index}` && {date: e.target.value}),
                ...(e.target.name === `news_&_updates_heading_${index}` && {heading: e.target.value}),
                ...(e.target.name === `news_&_updates_text_${index}` && {text: e.target.value}),
                ...(e.target.name === `news_&_updates_date_${index}` && {date: e.target.value}),
                ...(e.target.name === `info_of_country_heading_${index}` && {heading: e.target.value}),
                ...(e.target.name === `info_of_country_text_${index}` && {text: e.target.value}),
                ...(e.target.name === `benefits_title_${index}` && {title: e.target.value}),
                ...(e.target.name === `benefits_text_${index}` && {text: e.target.value}),
                ...(e.target.name === `top_colleges_city_${index}` && {city: e.target.value}),
                ...(e.target.name === `top_colleges_country_${index}` && {country: e.target.value}),
                ...(e.target.name === `top_colleges_name_of_college_${index}` && {college_name: e.target.value}),
                ...(e.target.name === `top_colleges_ielts_${index}` && {minimum_ielts: e.target.value}),
                ...(e.target.name === `top_colleges_ranking_${index}` && {ranking: e.target.value}),
                ...(e.target.name === `top_colleges_cta_button_${index}` && {button_name: e.target.value}),
                ...(e.target.name === `top_study_places_city_${index}` && {city: e.target.value}),
                ...(e.target.name === `admission_requirement_heading_${index}` && {heading: e.target.value}),
                ...(e.target.name === `top_courses_number_of_colleges_${index}` && {total_colleges: e.target.value}),
                ...(e.target.name === `top_courses_course_type_${index}` && {course_type: e.target.value}),
                ...(e.target.name === `top_courses_name_of_course_${index}` && {course_name: e.target.value}),
                
            }: element
        })

        oldArray[mainIndex].options = array;
        setFormInformation({...formInformation, sections: [...oldArray]})
    }

    const handleArrayImage = (index, ind, name, value) => {
        let oldArray = [...formInformation?.sections];
        let array = oldArray[index]?.options;
        array = array.map((element, i) => {
            return i === ind ? {...element,
                ...(name === `colleges_ranking_${ind}` && {ranking_icon: value}),
                ...(name === `colleges_ielts_${ind}` && {ielts_icon: value}),
                ...(name === `colleges_college_icon_${ind}` && {college_logo: value}),
                ...(name === `coaching_ranking_${ind}` && {ranking_icon: value}),
                ...(name === `coaching_ielts_${ind}` && {ielts_icon: value}),
                ...(name === `coaching_college_icon_${ind}` && {image: value}),
                ...(name === `testimonials_image_${ind}` && {student_image: value}),
                ...(name === `blog_image_${ind}` && {image: value}),
                ...(name === `info_of_country_logo_${ind}` && {image: value}),
                ...(name === `benefits_image_${ind}` && {image: value}),
                ...(name === `top_colleges_ranking_${ind}` && {ranking_icon: value}),
                ...(name === `top_colleges_ielts_${ind}` && {ielts_icon: value}),
                ...(name === `top_colleges_college_icon_${ind}` && {college_logo: value}),
                ...(name === `top_study_places_icon_${ind}` && {image: value}),
                ...(name === `admission_requirement_icon_${ind}` && {image: value}),
                ...(name === `top_courses_icon_${ind}` && {course_icon: value}),
            }: element
        })
        
        oldArray[index].options = array;
        setFormInformation({...formInformation, sections: [...oldArray]})
    }

    const textArrayEditor = (value, name, ind, mainIndex) => {
        let oldArray = [...formInformation?.sections];
        let array = oldArray[mainIndex]?.options;

        array = array.map((element, i) => {
            return i === ind ? {...element, 
                ...(name === `blogs_text_${ind}` && {text: value}),
                ...(name === `news_&_updates_text_${ind}` && {text: value}),
            }: element
        })

        oldArray[mainIndex].options = array;
        setFormInformation({...formInformation, sections: [...oldArray]})
    }

    const clearItem = (index) => {
        let array = [...formInformation?.sections];
        array.splice(index, 1);
        let ind = array.findIndex(item => item.section_type === "Top colleges");
        setReIndex(ind);
        setFormInformation({...formInformation, sections: [...array]});
    }

    React.useEffect(() => {

        if(localStorage.getItem("dataShow") === "New"){
            setFormInformation({...formInformation, page_type: pageType?.toLowerCase()});
        }
    },[])

    React.useEffect(() => {
        if(localStorage.getItem("dataShow") === "View" ||
            localStorage.getItem("dataShow") === "Edit"){
            let body = {}
            let sectionArray = customPageById?.sections?.map(({ _id, __v, ...rest }) => rest);
            if(pageType === "Exam"){
                body = {
                    page_type: customPageById?.page_type,
                    name: customPageById?.name,
                    page_id: customPageById?.page_id,
                    status: customPageById?.status,
                    exam: customPageById?.exam,
                    meta_title: customPageById?.meta_title,
                    meta_description: customPageById?.meta_description,
                    sections: sectionArray
                }
            }
            else if(pageType === "Country"){
                body = {
                    page_type: customPageById?.page_type,
                    name: customPageById?.name,
                    page_id: customPageById?.page_id,
                    status: customPageById?.status,
                    meta_title: customPageById?.meta_title,
                    meta_description: customPageById?.meta_description,
                    country_group_id: customPageById?.country_group_id,
                    sections: sectionArray
                }
            }
            else{
                body = {
                    page_type: customPageById?.page_type,
                    name: customPageById?.name,
                    page_id: customPageById?.page_id,
                    status: customPageById?.status,
                    sections: sectionArray,
                    meta_title: customPageById?.meta_title,
                    meta_description: customPageById?.meta_description,
                }
            }
            setFormInformation({...body});
        }
    },[])

    React .useEffect(() => {
        dispatch(getGroupList());
    }, []);

    const submitData = () => {
        if(validate().isValid){
            let filteredObj = {}
            let body = {};
            if(formInformation?.page_type === "exam"){
                for (const key in formInformation?.exam) {
                    if (formInformation?.exam.hasOwnProperty(key) && 
                        formInformation?.exam[key] !== null && 
                        formInformation?.exam[key] !== undefined && 
                        formInformation?.exam[key] !== '') {
                            filteredObj[key] = formInformation?.exam[key];
                    }
                }
                body = {
                    page_type: formInformation?.page_type,
                    name: formInformation?.name,
                    page_id: formInformation?.page_id,
                    status: formInformation?.status,
                    meta_title: formInformation?.meta_title,
                    meta_description: formInformation?.meta_description,
                    exam: filteredObj,
                    sections: formInformation?.sections
                }
            }

            setIsLoader(true);
            const res = localStorage.getItem("dataShow") === "Edit" ?
                dispatch(updateCustomPages(
                    formInformation?.page_type === "exam" ? 
                    {id: customPageById?._id, body} :
                    {id: customPageById?._id, body: formInformation})) : 
                dispatch(addNewCustomPages(
                    formInformation?.page_type === "exam" ? 
                    body :
                    formInformation));
            res?.then((r) => {
                setIsLoader(false);
                if(r?.payload?.status || r?.payload?.statusCode){
                    if(r?.payload?.status === 200 || 
                        r?.payload?.statusCode === 200){
                        toast.success(
                            localStorage.getItem("dataShow") === "Edit" ?
                            "Page Updated Successfully"
                            :
                            "Page Added Successfully");
                    }else{
                        toast.error(r?.payload?.message);
                    }
                }else{
                    toast.error(r?.payload);
                }
            })
        }else{
            toast.error(validate().message);
        }
    }

    const validate = () => {
        let status = { isValid: true, message: '' };
        if(formInformation?.page_type === ""){
            status.isValid = false;
            status.message = "Page type required";
            return status;
        }
        if(formInformation?.name === ""){
            status.isValid = false;
            status.message = "Exam name required";
            return status;
        }
        if(formInformation?.exam?.exam_type === "" && pageType === "Exam"){
            status.isValid = false;
            status.message = "Exam type required";
            return status;
        }
        if(formInformation?.exam?.course_name === "" && pageType === "Exam"){
            status.isValid = false;
            status.message = "Course name required";
            return status;
        }
        if(formInformation?.page_id === ""){
            status.isValid = false;
            status.message = "Page ID required";
            return status;
        }
        if(formInformation?.exam?.exam_category === "" && pageType === "Exam"){
            status.isValid = false;
            status.message = "Exam category required";
            return status;
        }
        return status;
    }

    const handleSearchText = (e) => {
        debouncedSave(e.target.value !== "" ? e.target.value : undefined)
    }

    const debouncedSave = React.useCallback(
        debounce((newValue) => dispatch(getUniversities({ searchText: newValue, request_status: "accepted" })), 800),
        []
    );
    
    const addMoreCollege = (collegeInfo) => {
        let oldArray = [...formInformation?.sections];
        let newindex = formInformation?.sections.findIndex(obj => obj?.section_type === "Top colleges");
        
        let array = [...formInformation?.sections[newindex]?.university_ids];
        // let collegePresent = array.filter((item) => {
        //     return item._id === collegeInfo?._id;
        // });
        dispatch(setIsDropdown(false));
        if(array?.length > 3) return toast.error("Maximum 4 colleges allowed");
        let index = array?.indexOf(collegeInfo?._id);
        if(index > -1){
            toast.error("College Already Added");
            return;
        }
        else{
            array.push(collegeInfo?._id);
            oldArray[newindex].university_ids = [...array];
            setFormInformation({...formInformation, sections: [...oldArray]});
        }
    }

    const addMoreBlog = (blogInfo) => {
        console.log(formInformation);
        let oldArray = [...formInformation?.sections];
        let newindex = formInformation?.sections.findIndex(obj => obj?.section_type === "Latest blogs");
        
        let array = [...formInformation?.sections[newindex]?.blogs_ids];
        // let collegePresent = array.filter((item) => {
        //     return item._id === collegeInfo?._id;
        // });
        dispatch(setIsDropdown(false));
        if(array?.length > 3) return toast.error("Maximum 4 blogs allowed");
        let index = array?.indexOf(blogInfo?._id);
        if(index > -1){
            toast.error("Blog Already Added");
            return;
        }
        else{
            array.push(blogInfo?._id);
            oldArray[newindex].blogs_ids = [...array];
            setFormInformation({...formInformation, sections: [...oldArray]});
        }
    }

    return (
        <div style={{
            display: 'flex',
            height: '100%'
        }}>
            <div style={{
                width: '214px',
                paddingRight: '24px',
                marginRight: '24px',
                borderRight: '1.6px solid rgb(0, 0, 0, 0.1)',
                paddingTop: '24px'
            }}>
                {pageType === "Exam" ?
                <ul className={classes.sideMenulist}>
                    {sideMenu?.map((item, index) => (
                        <li key={index} style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '24px',
                        }}>
                            {item.name === "Banner image" && <ImageIcon />}
                            {item.name === "About course" && <InfoIcon />}
                            {item.name === "Colleges" && <SchoolIcon />}
                            {item.name === "Coaching" && <CoPresentIcon />}
                            {item.name === "Features" && 
                                <img src={FeatureIcon} alt="feature" width="19" />}
                            {item.name === "Career & placements" && 
                                <img src={PlacementIcon} alt="feature" width="27" />}
                            {item.name === "Testimonials" && 
                                <img src={TestimonialIcon} alt="feature" width="23" />}
                            {item.name === "FAQs" && 
                                <img src={FaqsIcon} alt="feature" width="19" 
                                    style={{marginLeft: '3px'}} />}
                            {item.name === "Latest blogs" && 
                                <img src={LatestBlogsIcon} alt="feature" width="19" />}
                            {item.name === "News & updates" && 
                                <img src={NewsIcon} alt="feature" width="23" />}
                            <span style={{
                                width: '120px'
                            }}>{item.name}</span>
                            <AddIcon onClick={() => addItem(item.name)} />
                        </li>
                    ))}
                </ul>:
                <ul className={classes.sideMenulist}>
                    {sideMenu2?.map((item, index) => (
                        <li key={index} style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '24px',
                        }}>
                            {item.name === "Banner image" && <ImageIcon />}
                            {item.name === "Info of country" && <InfoIcon />}
                            {(item.name === "Why study in") && 
                                <img src={StudyIn} alt="study" width="19" />}
                            {item.name === "Benefits" && 
                                <img src={FeatureIcon} alt="benefits" width="19" />}
                            {item.name === "Top courses" && 
                                <img src={TopCourses} alt="topCourse" width="18" />}
                            {item.name === "Admission requirement" && 
                                <img src={Admission} alt="admission" width="18" />}
                            {item.name === "Top colleges" && 
                                <SchoolIcon />}
                            {item.name === "Top study places" && 
                                <img src={StudyPlaces} alt="studyPlace" width="22" />}
                            {item.name === "Testimonials" && 
                                <img src={TestimonialIcon} alt="feature" width="23" />}
                            {item.name === "FAQs" && 
                                <img src={FaqsIcon} alt="feature" width="19" 
                                    style={{marginLeft: '3px'}} />}
                            {item.name === "Latest blogs" && 
                                <img src={LatestBlogsIcon} alt="feature" width="19" />}
                            {item.name === "News & updates" && 
                                <img src={NewsIcon} alt="feature" width="23" />}
                            <span style={{
                                width: '120px'
                            }}>{item.name}</span>
                            <AddIcon onClick={() => addItem(item.name)} />
                        </li>
                    ))}
                </ul>}
            </div>
            <div style={{width: '80%'}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: '16px'
                }}>
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <h3>
                                Creating Custom Page :{" "}
                                <span style={{
                                    color: '#F69220', 
                                    textTransform: 'uppercase'
                                    }}>
                                    {pageType}
                                </span>
                            </h3>
                            <div>
                                {localStorage.getItem("dataShow") !== "View" &&
                                <Button 
                                    className={classes.btnSubmit}
                                    onClick={submitData}
                                    disabled={isLoader}
                                    sx={{
                                       background: isLoader ? 'rgb(0, 0, 0, 0.4)' : '#07AB35',
                                       '&:hover':{
                                            background: isLoader ? 'rgb(0, 0, 0, 0.4)' : '#07AB35',
                                       }
                                    }}>
                                    {isLoader ? 
                                    <img src={Loader} width="24" alt="loader" />
                                    :
                                    (localStorage.getItem("dataShow") === "Edit" ? 'Update' : 'Submit')}
                                </Button>}
                            </div>
                        </div>
                        {pageType === "Exam" &&
                        <>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div>
                                    <InputLabel className={classes.formInputLabel}>
                                        Exam
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name="name" 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        onChange={handleMainInputChange} 
                                        value={formInformation?.name} 
                                        required/>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Exam Type
                                    </InputLabel>
                                    <Select
                                        name="exam_type"
                                        value={formInformation?.exam?.exam_type}
                                        onChange={handleMainInputChange}
                                        placeholder=""
                                        autoWidth
                                        required
                                        sx={{
                                            width: '250px',
                                            height: '56px',
                                            position: 'relative',
                                            marginRight: '12px'
                                        }}
                                        >
                                            {examType && examType.map((item, index) => (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                                <div style={{marginLeft: '12px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Course Name
                                    </InputLabel>
                                    <Select
                                        name="course_name"
                                        value={formInformation?.exam?.course_name}
                                        onChange={handleMainInputChange}
                                        placeholder=""
                                        autoWidth
                                        required
                                        sx={{
                                            width: '250px',
                                            height: '56px',
                                            position: 'relative',
                                            marginRight: '12px'
                                        }}
                                        >
                                            {courseName && courseName?.map((item, index) => (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                margin: '12px 0'
                            }}>
                                <div>
                                    <InputLabel className={classes.formInputLabel}>
                                        Page ID
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name="page_id" 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        onChange={handleMainInputChange} 
                                        value={formInformation?.page_id} 
                                        required/>
                                </div>
                                <div style={{marginLeft: '24px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Exam Start Date{" "}
                                        <span className="optional-text-style">(Optional)</span>
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="date" 
                                        name="exam_start_date" 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        value={formInformation?.exam?.exam_start_date ?
                                            Moment(formInformation?.exam?.exam_start_date).format("YYYY-MM-DD") : ''}
                                        onChange={handleMainInputChange} 
                                        required/>
                                </div>
                                <div style={{marginLeft: '24px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Exam End Date{" "}
                                        <span className="optional-text-style">(Optional)</span>
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="date" 
                                        name="exam_end_date" 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        value={formInformation?.exam?.exam_end_date ?
                                            Moment(formInformation?.exam?.exam_end_date).format("YYYY-MM-DD") : ''}
                                        onChange={handleMainInputChange} 
                                        required/>
                                </div>
                                
                            </div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div style={{marginBottom: '12px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Exam Category
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name="exam_category" 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        onChange={handleMainInputChange} 
                                        value={formInformation?.exam?.exam_category} 
                                        required/>
                                </div>
                                <div style={{
                                    marginBottom: '12px',
                                    marginLeft: '24px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Logo of Exam <span className="optional-text-style">(Optional)</span>
                                    </InputLabel>
                                    <UploadBox
                                        name="exam_logo"
                                        value={formInformation?.exam?.logo}
                                        handleUpload={(name, value) => handleImageUploads(name, value)} 
                                        />
                                </div>
                            </div>
                        </>
                        }
                        {pageType === "Country" &&
                        <>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div>
                                    <InputLabel className={classes.formInputLabel}>
                                        Country Name
                                    </InputLabel>
                                    <Select
                                        name="country_name"
                                        value={formInformation?.name}
                                        onChange={handleMainInputChange}
                                        placeholder=""
                                        autoWidth
                                        required
                                        sx={{
                                            width: '250px',
                                            height: '56px',
                                            position: 'relative',
                                            marginRight: '12px'
                                        }}
                                        >
                                            {countryList && countryList.map((item, index) => (
                                                <MenuItem value={item?.country} key={index}>
                                                    {item?.country}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                                <div style={{marginLeft: '24px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Page ID
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name="page_id" 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        onChange={handleMainInputChange} 
                                        value={formInformation?.page_id} 
                                        required/>
                                </div>
                                <div style={{marginLeft: '24px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Country Group <span className="optional-text-style">(Optional)</span>
                                    </InputLabel>
                                    <Select
                                        name="country_group"
                                        value={formInformation?.country_group_id ?? ""}
                                        onChange={handleMainInputChange}
                                        placeholder=""
                                        autoWidth
                                        required
                                        sx={{
                                            width: '250px',
                                            height: '56px',
                                            position: 'relative',
                                            marginRight: '12px'
                                        }}>
                                            {groupList && groupList.map((item, index) => (
                                                <MenuItem value={item?._id} key={index}>
                                                    {item?.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                        </>
                        }
                        {(pageType === "Program" || pageType === "Service") &&
                        <>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div>
                                    <InputLabel className={classes.formInputLabel}>
                                        {pageType === "Program" && "Program Name"}
                                        {pageType === "Service" && "Service Name"}
                                    </InputLabel>
                                    <TextField 
                                        placeholder=""
                                        type="text"
                                        name="name"
                                        variant="outlined"
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        onChange={handleMainInputChange} 
                                        value={formInformation?.name} 
                                        required/>
                                </div>
                                <div style={{marginLeft: '24px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Page ID
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name="page_id" 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        onChange={handleMainInputChange} 
                                        value={formInformation?.page_id} 
                                        required/>
                                </div>
                            </div>
                        </>
                        }
                        <div style={{display: 'flex'}}>
                            <div>
                                <InputLabel className={classes.formInputLabel}>
                                    Meta Title
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    name="meta_title" 
                                    variant="outlined" 
                                    sx={{
                                        width: '250px',
                                        height: '62px'
                                    }}
                                    onChange={handleMainInputChange}
                                    value={formInformation?.meta_title}
                                    required/>
                            </div>
                            <div style={{marginLeft: '36px'}}>
                                <InputLabel className={classes.formInputLabel}>
                                    Meta Description
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    name="meta_description" 
                                    variant="outlined" 
                                    sx={{
                                        width: '524px',
                                        height: '62px'
                                    }} 
                                    onChange={handleMainInputChange} 
                                    value={formInformation?.meta_description} 
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {formInformation?.sections?.map((item, index) => (
                        <Accordion 
                            key={index}
                            expanded={expanded === `panel${index+1}`}
                            className={`panel_${item.section_type.split(" ")[0]}`}
                            onChange={handleChange(`panel${index+1}`)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <Typography sx={{ 
                                    flexShrink: 0,
                                    color: expanded === 'panel1' ? '#000' : 'rgb(0, 0, 0, 0.4)',
                                    textDecoration: expanded === 'panel1' ? 'underline' : 'none'
                                }} className={classes.panelHeading}>
                                    {item?.section_type}
                                </Typography>
                                <Button 
                                    className={classes.clearButton}
                                    onClick={() => clearItem(index)}>
                                    <ClearIcon sx={{
                                        fontSize: '12px',
                                        color: 'white',
                                    }} />
                                </Button>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item?.section_type === "Banner image" &&
                                    <CustomPageForm 
                                        from="banner_image"
                                        bannerDetail={item}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleImageUploads={(name, value) => handleImageUploads(name, value)}
                                        />
                                }
                                {item?.section_type === "About course" &&
                                    <CustomPageForm 
                                        from="about_course"
                                        aboutDetail={item}
                                        textEditor={(value, fromWhere) => textEditor(value, fromWhere)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleImageUploads={(name, value) => handleImageUploads(name, value)}
                                        />
                                }
                                {item?.section_type === "Info of country" &&
                                    <CustomPageForm 
                                        from="info_of_country"
                                        aboutDetail={item}
                                        list={formInformation?.sections}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        textEditor={(value, fromWhere) => textEditor(value, fromWhere)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Admission requirement" &&
                                    <CustomPageForm 
                                        from="admission_requirement"
                                        aboutDetail={item}
                                        list={formInformation?.sections}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        textEditor={(value, fromWhere) => textEditor(value, fromWhere)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Top courses" &&
                                    <CustomPageForm 
                                        from="top_courses"
                                        aboutDetail={item}
                                        list={formInformation?.sections}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        textEditor={(value, fromWhere) => textEditor(value, fromWhere)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Why study in" &&
                                    <CustomPageForm 
                                        from="study_in"
                                        aboutDetail={item}
                                        textEditor={(value, fromWhere) => textEditor(value, fromWhere)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleImageUploads={(name, value) => handleImageUploads(name, value)}
                                        />
                                }
                                {item?.section_type === "Features" &&
                                    <CustomPageForm 
                                        from="features"
                                        featureDetail={item}
                                        list={formInformation?.sections}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        />
                                }
                                {item?.section_type === "Career & placements" &&
                                    <CustomPageForm 
                                        from="career_&_placements"
                                        placementDetail={item}
                                        placementList={formInformation?.sections}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        textEditor={(value, fromWhere) => textEditor(value, fromWhere)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Top colleges" &&
                                    <CustomPageForm 
                                        from="top_colleges"
                                        aboutDetail={item}
                                        list={formInformation?.sections}
                                        addMoreCollege={(collegeInfo) => addMoreCollege(collegeInfo)}
                                        handleSearchText={(e) => handleSearchText(e)}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        removeItemCollege={(index, type, localI) => removeItemCollege(index, type, localI)}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Top study places" &&
                                    <CustomPageForm 
                                        from="top_study_places"
                                        aboutDetail={item}
                                        list={formInformation?.sections}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Colleges" &&
                                    <CustomPageForm 
                                        from="colleges"
                                        abroadCollege={item}
                                        list={formInformation?.sections}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Coaching" &&
                                    <CustomPageForm 
                                        from="coaching"
                                        abroadCollege={item}
                                        list={formInformation?.sections}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Testimonials" &&
                                    <CustomPageForm 
                                        from="testimonials"
                                        testimonialDetail={item}
                                        list={formInformation?.sections}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Benefits" &&
                                    <CustomPageForm 
                                        from="benefits"
                                        testimonialDetail={item}
                                        list={formInformation?.sections}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "FAQs" &&
                                    <CustomPageForm 
                                        from="faqs"
                                        faqsDetail={item}
                                        list={formInformation?.sections}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "Latest blogs" &&
                                    <CustomPageForm 
                                        from="blogs"
                                        blogDetail={item}
                                        list={formInformation?.sections}
                                        addMoreBlog={(blogInfo) => addMoreBlog(blogInfo)}
                                        removeItemBlogs={(index, type, localI) => removeItemBlogs(index, type, localI)}
                                        textArrayEditor={(value, name, i, index) => textArrayEditor(value, name, i, index)}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArrayImage={(index, i, name, value) => handleArrayImage(index, i, name, value)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                                {item?.section_type === "News & updates" &&
                                    <CustomPageForm 
                                        from="news_&_updates"
                                        newsDetail={item}
                                        list={formInformation?.sections}
                                        textArrayEditor={(value, name, i, index) => textArrayEditor(value, name, i, index)}
                                        removeItem={(index, type) => removeItem(index, type)}
                                        addMore={(index, type) => addMoreFeature(index, type)}
                                        handleBChnage={(e) => handleSectionInput(e, item?.section_type)}
                                        handleArray={(e, ind, from, mainIndex) => handleArray(e, ind, from, mainIndex)}
                                        />
                                }
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </div>
    )
}



export default NewCustom;