import React from "react";
import { Link, useNavigate } from "react-router-dom";
import TotalStudentIcon from "../../assest/svgs/total_student.svg";
import TotalTestimonialsIcon from "../../assest/svgs/total_testimonials.svg";
import TotalUniversityIcon from "../../assest/svgs/total_university.svg";
import TotalCoachingIcon from "../../assest/svgs/total_coaching.svg";
import ApplicationTable from "../../components/BasicTable/BasicTable";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Calendar from 'react-calendar';
import { makeStyles } from "@mui/styles";
import 'react-calendar/dist/Calendar.css';
import './dashboard.css';
import { 
  Typography,
  IconButton,
  Button } from "@mui/material";

const useStyle = makeStyles({
  topCard: {
    width: '174px',
    height: '216px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: '0px 2px 10px 1px #E8E6FB',
    borderRadius: '5px'
  },
  heading:{
    fontSize: '20px !important',
    fontWeight: 'bold !important',
  },
  notificationListBox:{
    width: '772px',
    height: '264px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 70px 2px rgba(103, 47, 143, 0.2)',
    borderRadius: '5px',
    padding: '0 16px'
  },
  notificationList:{
    color: '#000',
    fontSize: '14px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '8px 0'
  },
  calenderBox:{
    boxShadow: '0px 4px 70px 2px rgba(103, 47, 143, 0.2)',
    borderRadius: '5px',
    width: '320px',
    height: '498px'
  },
  lowerBox:{
    boxShadow: '0px 4px 70px 2px rgba(103, 47, 143, 0.2)',
    borderRadius: '5px',
    marginTop: '8px',
    height: '235px'
  }
})
const totalData = [
  {
    icon: TotalStudentIcon,
    name: 'Students',
    count: 20,
    bgColor: '#E8E6FB'
  },
  {
    icon: TotalUniversityIcon,
    name: 'Total University',
    count: 30,
    bgColor: '#FAE5EA'
  },
  {
    icon: TotalCoachingIcon,
    name: 'Total Coaching',
    count: 40,
    bgColor: '#FCEDDA'
  },
  {
    icon: TotalTestimonialsIcon,
    name: 'Total Testimonials',
    count: 50,
    bgColor: '#C1FFD2'
  }
]
const demoNewApp = [
  {
    university_name: 'unive namesd'
  },
  {
    coaching_name: 'coaching name'
  }
]
const colR = ["SNo", "University / Coaching center", "actions"];
const Dashboard = () => {
  const classes = useStyle();
  const [value, onChange] = React.useState(new Date());
  return <div style={{
    marginTop: '3%'
  }}>
    <div style={{
      display: 'flex',
    }}>
      <div>
        <div>
          {totalData.map((item, index) => (
            <div style={{
              display: 'inline-block',
              marginRight: '28px'
            }} key={index}>
              <div style={{
                backgroundColor: item?.bgColor
              }} className={classes.topCard}>
                  <img src={item?.icon} alt={`${item?.name}Icon`} style={{ 
                    width: '40px',
                    height: '45px'
                  }} />
                <p style={{
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}>{item?.name}</p>
                <p style={{
                  fontSize: '22px',
                  fontWeight: 'bold',
                  marginTop: '0'
                }}>{item?.count}</p>
              </div>
            </div>
          ))}
        </div>
        <div>
          <Typography variant="h2" component="h2" className={classes.heading} sx={{
            marginTop: '20px'
          }}>
            Notifications
          </Typography>
          <div className={classes.notificationListBox}>
              <ul style={{
                listStyleType: 'none',
                padding: '0'
              }}>
                {[1,2,3].map((item, index) => (
                  <li className={classes.notificationList} style={{
                    fontWeight: index === 2 ? 400 : 800
                  }}>
                    {index+1}. Review submitted by Nikhil Aggarwal. <Button sx={{
                      color: '#07AB35',
                      fontWeight: index === 2 ? '400 !important' : '800 !important'
                    }}>CHECK IT</Button>
                  </li>
                ))}
              </ul>
          </div>
        </div>
      </div>
      <div>
        <Typography variant="h2" component="h2" className={classes.heading} sx={{
          marginTop: '20px'
        }}>
          Calender
        </Typography>
        <div className={`${classes.calenderBox} calender-box`}>
          <Calendar 
            onChange={onChange} 
            value={value} 
            selectRange={true}
            />
        </div>
      </div>
    </div>
    <div style={{
      display: 'flex',
    }}>
      <div>
        <Typography variant="h2" component="h2" className={classes.heading} sx={{
          marginTop: '20px'
        }}>
          New Application Process
        </Typography>
        <div className={`${classes.lowerBox} new-aaplication-table`} style={{
          width: '578px'
        }}>
          <ApplicationTable
            columns={colR}
            rows={demoNewApp.map((item) => {
              const res = {
                ...item,
                actions: (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => {}}>
                      <Link to={''}>
                        <VisibilityIcon sx={{color: '#BA68C8'}} fontSize="small" />
                        <Typography component="p" variant="p" 
                          sx={{
                            color: '#BA68C8',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginTop: '-6px'
                            }}>
                          View
                        </Typography>
                      </Link>
                    </IconButton>
                  </div>
                )
              };
              return res;
            })}
          />
        </div>
      </div>
      <div style={{
        marginLeft: '20px'
      }}>
        <Typography variant="h2" component="h2" className={classes.heading} sx={{
          marginTop: '20px'
        }}>
          Notice Board
        </Typography>
        <div className={classes.lowerBox} style={{
          width: '528px'
        }}></div>
      </div>
    </div>
  </div>;
};

export default Dashboard;
