import React from "react";
import TablePagination from "@mui/material/TablePagination";

const Pagination = ({
  totalCount,
  changeLimit,
  changeOffset,
  limit,
  changePage,
  page,
}) => {
  //offset : number of enteries skip === rowsPerPage*page === limit*page
  const [rowsPerPage, setRowsPerPage] = React.useState(limit); //limit

  const handleChangePage = (event, newPage) => {
    changePage(newPage);
    changeOffset(newPage * rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    changePage(0);
    changeOffset(0);
    changeLimit(parseInt(event.target.value, 10));
  };

  return (
    <TablePagination
      rowsPerPageOptions={[10, 20, 50, 100]}
      component="div"
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      // onChange={(e) => console.log(e)}
    />
  );
};

export default Pagination;
