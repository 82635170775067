import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  IconButton, 
  Typography } from "@mui/material";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import PartnerTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner/Spinner";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getLeads, getLeadById } from "../../redux/lgs/lgsSlice";

const LeadApproved = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const colR = ["SNo", "Name", "Phone Number", "Email ID", "Status", "actions"];

  const { 
    isLoading,
    isError, 
    message,
    totalCount,
    searchText,
    leadData } = useSelector(
    (state) => state.lead
  );

  useEffect(() => {
    const res = dispatch(getLeads({ limit, offset, searchText, status: "accepted" }));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getLeads({ limit, offset, searchText, status: "accepted" }));
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  const viewDetail = (id) => {
    const res = dispatch(getLeadById({id}));
    res?.then((r) => {
        if(r?.payload?.statusCode === 200){
          navigate(routes.LEAD_DETAIL)
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
    })
  }
 
  if (isLoading ) return <Spinner />;
  if (isError ) return <h1>{message}</h1>;
  
  return (
    <>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="lead"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <PartnerTable
          columns={colR}
          where="recipes"
          rows={leadData && leadData?.map((item) => {
            const res = {
              ...item,
              Status: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 8px'
                    }}
                  >
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#07AB35',
                        fontSize: '18px',
                        fontWeight: 'bold'
                        }}>
                      Approved
                    </Typography>
                  </IconButton>
                </div>
              ),
              actions: (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={() => viewDetail(item?._id)}
                  >
                    <VisibilityIcon sx={{color: '#3C1CDE'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#3C1CDE',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      View
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          })}
        />
      </div>
    </>
  );
};

export default LeadApproved;
