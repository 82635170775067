//Auth Services is strictly for http requests or sending the data back or setting local storage

import axios from "axios";
import endpoint from "../../index.routes";

//Login User
const login = async (userData) => {
  const response = await axios.post(endpoint.LOGIN, userData);
  if (response.status === 200) {
    localStorage.setItem("hp_user", JSON.stringify(response.data.data));
    localStorage.setItem("hp_token", response.data.data.token);
  }
  return response.data;
};

const authServices = { login: login };

export default authServices;
