import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import Table from '../../components/OtherTable/OtherTable';
import Select from '../../components/Select/Select';
import UploadBox from '../../components/UploadBox/UploadBox';
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import {
    Button,
    Typography,
    InputLabel,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio
  } from "@mui/material";
import { addProduct } from '../../redux/instituteProd/instituteProdSlice';
import "./product.css";
import { toast } from 'react-toastify';
import SubProduct from "../../components/SubProduct/SubProduct";
import AddOns from "../../components/AddOns/AddOns"
import LoaderIcon from "../../components/loader/Loader";
import routes from "../../react_routes_enum";
import { 
    getProductByInstitute,
    productUpdate
  } from "../../redux/instituteProd/instituteProdSlice";

const useStyle = makeStyles({
    marginBot24: {
        marginBottom: '24px',
    },
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    }
})
const placementRecordsTitle =  ["No. Of Students", "Batch", "Company Logo"]
const topFacultyTitle =  ["Name", "Rank", "Year of experience", "Upload profile picture"]
const ourTopperTitle =  ["Name", "Rank", "Batch", "Company Logo"]

var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

const universityData = {
    university_id: "",
    name: "",
    category: "",
    product_type: "",
    branches: [],
    course_type: "",
    description: "",
    syllabus_url: "",
    payment_mode: "direct",
    images_url: [],
    placement_records: [
        {
            number_of_students: "",
            batch: "",
            logo: ""
        },
        {
            number_of_students: "",
            batch: "",
            logo: ""
        },
        {
            number_of_students: "",
            batch: "",
            logo: ""
        },
        {
            number_of_students: "",
            batch: "",
            logo: ""
        },
        {
            number_of_students: "",
            batch: "",
            logo: ""
        }
    ],
    sub_products: [],
    add_ons: [],
}

let coachingData = {
    coaching_id: "",
    name: "",
    category: "",
    product_type: "",
    payment_mode: "direct",
    plan: "",
    description: "",
    syllabus_url: "",
    images_url: [],
    top_faculties: [
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        }
    ],
    toppers: [
        {
            name: "",
            rank: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            rank: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            rank: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            rank: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            rank: "",
            experience_years: "",
            profile_picture: ""
        }
    ],
    sub_products: [],
    add_ons: []
}

const ProductForm = () => {
    const { 
        productById,
        instituteIdForProduct
    } = useSelector((state) => state.institute_prod);

    const { type } = useSelector(
        (state) => state.university
    );
    const navigate = useNavigate();
    const [universityProduct, setUniversityProduct] = React.useState(((type === "University") && productById) ? productById : 
        universityData);
    const [coachingProduct, setCoachingProduct] = React.useState(((type === "Coaching") && productById) ? productById : 
        coachingData);
    const [names, setNames] = React.useState(["", "", "", "", ""]);
    const [rank, setRank] = React.useState(["", "", "", "", ""]);
    const [epxerience, setExperience] = React.useState(["", "", "", "", ""]);
    const [profilePic, setProfilePic] = React.useState(["", "", "", "", ""]);
    const [names1, setNames1] = React.useState(["", "", "", "", ""]);
    const [rank1, setRank1] = React.useState(["", "", "", "", ""]);
    const [epxerience1, setExperience1] = React.useState(["", "", "", "", ""]);
    const [profilePic1, setProfilePic1] = React.useState(["", "", "", "", ""]);
    const [coursesList, setCoursesList] = React.useState([]);
    const [univProType, setUnivProType] = React.useState([]);
    const [universityCat, setUniversityCat] = React.useState([]);
    const [univCourseType, setUnivCourseType] = React.useState([]);
    const [coachingCat, setCoachingCat] = React.useState([]);
    const [coachingProType, setCoachingProType] = React.useState([]);
    const [coachingProPlan, setCoachingProPlan] = React.useState([]);
    const [coachAddonType, setCoachAddonType] = React.useState([]);
    const [semester, setSemester] = React.useState([]);
    const [UnivAddonType, setUnivAddonType] = React.useState([]);
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [loader, setLoader] = React.useState(false);
    const classes = useStyle();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const submitClicked = (e) => {   
        e.preventDefault();
        if(type === "Coaching"){
            wrapperData()
            if(validate(coachingProduct).status){
                
                let facultyTableError = false;
                let toppersTableError = false;
                let newCoachData = {};
                let topFaculty = wrapperData().faculty.filter((item, index) => {
                    if(item.name !== "" && item.experience_years !== "" && item.profile_picture !== "") {
                        return item
                    }else{
                        if(item.name === "" && item.experience_years === "" && item.profile_picture === "") {
                            
                        }else{
                            facultyTableError = true;
                        }
                    }
                });
                let toppers = wrapperData()?.toppers.filter((item, index) => {
                    if(item.name !== "" && item.rank !== "" && item.experience_years !== "" && item.profile_picture !== "") {
                        return item
                    }else{
                        if(item.name === "" && item.rank === "" && item.experience_years === "" && item.profile_picture === "") {
                            
                        }else{
                            toppersTableError = true;
                        }
                    }
                })
                if(facultyTableError){
                    toast.error("Missing Relevant Data In Top Faculty")
                    return
                }
                if(toppersTableError){
                    toast.error("Missing Relevant Data In Toppers")
                    return
                }
                newCoachData = {
                    coaching_id: coachingProduct?.coaching_id,
                    name: coachingProduct?.name,
                    category: coachingProduct?.category,
                    product_type: coachingProduct?.product_type,
                    payment_mode: coachingProduct?.payment_mode,
                    plan: coachingProduct?.plan,
                    description: coachingProduct?.description,
                    syllabus_url: coachingProduct?.syllabus_url,
                    images_url: coachingProduct?.images_url,
                    top_faculties: topFaculty,
                    toppers: toppers,
                    sub_products: coachingProduct?.sub_products,
                    add_ons: coachingProduct?.add_ons
                }
                setLoader(true);
                const res = (location.pathname.split("/")[2] === "edit") ? dispatch(productUpdate(coachingProduct)) : 
                    dispatch(addProduct(newCoachData));
                res.then((r) => {
                    setLoader(false);
                    if(r.payload.statusCode === 200){
                        toast.success(r.payload.message);
                        let params= {limit, offset, coaching_id: instituteIdForProduct};
                        dispatch(getProductByInstitute(params));
                        navigate(routes.PRODUCT_LIST);
                    }else{
                        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                            r?.payload?.message === "Session Expired"){
                            toast.error("Session expired, please login again");
                            localStorage.removeItem("hp_token");
                            localStorage.removeItem("hp_user");
                            navigate(routes.LOGIN);
                            return;
                        }
                        toast.error(r?.payload?.message);
                    }
                })
            }else{
                toast.error(validate(coachingProduct).message);
            }
        }else{
            wrapperData()
            if(validate(universityProduct).status){
                let placementTableError = false;
                let placemmentData = wrapperData().placement_records.filter((item, index) => {
                    if(item.number_of_students !== "" && item.batch !== "" && 
                        item.logo !== "") {
                        return item
                    }else{
                        if(item.number_of_students === "" && item.batch === "" && 
                        item.logo === ""){
                            
                        }else{
                            placementTableError = true;
                        }
                    }
                });
                if(placementTableError){
                    toast.error("Missing Relevant Data In Placement Record");
                    return;
                }

                let newUnivPro = {
                    university_id: universityProduct?.university_id,
                    name: universityProduct?.name,
                    category: universityProduct?.category,
                    product_type: universityProduct?.product_type,
                    branches: universityProduct?.branches,
                    course_type: universityProduct?.course_type,
                    description: universityProduct?.description,
                    syllabus_url: universityProduct?.syllabus_url,
                    payment_mode: universityProduct?.payment_mode,
                    images_url: universityProduct?.images_url,
                    placement_records: placemmentData,
                    sub_products: universityProduct?.sub_products,
                    add_ons: universityProduct?.add_ons,
                }
                setLoader(true);
                const res = (location.pathname.split("/")[2] === "edit") ? dispatch(productUpdate(universityProduct)) : 
                    dispatch(addProduct(newUnivPro));
                res.then((r) => {
                    setLoader(false)
                    if(r.payload.statusCode === 200){
                        toast.success(r.payload.message);
                        let params= {limit, offset, university_id: instituteIdForProduct};
                        dispatch(getProductByInstitute(params));
                        navigate(routes.PRODUCT_LIST);
                    }else{
                        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                            r?.payload?.message === "Session Expired"){
                            toast.error("Session expired, please login again");
                            localStorage.removeItem("hp_token");
                            localStorage.removeItem("hp_user");
                            navigate(routes.LOGIN);
                            return;
                        }
                        toast.error(r?.payload?.message);
                    }
                })
            }else{
                toast.error(validate(universityProduct).message);
            }
        }
    }
    const handleChange = (e) => {
        if(e.target.name === "name"){
            type === "Coaching" ? setCoachingProduct({...coachingProduct, name: e.target.value}) : 
                setUniversityProduct({...universityProduct, name: e.target.value});
        }
        if(e.target.name === "category"){
            const res = dispatch(getConfigDetails());
            res?.then((r) => {
                r?.payload?.data.map((item) => {
                    if(item.configuration_type === "university_category"){
                        item?.data?.map((ite) => {
                            ite.cat === e.target.value && setCoursesList(ite?.subCat);
                        })
                    }
                })
            })
            type === "Coaching" ? setCoachingProduct({...coachingProduct, category: e.target.value}) : 
                setUniversityProduct({...universityProduct, category: e.target.value});
        }
        if(e.target.name === "type"){
            type === "Coaching" ? setCoachingProduct({...coachingProduct, product_type: e.target.value}) : 
                setUniversityProduct({...universityProduct, product_type: e.target.value});
        }
        if(e.target.name === "desctiption"){
            type === "Coaching" ? setCoachingProduct({...coachingProduct, description: e.target.value}) : 
                setUniversityProduct({...universityProduct, description: e.target.value});
        }
        if(e.target.name === "branch"){
            type === "Coaching" ? setCoachingProduct({...coachingProduct, plan: e.target.value}) : 
                setUniversityProduct({...universityProduct, branches: [e.target.value]});
        }
        if(e.target.name === "course_type"){
            setUniversityProduct({...universityProduct, course_type: e.target.value});
        }
        if(e.target.name === "payment_process"){
            setCoachingProduct({...coachingProduct, payment_mode: e.target.value});
        }
        if(e.target.name === "apply_for_college"){
            setUniversityProduct({...universityProduct, payment_mode: e.target.value});
        }
    }
    const handleImageUploads = (name, value) => {
        name === "syllabus" && (type === "Coaching" ? setCoachingProduct({...coachingProduct, syllabus_url: value}) : 
            setUniversityProduct({...universityProduct, syllabus_url: value}));
        name === "image" && (type === "Coaching" ? setCoachingProduct({...coachingProduct, images_url: [value]}) : 
            setUniversityProduct({...universityProduct, images_url: [value]}));
    }
    const addAddons = () => {
        if(type === "Coaching"){
            let array = coachingProduct.add_ons;
            array.push({
                name: "",
                product_type: "",
                author_name: "N/A",
                description: "",
                price: "",
                images_url: [],
                is_percentage_discount: 1,
                discount: 0,
                payment_procedure: 'direct_pay'
            });
            setCoachingProduct({...coachingProduct, add_ons: [...array]});
        }else{
            let array = universityProduct.add_ons;
            array.push({
                name: "",
                product_type: "",
                author_name: "N/A",
                description: "",
                price: "",
                images_url: [],
                is_percentage_discount: 1,
                discount: 0,
                payment_procedure: 'direct_pay',
                course_name: ""
            });
            setUniversityProduct({...universityProduct, add_ons: [...array]});
        }
    }
    const addSubProduct = () => {
        if(type === "Coaching"){
            let array = coachingProduct.sub_products;
            array.push({
                title: "",
                description: "",
                duration: "",
                fees: "",
                gst: "",
                is_percentage_discount: 1,
                subjects: [],
                discount: 0,
                payment_procedure: 'direct_pay'
            });
            setCoachingProduct({...coachingProduct, sub_products: [...array]});
        }else{
            let array = universityProduct.sub_products;
            array.push({
                title: "",
                description: "",
                duration: "",
                fees: "",
                is_percentage_discount: 1,
                subjects: [],
                semester: "",
                discount: 0,
                gst: "",
                eligibility_criteria: "",
                payment_procedure: 'direct_pay'
            });
            setUniversityProduct({...universityProduct, sub_products: [...array]});
        }
    }
    
    const wrapperData = () => {
        if(type === "Coaching"){
            let data = {status: false, message: "", faculty: coachingProduct.top_faculties, toppers: coachingProduct.toppers}
            coachingProduct.top_faculties.map((item, index) => {
                if(location.pathname.split("/")[2] === "edit"){
                    return {...item, name: names[index], experience_years: epxerience[index], 
                        profile_picture: profilePic[index]};
                }else{
                    item.name = names[index];
                    item.experience_years = epxerience[index];
                    item.profile_picture = profilePic[index];
                }
            })
            coachingProduct.toppers.map((item, index) => {
                if(location.pathname.split("/")[2] === "edit"){
                    return {...item, name: names1[index], rank: rank1[index], experience_years: epxerience1[index], 
                        profile_picture: profilePic1[index]};
                }else{
                    item.name = names1[index];
                    item.rank = rank1[index];
                    item.experience_years = epxerience1[index];
                    item.profile_picture = profilePic1[index];
                }
            })
            return data;
        }else{
            let data = {status: false, message: "", placement_records: universityProduct.placement_records}
            universityProduct.placement_records.map((item, index) => {
                if(location.pathname.split("/")[2] === "edit"){
                    return {...item, 
                        student_name: names1[index], 
                        company_name: rank1[index], 
                        experience_years: epxerience1[index], 
                        profile_picture: profilePic1[index]};
                }else{
                    item.number_of_students = names1[index];
                    item.batch = epxerience1[index];
                    item.logo = profilePic1[index];
                }
            })
            return data;
        }
    }
    const subProductChange = (e, index) => {
        let localSubProduct = []
        let array = type === "Coaching" ? coachingProduct.sub_products : universityProduct.sub_products;
        localSubProduct = array.map((element, i) => {
            return i === index ? {...element, 
                ...(e.target.name === `course_duration_${index}` && {duration: e.target.value}),
                ...(e.target.name === `sub_product_${index}` && {title: e.target.value}),
                ...(e.target.name === `timing_${index}` && {timings: e.target.value}),
                // ...(e.target.name === `description_${index}` && {description: e.target.value}),
                ...(e.target.name === `fees_${index}` && {fees: e.target.value}),
                ...(e.target.name === `gst_${index}` && {gst: e.target.value}),
                ...(e.target.name === `sub_product_payment_process_${index}` && {payment_procedure: e.target.value}),
                ...(e.target.name === `s_${index}` && (type === "Coaching" ? {subjects: [e.target.value]} :
                    {semester: `${e.target.value}`})),
                ...(e.target.name === `discount_${index}` && {discount: e.target.value}),
                ...(e.target.name === `eligibility_${index}` && {eligibility_criteria: e.target.value}),
                ...(e.target.name === `subj_${index}` && {subjects: [e.target.value]}),
                ...(e.target.name === `per_${index}` && {is_percentage_discount: (e.target.value === "percentage" ? 1 : 0)}),

            } : element
        })
        type === "Coaching" ? setCoachingProduct({...coachingProduct, sub_products: localSubProduct}) :
        setUniversityProduct({...universityProduct, sub_products: localSubProduct})
    }
    const subAddOnsChange = (e, index) => {
        let array = [];
        type === "Coaching" ? (array = coachingProduct.add_ons) : (array = universityProduct.add_ons);
        
        if(e.target){

            array = array.map((element, i) => {
                return i === index ? {...element, 
                    ...(e.target.name === `add_on_p_type_${index}` && {product_type: e.target.value}),
                    ...(e.target.name === `add_on_product_name_${index}` && {name: e.target.value}),
                    ...(e.target.name === `add_on_author_name_${index}` && {author_name: e.target.value}),
                    ...(e.target.name === `add_on_price_${index}` && {price: e.target.value}),
                    ...(e.target.name === `add_on_offer_${index}` && {discount: e.target.value}),
                    ...(e.target.name === `add_ons_product_payment_process_${index}` && {payment_procedure: e.target.value}),

                    ...(e.target.name === `s_${index}` && (type === "Coaching" ? {subjects: [e.target.value]} :
                        {semester: e.target.value})),

                    ...(e.target.name === `add_ons_course_${index}` && {course_name: e.target.value}),

                    ...(e.target.name === `subj_${index}` && {subjects: [e.target.value]}),
                    ...(e.target.name === `add_on_per_${index}` && {is_percentage_discount: (e.target.value === "percentage" ? 1 : 0)}),
    
                } : element
            })
            type === "Coaching" ? setCoachingProduct({...coachingProduct, add_ons: array}) :
                setUniversityProduct({...universityProduct, add_ons: array})
        }else{
            array = array.map((element, i) => {
                return i === parseInt(e?.split("_")[3]) ? {...element, 
                    ...(e === `add_on_img_${i}` && {images_url: [index]}),
                } : element
            })
            type === "Coaching" ? setCoachingProduct({...coachingProduct, add_ons: [...array]}) :
                setUniversityProduct({...universityProduct, add_ons: array})
        }
        
    }
    React.useEffect(() => {
        if(instituteIdForProduct){
            type === "Coaching" ? setCoachingProduct({...coachingProduct, coaching_id: instituteIdForProduct}) : 
                setUniversityProduct({...universityProduct, university_id: instituteIdForProduct})
        }
        if(productById){
            let tempName = [];
            let tempRank = [];
            let tempExperience = [];
            let tempProPic = [];
            let topperData = [];
            let placementData = [];
            if(type === "University"){
                for(let i = 0; i < 5; i++){
                    placementData[i] = {
                        number_of_students: productById?.placement_records[i]?.number_of_students ? productById?.placement_records[i]?.number_of_students : '',
                        batch: productById?.placement_records[i]?.batch ? productById?.placement_records[i]?.batch : '',
                        logo: productById?.placement_records[i]?.logo ? productById?.placement_records[i]?.logo : ''
                    }
                    tempName[i] = productById?.placement_records[i]?.number_of_students ? productById?.placement_records[i]?.number_of_students : ''
                    tempExperience[i] = productById?.placement_records[i]?.batch ? productById?.placement_records[i]?.batch : ''
                    tempProPic[i] = productById?.placement_records[i]?.logo ? productById?.placement_records[i]?.logo : ''
                }
                setUniversityProduct({
                    ...universityProduct, 
                    placement_records: placementData
                })

                setNames1([...tempName]);
                setExperience1([...tempExperience]);
                setProfilePic1([...tempProPic]);
                
            }

            if(type === "Coaching"){
                let tempName1 = [];
                let tempExperience1 = [];
                let tempProPic1 = [];
                let topFac = [];
                for(let i = 0; i < 5; i++){
                    topFac[i] = {
                        name: productById?.top_faculties[i]?.name ? productById?.top_faculties[i]?.name : '',
                        experience_years: productById?.top_faculties[i]?.experience_years ? productById?.top_faculties[i]?.experience_years : '',
                        profile_picture: productById?.top_faculties[i]?.profile_picture ? productById?.top_faculties[i]?.profile_picture : ''
                    }
                    tempName1[i] = productById?.top_faculties[i]?.name ? productById?.top_faculties[i]?.name : ''
                    tempExperience1[i] = productById?.top_faculties[i]?.experience_years ? productById?.top_faculties[i]?.experience_years : ''
                    tempProPic1[i] = productById?.top_faculties[i]?.profile_picture ? productById?.top_faculties[i]?.profile_picture : ''
                }
                for(let i = 0; i < 5; i++){
                    topperData[i] = {
                        name: productById?.toppers[i]?.name ? productById?.toppers[i]?.name : '',
                        rank: productById?.toppers[i]?.rank ? productById?.toppers[i]?.rank : '',
                        experience_years: productById?.toppers[i]?.experience_years ? productById?.toppers[i]?.experience_years : '',
                        profile_picture: productById?.toppers[i]?.profile_picture ? productById?.toppers[i]?.profile_picture : ''
                    }
                    tempName[i] = productById?.toppers[i]?.name ? productById?.toppers[i]?.name : ''
                    tempRank[i] = productById?.toppers[i]?.rank ? productById?.toppers[i]?.rank : ''
                    tempExperience[i] = productById?.toppers[i]?.experience_years ? productById?.toppers[i]?.experience_years : ''
                    tempProPic[i] = productById?.toppers[i]?.profile_picture ? productById?.toppers[i]?.profile_picture : ''
                }
                setCoachingProduct({...coachingProduct, top_faculties: topFac, toppers: topperData})
                
                setNames([...tempName1]);
                setExperience([...tempExperience1]);
                setProfilePic([...tempProPic1]);

                setNames1([...tempName]);
                setRank1([...tempRank]);
                setExperience1([...tempExperience]);
                setProfilePic1([...tempProPic]);
            }
        }
    },[])

    React.useEffect(() => {
        const res = dispatch(getConfigDetails());
        res?.then((r) => {
            r?.payload?.data.map((item) => {
                if(item.configuration_type === "university_category"){
                    let array = [];
                    item?.data?.map((ite) => {
                        ite.cat === "Engineering" && setCoursesList(ite?.subCat);
                        array.push(ite.cat)
                    })
                    setUniversityCat(array)
                }
                if(item.configuration_type === "univ_pro_type"){
                    setUnivProType(item?.data)
                }
                if(item.configuration_type === "univ_pro_course_type"){
                    setUnivCourseType(item?.data)
                }
                if(item.configuration_type === "univ_sub_pro_semester"){
                    setSemester(item?.data)
                }
                if(item.configuration_type === "coaching_category"){
                    setCoachingCat(item?.data)
                }
                if(item.configuration_type === "univ_sub_pro_adontype"){
                    setUnivAddonType(item?.data)
                }
                if(item.configuration_type === "coach_pro_type"){
                    setCoachingProType(item?.data)
                }
                if(item.configuration_type === "coach_pro_plan"){
                    setCoachingProPlan(item?.data)
                }
                if(item.configuration_type === "coach_sub_pro_adontype"){
                    setCoachAddonType(item?.data)
                }
            })
        })
    },[])
    const validate = (data) => {
        let error = {status: true, message: ''}
        if(data.name === ""){
            error.status = false;
            error.message = "Product Name Required";
            return error;
        }
        if(data.name.length < 4 || !nameValidator.test(data.name)){
            data.name.length < 4 ? (error = {status: false, message: "Product name must be at least 4 characters"}) :
              (error = {status: false, message: "Invalid Product Name"})
            return error;
        }
        if(data.category === ""){
            error.status = false;
            error.message = "Product Category Required";
            return error;
        }
        if(data.product_type === ""){
            error.status = false;
            error.message = "Product Type Required";
            return error;
        }
        if(data.description === "" || data.description.length < 6){
            error.status = false;
            error.message = data.description === "" ? "Product Description Required" : "Description must be at least 6 characters";
            return error;
        }
        if(data.syllabus_url === ""){
            error.status = false;
            error.message = "Syllabus Required";
            return error;
        }
        if(data.sub_products.length > 0){
            data.sub_products.map((item, index) => {
                if(item.title === "" || item.title.length < 4){
                    error.status = false;
                    error.message = item.title === "" ? "Sub product title required" : "Sub product title must be at least 4 characters";
                    return error;
                }
                if(item.duration === ""){
                    error.status = false;
                    error.message = "Sub product duration required";
                    return error;
                }
                if(item.timings === ""){
                    error.status = false;
                    error.message = "Sub product timings required";
                    return error;
                }
                if(item.description === "" || item.description.length < 6){
                    error.status = false;
                    error.message = item.description === "" ? "Sub product description required" : "Sub product description must be at least 6 characters";
                    return error;
                }
                if(item.fees === ""){
                    error.status = false;
                    error.message = "Fees required";
                    return error;
                }
                if(item.gst === ""){
                    error.status = false;
                    error.message = "GST required";
                    return error;
                }
                if(type === "University"){
                    if(item.semester === ""){
                        error.status = false;
                        error.message = "Semester required";
                        return error;
                    }
                }
                if(item.subjects.length === 0){
                    error.status = false;
                    error.message = "Subject required";
                    return error;
                }
                if(item.discount === ""){
                    error.status = false;
                    error.message = "Discount or Offer required";
                    return error;
                }
                if(item.eligibility_criteria === ""){
                    error.status = false;
                    error.message = "Eligibility criteria required";
                    return error;
                }
            })
            
        }
        if(data.add_ons.length > 0){
            data.add_ons.map((item, index) => {
                if(item.product_type === ""){
                    error.status = false;
                    error.message = "Addons product type required";
                    return error;
                }
                if(item.name === "" || item.name.length < 4){
                    error.status = false;
                    error.message = item.name === "" ? "Addons product name required" : "Addons product name must be at least 4 characters";
                    return error;
                }
                // if(item.author_name === "" || item.author_name.length < 4){
                //     error.status = false;
                //     error.message = item.author_name === "" ? "Addons author name required" : "Addons author name must be at least 4 characters";
                //     return error;
                // }
                if(item.price === ""){
                    error.status = false;
                    error.message = "Addons price required";
                    return error;
                }
                // if(item.images_url.length === 0){
                //     error.status = false;
                //     error.message = "Addons image required";
                //     return error;
                // }
                if(item.description === ""){
                    error.status = false;
                    error.message = "Addons description required";
                    return error;
                }
                // if(item.discount === ""){
                //     error.status = false;
                //     error.message = "Addons discount required";
                //     return error;
                // }
                if(type === "University"){
                    if(item.course_name === "" || item.course_name.length < 4){
                        error.status = false;
                        error.message = item.course_name === "" ? "Addons course name required" : "Addon course name must be at least 4 characters";
                        return error;
                    }
                }
            })
        }
        if(type === "Coaching"){
            if(data.plan === ""){
                error.status = false;
                error.message = "Plan Required";
                return error;
            }
            // data.top_faculties.map((item, index) => {
            //     if(item?.name === ""){ 
            //         error.status = false;
            //         error.message = "Top faculty name empty not allowed";
            //         return error;
            //     }
            //     if(data.name.length < 4 || !nameValidator.test(data.name)){
            //         data.name.length < 4 ? (error = {status: false, message: "Top faculty name must be at least 4 characters"}) :
            //           (error = {status: false, message: "Invalid Topper Name"})
            //         return error;
            //     }
            // })
        }if(type === "University"){
            if(data.branches.length === 0){
                error.status = false;
                error.message = "Branch Required";
                return error;
            }
            if(data.course_type === ""){
                error.status = false;
                error.message = "Course Type Required";
                return error;
            }
        }
        return error;
    }
    const richTextEditor = (text, i, richType) => {
        let localSubProduct = []
        let array = type === "Coaching" ? coachingProduct.sub_products : universityProduct.sub_products;
        localSubProduct = array.map((element, index) => {
            return i === index ? {...element, 
                ...(richType === `sub_product_${index}` && {description: text}),
            } : element
        })
        type === "Coaching" ? setCoachingProduct({...coachingProduct, sub_products: localSubProduct}) :
        setUniversityProduct({...universityProduct, sub_products: localSubProduct})
    }
    const richTextEditorAddOn = (text, i, richType) => {
        let array = [];
        type === "Coaching" ? (array = coachingProduct.add_ons) : (array = universityProduct.add_ons);
        array = array.map((element, index) => {
            return i === index ? {...element, 
                ...(richType === `sub_addon_${index}` && {description: text}),
            } : element
        })
        type === "Coaching" ? setCoachingProduct({...coachingProduct, add_ons: array}) :
                setUniversityProduct({...universityProduct, add_ons: array})
    }
    return (
        <div className='add-product-form'>
            <form onSubmit={submitClicked}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div>
                    </div>
                    {location.pathname.split("/")[2] !== "view" &&
                    <Button type="button" sx={{
                        backgroundColor: loader ? 'var(--disable-color)' : '#07AB35',
                        textTransform: 'capitalize',
                        color: 'white',
                        width: '100px',
                        "&:hover":{
                            backgroundColor: '#07AB35',
                        }
                    }} 
                    onClick={submitClicked} 
                    disabled={loader}>
                        {loader ? <LoaderIcon /> : "Submit"}
                    </Button>}
                </div>
                <div className={[`${classes.marginBot24} form-part-1`]}>
                    <div className='three-input-box margin-bot-change'>
                        <InputLabel className={classes.formInputLabel}>Name of Product</InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            value={type === "University" ? universityProduct?.name : coachingProduct?.name} 
                            variant="outlined" 
                            name="name" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                    </div>
                    <div className='three-input-box category-input margin-bot-change'>
                        <InputLabel className={classes.formInputLabel}>Category</InputLabel>
                        <Select 
                            name="category"
                            value={type === "University" ? universityProduct?.category : coachingProduct?.category}
                            option={type === "University" ? universityCat : coachingCat } 
                            handleSelect={(e) => handleChange(e)}
                            />
                    </div>
                    <div className='three-input-box'>
                        <InputLabel className={classes.formInputLabel}>Type</InputLabel>
                        <Select 
                            name="type"
                            value={type === "University" ? universityProduct?.product_type : coachingProduct?.product_type}
                            option={type === "University" ? univProType : coachingProType } 
                            handleSelect={(e) => handleChange(e)}
                            />
                    </div>
                </div>
                <div className={[`${classes.marginBot24} description-box`]}>
                    <div className="description-input">
                        <InputLabel className={classes.formInputLabel}>Description</InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            name="desctiption" 
                            onChange={handleChange} 
                            value={type === "University" ? universityProduct?.description : coachingProduct?.description} 
                            rows={6}
                            multiline
                            required/>
                    </div>
                    <div className="branch-course">
                        <div className="three-input-box margin-bot-change">
                            {type === "University" ? <InputLabel className={classes.formInputLabel}>
                                Branches <span className="optional-text-style">(Optional)</span>
                            </InputLabel>:
                            <InputLabel className={classes.formInputLabel}>
                                Plan
                            </InputLabel>}
                            <Select 
                                name="branch"
                                value={type === "University" ? universityProduct?.branches[0] : coachingProduct?.plan}
                                option={type === "University" ? coursesList : coachingProPlan } 
                                handleSelect={(e) => handleChange(e)}
                                />
                        </div>
                        {type === "Coaching" &&
                        <div className="three-input-box course-type-input margin-bot-change">
                            <InputLabel className={classes.formInputLabel}>
                                How you want to do payment ?
                            </InputLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={coachingProduct?.payment_mode}
                                name="payment_process"
                                onChange={(e) => handleChange(e)}
                            >
                                <FormControlLabel 
                                    value="direct" 
                                    control={<Radio sx={{
                                        color: 'var(--secondary-color)',
                                        '&.Mui-checked': {
                                            color: 'var(--secondary-color)',
                                            },
                                    }} />} 
                                    label="Direct Pay" />
                                <FormControlLabel 
                                    value="full_process" 
                                    control={<Radio sx={{
                                        color: 'var(--secondary-color)',
                                        '&.Mui-checked': {
                                            color: 'var(--secondary-color)',
                                            },
                                    }} />} 
                                    label="Follow Full Process" />
                            </RadioGroup>
                        </div>
                        }
                        {type === "University" &&
                        <div className="three-input-box course-type-input margin-bot-change">
                            <InputLabel className={classes.formInputLabel}>
                                Course Type
                            </InputLabel>
                            <Select 
                                name="course_type"
                                value={universityProduct.course_type}
                                option={universityCat} 
                                handleSelect={(e) => handleChange(e)}
                                />
                        </div>}
                    </div>
                </div>
                <div className={`upload-sy-pro`} style={{
                    width: type === "University" ? '954px' : '612px'
                }}>
                    <div className="margin-bot-change">
                        <Typography component="h2" variant="h2" className="input-label-text">
                            Syllabus
                        </Typography>
                        <div className="product-upload-box">
                            <UploadBox
                                name="syllabus"
                                value={type === "University" ? universityProduct?.syllabus_url : coachingProduct?.syllabus_url}
                                handleUpload={(name, value) => handleImageUploads(name, value)} />
                        </div>
                    </div>
                    <div style={{marginLeft: type === "University" ? '152px' : '0'}}>
                        <Typography component="h2" variant="h2" className="input-label-text">
                            Upload Image <span className="optional-text-style">(Optional)</span></Typography>
                        <div className="product-upload-box">
                            <UploadBox 
                                name="image"
                                value={type === "University" ? universityProduct?.images_url[0] : coachingProduct?.images_url[0]}
                                handleUpload={(name, value) => handleImageUploads(name, value)}/>
                        </div>
                    </div>
                    {type === "University" && 
                    <div className="three-input-box margin-bot-change" style={{width: '286px'}}>
                        <InputLabel className={classes.formInputLabel}>
                            How you want to apply for college?
                        </InputLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={universityProduct?.payment_mode}
                            name="apply_for_college"
                            onChange={(e) => handleChange(e)}
                        >
                            <FormControlLabel 
                                value="direct" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Direct Purchase" />
                            <FormControlLabel 
                                value="registration fee" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Apply With Registration Fee" />
                        </RadioGroup>
                    </div>}
                </div>
                {type === "Coaching" && <div style={{
                    marginTop: '24px',
                    overflowX: 'auto',
                }}>
                    <Table
                        profilePic={profilePic}
                        experience={epxerience}
                        setExperience={(exp) => setExperience([...exp])}
                        setNames={(name) => setNames([...name])}
                        rank={rank}
                        setRank={(rank) => setRank([...rank])}
                        names={names}
                        tableWidth="auto"
                        title="Top faculties"
                        uploadPicFor="faculties"
                        subTitle={topFacultyTitle}
                        isOptionalText={true}
                        isRank={false}/>
                </div>}
                <div style={{
                    marginTop: '24px',
                    overflowX: 'auto',
                }}>
                    <Table 
                        profilePic={profilePic1}
                        experience={epxerience1}
                        setExperience={(exp) => setExperience1([...exp])}
                        setNames={(name) => setNames1([...name])}
                        rank={rank1}
                        setRank={(rank) => setRank1([...rank])}
                        names={names1}
                        tableWidth="auto"
                        title={type === "Coaching" ? "Our Toppers" : "Placement Records"}
                        uploadPicFor="toppers"
                        subTitle={type === "Coaching" ? ourTopperTitle : placementRecordsTitle}
                        isRank={true}
                        isOptionalText={true}/>
                </div>
                <div style={{
                    marginTop: '24px'
                }}>
                    {type === "Coaching" ? 
                    coachingProduct.sub_products.map((item, index) => (
                        <div key={index}>
                            <SubProduct 
                                index={index}
                                item={item}
                                handleChange={(e, i) => {subProductChange(e, i)}}
                                textEditor={(newText) => {richTextEditor(newText, index, `sub_product_${index}`)}} />
                        </div>))
                        :
                    universityProduct.sub_products.map((item, index) => (
                        <div key={index}>
                            <SubProduct 
                                index={index}
                                item={item}
                                semesList={semester}
                                handleChange={(e, i) => {subProductChange(e, i)}}
                                textEditor={(newText) => {richTextEditor(newText, index, `sub_product_${index}`)}} />
                        </div>
                    ))}

                    {type === "Coaching" ? coachingProduct.add_ons.map((item, index) => (
                        <div key={index}>
                            <AddOns 
                                index={index}
                                item={item}
                                type={coachAddonType}
                                handleChange={(e, i) => {subAddOnsChange(e, i)}}
                                textEditor={(newText) => {richTextEditorAddOn(newText, index, `sub_addon_${index}`)}} />
                        </div>))
                        :
                    universityProduct.add_ons.map((item, index) => (
                        <div key={index}>
                            <AddOns 
                                index={index}
                                item={item}
                                type={UnivAddonType}
                                handleChange={(e, i) => {subAddOnsChange(e, i)}}
                                textEditor={(newText) => {richTextEditorAddOn(newText, index, `sub_addon_${index}`)}} />
                        </div>))}
                    <div>
                        <Button variant="contained" sx={{
                            backgroundColor: type === "University" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                            textTransform: 'capitalize',
                            marginRight: '8px'
                        }} onClick={addAddons}>
                            <AddIcon sx={{marginRight: '4px'}} />
                            Add Addons
                        </Button>
                        <Button variant="outlined" sx={{
                            textTransform: 'capitalize',
                            color: type === "University" ? 'var(--secondary-color)' : '#000',
                            fontWeight: 'bold',
                            borderColor: type === "University" ? 'var(--secondary-color)' : '#000'
                        }} onClick={addSubProduct}>
                            <AddIcon sx={{marginRight: '4px'}} />
                            Add Sub-Product
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default ProductForm;