import axios from "axios";
import endpoint from "../../index.routes";

const getConfigDetails = async () => {
  const response = await axios.get(endpoint.CONFIG);
  return response.data;
};

const configServices = { getConfigDetails };

export default configServices;
