import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  Typography, 
  InputLabel,
  Select,
  MenuItem } from "@mui/material";
import routes from "../../react_routes_enum";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LoaderIcon from "../../components/loader/Loader";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import Checkbox from '@mui/material/Checkbox';
import Table from '../../components/OtherTable/OtherTable';
import { updateCoachingData } from "../../redux/coaching/coachingSlice";
import { 
    updateData, 
    getUniversityByToken } from "../../redux/universities/universitySlice";
    
const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    topHeader: {
        fontSize: '34px',
        fontWeight: 'bold'
    },
    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    horizontalLine: {
        width: '200px',
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        height: '52px',
        width: '52px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '800px'
    },
    icon: {
        color: '#fff',
    },
    iconText: {
        color: 'var(--primary-color)',
        fontSize: '18px !important',
        marginTop: '8px !important',
        fontWeight: 'bold',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
        top: -24
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    table: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    },
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
}))

let courseOffered = {
    courses_offered_check: 1,
    courses_offered: {
        under_graduation: 1,
        post_graduation: 1,
        courses: [],
        fee_structure: [
            {
                course: "course 1",
                year: 1,
                fee: 0
            },
            {
                course: "course 1",
                year: 2,
                fee: 0
            },
            {
                course: "course 1",
                year: 3,
                fee: 0
            },
            {
                course: "course 1",
                year: 4,
                fee: 0
            },
            {
                course: "course 1",
                year: 5,
                fee: 0
            },
            {
                course: "course 2",
                year: 1,
                fee: 0
            },
            {
                course: "course 2",
                year: 2,
                fee: 0
            },
            {
                course: "course 2",
                year: 3,
                fee: 0
            },
            {
                course: "course 2",
                year: 4,
                fee: 0
            },
            {
                course: "course 2",
                year: 5,
                fee: 0
            },
            {
                course: "course 3",
                year: 1,
                fee: 0
            },
            {
                course: "course 3",
                year: 2,
                fee: 0
            },
            {
                course: "course 3",
                year: 3,
                fee: 0
            },
            {
                course: "course 3",
                year: 4,
                fee: 0
            },
            {
                course: "course 3",
                year: 5,
                fee: 0
            },
        ]
    },
}

let facultyDetails = {
    faculties_details_check: 1,
    faculties_details: [
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        }
    ]
}

const topFacultyTitle =  ["Name of faculty", "Rank", "Year of experience", "Upload profile picture"]

const Listing = () => {
    const [btnLoader, setBtnLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [names, setNames] = React.useState(["", "", "", "", ""]);
    const [rank, setRank] = React.useState(["", "", "", "", ""]);
    const [epxerience, setExperience] = React.useState(["", "", "", "", ""]);
    const [profilePic, setProfilePic] = React.useState(["", "", "", "", ""]);
    const [courseDetail, setCourseDetail] = React.useState(courseOffered);
    const [courseList, setCourseList] = React.useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const { 
        type, 
        universityByToken,
        universityById } = useSelector(
        (state) => state.university
    );
    const { 
        coachingByToken,
        coachingById } = useSelector(
        (state) => state.coaching
    );
    const saveAndNext = () => {
        if(universityById?.courses_offered_check && !isEdit){
            navigate(routes.PLACEMENT_RECORD);
            return;
        }
        if(coachingById?.faculties_details_check && !isEdit){
            navigate(routes.DETAILS);
            return;
        }
        else{
            if(type === "University"){
                if(courseDetail.courses_offered.under_graduation === 0 && courseDetail.courses_offered.post_graduation === 0){
                    return toast.error("Select Under Graduation or Post Graduation");
                }
                if(courseDetail.courses_offered.courses.length === 0){
                    return toast.error("Select Course");
                }
                if(courseDetail.courses_offered.fee_structure.length === 0){
                    return toast.error("Fill Fee Structure");
                }else{
                    let body = {
                        courses_offered_check: courseDetail?.courses_offered_check,
                        courses_offered: {
                            under_graduation: courseDetail?.courses_offered?.under_graduation ? 1 : 0,
                            post_graduation: courseDetail?.courses_offered?.post_graduation ? 1 : 0,
                            courses: courseDetail?.courses_offered?.courses,
                            fee_structure: courseDetail?.courses_offered?.fee_structure 
                        }
                    }
                    setBtnLoader(true);
                    let res = dispatch(updateData({body, 
                        id: universityById?._id ? universityById?._id : 
                        universityByToken?.data?._id}));
                    res.then((r) => {
                        setBtnLoader(false);
                        if(r.payload.statusCode === 200){
                            toast.success(r?.payload?.message);
                            dispatch(getUniversityByToken());
                            navigate(routes.PLACEMENT_RECORD);
                        }else{
                            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                                r?.payload?.message === "Session Expired"){
                                toast.error("Session expired, please login again");
                                localStorage.removeItem("hp_token");
                                localStorage.removeItem("hp_user");
                                navigate(routes.LOGIN);
                                return;
                            }
                            toast.error(r?.payload?.message);
                        }
                    })
                }
            }else{
                if(wrapperData().status){
                    toast.error(wrapperData().message);
                }else{
                    let facData = {
                        faculties_details_check: 1,
                        faculties_details: []
                    };
                    let newError = false;
                    wrapperData().faculty?.faculties_details?.map((item, index) => {
                        if((item?.name !== "" && 
                            item?.experience_years !== "" && 
                            item?.profile_picture !== "")){
                                facData.faculties_details.push(item);
                        }else{
                            if(item?.name === "" && 
                            item?.experience_years === "" && 
                            item?.profile_picture === ""){

                            }else{
                                newError = true;
                            }
                        }
                    })
                    if(newError){
                        toast.error("Missing Relevant Field");
                        return;
                    }
                    setBtnLoader(true);
                    let res = dispatch(updateCoachingData({body: facData, 
                        id: coachingById?._id ? coachingById?._id : 
                        coachingByToken?.data?._id}));
                    res.then((r) => {
                        setBtnLoader(false);
                        if(r.payload.statusCode === 200){
                            toast.success(r.payload.message);
                            //dispatch(getCoachingByToken());
                            localStorage.removeItem("new_coaching_token");
                            navigate(routes.COACHING_REGISTRATION);
                        }else{
                            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                                r?.payload?.message === "Session Expired"){
                                toast.error("Session expired, please login again");
                                localStorage.removeItem("hp_token");
                                localStorage.removeItem("hp_user");
                                navigate(routes.LOGIN);
                                return;
                            }
                            toast.error(r?.payload?.message);
                        }
                    })
                }
            }
        }
    }

    const courseChange = (e, index, year, course) => {
        let localArray = [];
        let array = [...courseDetail?.courses_offered?.fee_structure]
        localArray = array.map((element, i) => { 
            return i === index ? {...element, 
            ...(e.target.name === `${course}_${year}` && {fee: Number(e.target.value)})
            }: element
        })
        setCourseDetail({
            ...courseDetail, 
            courses_offered: {
                ...courseDetail.courses_offered,
                fee_structure: localArray
            }
        })
    }
    const courseChangeData = (e) => {
        e.target.name === "under_grad" && (setCourseDetail({
            ...courseDetail, 
            courses_offered: {
                ...courseDetail.courses_offered,
                under_graduation: e.target.checked ? 1 : 0
            }
        }))
        e.target.name === "post_grad" && (setCourseDetail({
            ...courseDetail, 
            courses_offered: {
                ...courseDetail.courses_offered,
                post_graduation: e.target.checked ? 1 : 0
            }
        }))
        if(e.target.name === "select_course") {
            if(e.target.value?.length < 4){
                setCourseDetail({
                ...courseDetail, 
                courses_offered: {
                    ...courseDetail.courses_offered,
                    courses: e.target.value
                    }
                })
            }else{
                toast.error("Max 3 courses are allowed")
            }
        }
    }
    const wrapperData = () => {
        let data = {status: false, message: "", faculty: facultyDetails}
        facultyDetails.faculties_details.map((item, index) => {
            // if(names[index] && epxerience[index] && profilePic[index]){
                item.name = names[index];
                item.experience_years = epxerience[index];
                item.profile_picture = profilePic[index];
            // }else{
            //     data = {status: true, message: "Relevant data required", faculty: facultyDetails}
            // }
        })
        return data;
    }
    useEffect(() => {
        const res = dispatch(getConfigDetails());
        res.then((r) => {
            if(r?.payload?.data){
                r?.payload?.data?.map((item) => {
                    if(item?.configuration_type === "university_courses" && type === "University"){
                        setCourseList(item?.data);
                    }
                    if(item?.configuration_type === "coaching_courses" && type === "Coaching"){
                        setCourseList(item?.data);
                    }
                })
            }
        })
        if(universityById?.courses_offered_check){
            setCourseDetail({...courseDetail, 
                courses_offered: universityById?.courses_offered})
        }
        if(coachingById?.faculties_details_check){
            let nameFrom = [];
            let experienceFrom = [];
            let profilePicFrom = [];
            [1, 2, 3, 4, 5]?.map((item, index) => {
                nameFrom[index] = coachingById?.faculties_details[index]?.name ? 
                    coachingById?.faculties_details[index]?.name : '';
                experienceFrom[index] = coachingById?.faculties_details[index]?.experience_years ? 
                    coachingById?.faculties_details[index]?.experience_years : '';
                profilePicFrom[index] = coachingById?.faculties_details[index]?.profile_picture ? 
                    coachingById?.faculties_details[index]?.profile_picture : '';
            })
            setNames([...nameFrom]);
            setExperience([...experienceFrom]);
            setProfilePic([...profilePicFrom]);
        }
    },[])
    const sumFunction = (array, course) => {
        let total = 0;
        array.map((item) => {
            if(item.course === course){
                total = total + item.fee
            }
        });
        return total;
    }
    return (
        <>
        <div style={{
            marginTop: '3%'
        }}>
            <div className={classes.topHeading}>
                <div></div>
                <Typography component="h2" variant="h2" 
                    className={classes.topHeader}>Details</Typography>
                <div>
                    <Button sx={{
                        backgroundColor: 'var(--primary-color)',
                        width: '130px'
                    }} variant="contained" onClick={saveAndNext} disabled={btnLoader}>
                        {btnLoader ? <LoaderIcon />  : 
                        (((universityById?.courses_offered_check || coachingById?.faculties_details_check) && 
                        !isEdit) ? 'Next' : 'Save & Next')}
                    </Button>
                    {(universityById?.courses_offered_check || 
                        coachingById?.faculties_details_check) &&
                    <Button variant="outlined" 
                        onClick={() => setIsEdit(!isEdit)}
                        sx={{
                            color: 'var(--primary-color)',
                            marginLeft: '8px'
                        }}>
                        {isEdit ? 'Cancel' : 'Edit'}
                    </Button>}
                </div>
            </div>
            <div className={classes.formIconsBox}>
                <div className={classes.iconBox}>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine}></div>
                        <div className={classes.iconBox2} style={{left: 48}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    }}>
                                <BorderColorIcon fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText}>
                                Create
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 72}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    fontSize: '24px',
                                    backgroundColor: 'var(--primary-color)'
                                    }}>
                                i
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Information
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--primary-color)'}}>
                                <CameraAltIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Uploads
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--primary-color)'}}>
                                <MenuBookIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                {type === "University" ? 'Courses' : 'Faculties'}
                            </Typography>
                        </div>
                    </div>
                    {type === "University" && 
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 24}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <BusinessCenterIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                Placement Records
                            </Typography>
                        </div>
                    </div>}
                </div>
            </div>
            <div style={{
                marginTop: '10%',
            }}>
                {type === "University" &&
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Checkbox className={classes.checkBox} name="under_grad" onChange={courseChangeData} 
                            checked={courseDetail?.courses_offered?.under_graduation ? true : false}/>
                        <InputLabel className={classes.formInputLabel} sx={{marginBottom: '0px'}}>
                            Under Graduation
                        </InputLabel>
                        <Checkbox className={classes.checkBox} name="post_grad" onChange={courseChangeData} 
                            checked={courseDetail?.courses_offered?.post_graduation ? true : false}/>
                        <InputLabel className={classes.formInputLabel} sx={{marginBottom: '0px'}}>
                            Post Graduation
                        </InputLabel>
                    </div>
                    <div style={{
                        marginLeft: '0.8%',
                        marginTop: '20px',
                    }}>
                        <InputLabel className={classes.formInputLabel} sx={{marginBottom: '0px'}}>
                            Courses
                        </InputLabel>
                        <Select
                            name="select_course"
                            multiple
                            value={courseDetail.courses_offered.courses}
                            onChange={courseChangeData}
                            placeholder="course"
                            autoWidth
                            sx={{
                                width: '32%',
                                height: '56px',
                                position: 'relative',
                                marginRight: '12px'
                            }}
                            >
                                {courseList.map((item, index) => (
                                    <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))}
                        </Select>
                    </div>
                    <InputLabel className={classes.formInputLabel} sx={{
                        marginBottom: '0px',
                        marginTop: '32px',
                        marginLeft: '8px'
                        }}>
                        Fees structure for courses
                    </InputLabel>
                    <div className={classes.table}>
                        <div className={classes.tableTh}>
                            <div className={classes.tableTd}>Fees for calender year 2023</div>
                            <div className={classes.tableTd}>Year 1</div>
                            <div className={classes.tableTd}>Year 2</div>
                            <div className={classes.tableTd}>Year 3</div>
                            <div className={classes.tableTd}>Year 4</div>
                            <div className={classes.tableTd}>year 5</div>
                            <div className={classes.tableTd} style={{borderRight: 'none'}}>Total Fees</div>
                        </div>
                        <div className={classes.tableTh}>
                            <div className={classes.tableTd+' table-td'}>
                                {courseDetail?.courses_offered?.courses[0] ?
                                courseDetail?.courses_offered?.courses[0] : 'Course 1'}
                            </div>
                            {courseDetail?.courses_offered?.fee_structure.map((item, index) => (
                                item.course === "course 1" &&
                                    <div className={classes.tableTd} style={{
                                        padding: 0,
                                    }} key={index}>
                                        <input type="number" 
                                            value={item.fee === 0 ? '' : item.fee}
                                            onChange={(e) => {courseChange(e, index, item.year, "course_1")}}
                                            name={`course_1_${item.year}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                border: 'none'}} />
                                    </div>
                            ))}
                            <div className={classes.tableTd} style={{
                                borderRight: 'none',
                                padding: 0}}>
                                {sumFunction(courseDetail?.courses_offered?.fee_structure, "course 1")}
                            </div>
                        </div>
                        <div className={classes.tableTh}>
                            <div className={classes.tableTd+' table-td'}>
                                {courseDetail?.courses_offered?.courses[1] ?
                                courseDetail?.courses_offered?.courses[1] : 'Course 2'}
                            </div>
                            {courseDetail?.courses_offered?.fee_structure.map((item, index) => (
                                item.course === "course 2" &&
                                    <div className={classes.tableTd} style={{
                                        padding: 0,
                                    }} key={index}>
                                        <input type="number" 
                                            value={item.fee === 0 ? '' : item.fee}
                                            onChange={(e) => {courseChange(e, index, item.year, "course_2")}}
                                            name={`course_2_${item.year}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                border: 'none'}} />
                                    </div>
                            ))}
                            <div className={classes.tableTd} style={{
                                borderRight: 'none',
                                padding: 0}}>
                                {sumFunction(courseDetail?.courses_offered?.fee_structure, "course 2")}
                            </div>
                        </div>
                        <div className={classes.tableTh} style={{border: 'none'}}>
                            <div className={classes.tableTd+' table-td'}>
                                {courseDetail?.courses_offered?.courses[2] ?
                                courseDetail?.courses_offered?.courses[2] : 'Course 3'}
                            </div>
                            {courseDetail?.courses_offered?.fee_structure.map((item, index) => (
                                item.course === "course 3" &&
                                    <div className={classes.tableTd} style={{
                                        padding: 0,
                                    }} key={index}>
                                        <input type="number" 
                                            value={item.fee === 0 ? '' : item.fee}
                                            onChange={(e) => {courseChange(e, index, item.year, "course_3")}}
                                            name={`course_3_${item.year}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                border: 'none'}} />
                                    </div>
                            ))}
                            <div className={classes.tableTd} style={{
                                borderRight: 'none',
                                padding: 0}}>
                                {sumFunction(courseDetail?.courses_offered?.fee_structure, "course 3")}
                            </div>
                        </div>
                    </div>
                </>
                }
            {type === "Coaching" &&
                <div className="course-detail-aria" style={{
                    marginTop: '120px'
                }}>
                    <Table 
                        profilePic={profilePic}
                        experience={epxerience}
                        setExperience={(exp) => setExperience([...exp])}
                        setNames={(name) => setNames([...name])}
                        rank={rank}
                        setRank={(rank) => setRank([...rank])}
                        names={names}
                        tableWidth="100%"
                        uploadPicFor="faculties"
                        title="Top Faculties In Your Institute"
                        subTitle={topFacultyTitle}
                        isOptionalText={true}  />
                </div>
                }
            </div>
        </div>
        </>
    );
};

export default Listing;
