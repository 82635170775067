import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Box,
    InputLabel,
    TextField,
    Typography
  } from "@mui/material";
import Moment from "moment";
import routes from "../../react_routes_enum";
import { toast } from "react-toastify";
import InputAdornment from '@mui/material/InputAdornment';
import Grid from "@mui/material/Grid";
import Spinner from "../../components/Spinner/Spinner";
import { makeStyles } from "@mui/styles";
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CakeIcon from '@mui/icons-material/Cake';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import CurrentQual from  "../../assest/images/coaching_grey.png";
import CourseIcon from "../../assest/images/course_icon.png";
import BudgetIcon from "../../assest/images/budget_icon.png";
import CurrentlyCollegeIcon from "../../assest/images/currently_college_icon.png";
import { getUniversityById } from "../../redux/universities/universitySlice";
import { getCoachingById } from "../../redux/coaching/coachingSlice";
import { 
    getApplicationById
  } from "../../redux/students/studentsSlice";

const useStyle = makeStyles({
    benefitSingleBox: {
        display: "flex",
        marginBottom: '24px',
        alignItems: "center",
    },
    howItWorkSingleBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: '24px',
        alignItems: "center",
    },
    buttonStyle: {
        background: 'none',
        textTransform: 'capitalize !important',
        position: 'absolute !important',
        right: 0,
        top: 8,
    },
    verifySubmit: {
        height: '36px',
        textTransform: 'capitalize !important',
        color: '#FFFFFF !important',
        width: '150px',
        '&:hover':{
            backgroundColor: '#DC0707 !important',
        },
    },
    marginBot24: {
        marginBottom: '24px'
    },
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    uploadButton: {
        height: '80px',
        border: '2px dashed rgb(0,0,0,0.5) !important',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF !important',
        "&:hover": {
            backgroundColor: '#ffffff !important',
        }
    },
    requestButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '100%',
        height: '44px',
        fontSize: '16px !important',
        borderRadius: '8px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    }
})

let formData = {
    email: "",
    password: "",
    name: "",
    address: "",
    phone: "",
    dob: "",
    id_proof: "",
    phone_country_code: "91",
    verification_id_email: "",
    verification_id_phone: "",
    registered_for: "university",
    course_interested_in: "",
    subjects: [],
    countries_interested_in: [],
    budget_in_lakhs: "",
    education_loan_assistance_needed: true,
    is_first_attempt: false
}

export default function ApplicationDetails() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { 
        isLoading, 
        isError, 
        message, 
        studentId,
        applicationById
      } = useSelector((state) => state.students);
    const { coachingById } = useSelector((state) => state.coaching); 
    const { universityById } = useSelector((state) => state.university); 

    const [formInformation, setFormInformation] = React.useState(applicationById ? applicationById : formData);
    React.useEffect(() => {
        const res = dispatch(getApplicationById({id: studentId}));
        res.then((r) => {
            if(r?.payload?.statusCode === 200){
                setFormInformation(r.payload?.data);
                r.payload?.data?.university_id ? 
                    dispatch(getUniversityById({universityId: r.payload?.data?.university_id})) :
                        dispatch(getCoachingById({universityId: r.payload?.data?.coaching_id}))
            }else{
                if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                    r?.payload?.message === "Session Expired"){
                    toast.error("Session expired, please login again");
                    localStorage.removeItem("hp_token");
                    localStorage.removeItem("hp_user");
                    navigate(routes.LOGIN);
                }
            }
        })
    }, []);

    if (isLoading) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
        <>
        <Grid
            container
            component="main"
            sx={{ height: "100vh" }}
            className="login-form"
        >
            <Box
                sx={{
                my: 8,
                width: '100%'
                }}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid rgb(0, 0, 0, 0.4)',
                    marginBottom: '32px',
                    paddingBottom: '16px'
                }}>
                    <div style={{ 
                        height: '75px',
                        width: '75px',
                        backgroundColor: '#d9d9d9',
                        borderRadius: '50%',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '12px',
                        overflow: 'hidden'
                    }}>
                        {(universityById || coachingById) && <img 
                            src={coachingById?.uploads?.coaching_logo || 
                            universityById?.uploads?.university_logo} 
                            alt="logo" style={{
                                width: '100%'
                            }} />}
                    </div>
                    <div>
                        <Typography component="h5" variant="h5" sx={{
                            fontWeight: 'bold',
                            color: '#F69220'
                        }}>
                            Register Now To Apply
                        </Typography>
                        <Typography component="p" variant="p" sx={{
                            fontWeight: 'bold',
                            color: 'rgb(0, 0, 0, 0.5)'
                        }}>
                            {universityById?.university_name || coachingById?.coaching_name},{" "}
                            {universityById?.information?.city || coachingById?.information?.city}
                        </Typography>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    width: '100%'
                }}>
                    <div style={{
                        width: '45%',
                        marginRight: '24px'
                    }}>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Name
                            </InputLabel>
                            <TextField placeholder="" type="text" variant="outlined" name="name" 
                                InputProps={{
                                    startAdornment: <PersonIcon 
                                        position="start" 
                                        sx={{
                                            color: 'rgb(0, 0, 0, 0.4)',
                                            marginRight: '8px'
                                        }}>

                                        </PersonIcon>,
                                }}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }}value={formInformation.name} required/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Phone number
                            </InputLabel>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div style={{
                                    width: '100%',
                                    position: 'relative',
                                    display: 'flex',
                                }} className="input-box-appl-detail">
                                    <TextField 
                                        variant="outlined" 
                                        value="+91"
                                        sx={{
                                            width: '12%',
                                            height: '62px',
                                            borderRadius: 'none'
                                        }}/>
                                    <TextField 
                                        type="number" 
                                        variant="outlined" 
                                        sx={{
                                            width: '88%',
                                            height: '62px',
                                            borderRadius: 'none'
                                        }} 
                                        InputProps={{
                                            startAdornment: <LocalPhoneIcon 
                                                position="start" 
                                                sx={{
                                                    color: 'rgb(0, 0, 0, 0.4)',
                                                    marginRight: '8px'
                                                    }}>

                                                </LocalPhoneIcon>,
                                        }}
                                        name="phone-number" 
                                        value={formInformation.phone_number} 
                                        required/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Date of birth
                            </InputLabel>
                            <TextField 
                                InputProps={{
                                    startAdornment: <CakeIcon 
                                        position="start" 
                                        sx={{
                                            color: 'rgb(0, 0, 0, 0.4)',
                                            marginRight: '8px'
                                            }}>
                                        </CakeIcon>,
                                }}
                                type="text" 
                                variant="outlined" 
                                name="dob" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                className="input-box-appl-detail"
                                value={Moment(formInformation?.dob).format("DD MMMM YYYY")}/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Current Qualification
                            </InputLabel>
                            <TextField 
                                InputProps={{
                                    startAdornment: 
                                    <InputAdornment position="start">
                                        <img src={CurrentQual} alt="" width="18" style={{
                                            marginRight: '4px'
                                        }} />
                                    </InputAdornment>,
                                }}
                                type="text" 
                                variant="outlined" 
                                name="dob" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                className="input-box-appl-detail"
                                value={formInformation?.current_qualification}/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Study Material
                            </InputLabel>
                            <TextField 
                                InputProps={{
                                    startAdornment: <CalendarTodayIcon position="start" 
                                    sx={{color: 'rgb(0, 0, 0, 0.4)'}}></CalendarTodayIcon>,
                                }}
                                type="text" 
                                variant="outlined" 
                                name="dob" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                className="input-box-appl-detail"
                                value={formInformation.study_material ? 
                                    formInformation.study_material : ''}/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Budget <span style={{
                                    color: 'rgb(0, 0, 0, 0.4)',
                                    fontSize: '14px'
                                }}>(as per course selection)</span>
                            </InputLabel>
                            <TextField 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                    <img src={BudgetIcon} alt="" width="18" style={{
                                        marginRight: '8px'
                                    }} />
                                  </InputAdornment>,
                                }}
                                type="text" 
                                variant="outlined" 
                                name="dob" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                className="input-box-appl-detail"
                                value={formInformation?.budget}/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Contact Number Of Parent
                            </InputLabel>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div style={{
                                    width: '100%',
                                    position: 'relative',
                                    display: 'flex',
                                }} className="input-box-appl-detail">
                                    <TextField 
                                        variant="outlined" 
                                        value="+91"
                                        sx={{
                                            width: '12%',
                                            height: '62px',
                                            borderRadius: 'none'
                                        }}/>
                                    <TextField 
                                        type="number" 
                                        variant="outlined" 
                                        sx={{
                                            width: '88%',
                                            height: '62px',
                                            borderRadius: 'none'
                                        }} 
                                        InputProps={{
                                            startAdornment: <LocalPhoneIcon 
                                                position="start" 
                                                sx={{
                                                    color: 'rgb(0, 0, 0, 0.4)',
                                                    marginRight: '8px'
                                                    }}>

                                                </LocalPhoneIcon>,
                                        }}
                                        name="phone-number" 
                                        value={formInformation?.parent_phone_number} 
                                        required/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        width: '45%'
                    }}>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Address
                            </InputLabel>
                            <TextField 
                                InputProps={{
                                    startAdornment: <RoomIcon 
                                        position="start" 
                                        sx={{
                                            color: 'rgb(0, 0, 0, 0.4)',
                                            marginRight: '8px'
                                            }}>
                                        
                                        </RoomIcon>,
                                }}
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                className="input-box-appl-detail"
                                value={formInformation?.address}/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Email ID
                            </InputLabel>
                            <div style={{
                                    position: 'relative',
                                }}>
                                <TextField 
                                    placeholder="" 
                                    type="email" 
                                    variant="outlined" 
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    InputProps={{
                                        startAdornment: <EmailIcon 
                                            position="start" 
                                            sx={{
                                                color: 'rgb(0, 0, 0, 0.4)',
                                                marginRight: '8px'
                                                }}>

                                            </EmailIcon>,
                                    }}
                                    name="email" 
                                    value={formInformation?.email} 
                                    required/>
                            </div>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Currently in which school/college <span style={{
                                    color: 'rgb(0, 0, 0, 0.4)',
                                    fontSize: '14px'
                                }}>(optional)</span>
                            </InputLabel>
                            <div style={{
                                    position: 'relative',
                                }}>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    variant="outlined" 
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                        <img src={CurrentlyCollegeIcon} alt="" width="16" 
                                        style={{
                                            marginRight: '8px'
                                        }} />
                                      </InputAdornment>,
                                    }}
                                    value={formInformation?.current_college} 
                                    required/>
                            </div>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Courses
                            </InputLabel>
                            <TextField 
                                InputProps={{
                                    startAdornment:  <InputAdornment position="start">
                                    <img src={CourseIcon} alt="" width="16" 
                                    style={{
                                        marginRight: '8px'
                                    }} />
                                  </InputAdornment>,
                                }}
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                className="input-box-appl-detail"
                                value={formInformation?.courses?.toString()}
                                />
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Location <span style={{
                                    color: 'rgb(0, 0, 0, 0.4)',
                                    fontSize: '14px'
                                }}>(For online classes)</span>
                            </InputLabel>
                            <TextField 
                                InputProps={{
                                    startAdornment: <RoomIcon 
                                        position="start" 
                                        sx={{
                                            color: 'rgb(0, 0, 0, 0.4)',
                                            marginRight: '8px'
                                            }}>

                                        </RoomIcon>,
                                }}
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                className="input-box-appl-detail"
                                value={formInformation?.location}/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Parent's Name
                            </InputLabel>
                            <TextField 
                                InputProps={{
                                    startAdornment: <PersonIcon 
                                        position="start" 
                                        sx={{
                                            color: 'rgb(0, 0, 0, 0.4)',
                                            marginRight: '8px'
                                            }}>
                                        
                                        </PersonIcon>,
                                }}
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                className="input-box-appl-detail"
                                value={formInformation?.parent_name}/>
                        </div>
                    </div>
                </div>
            </Box>
        </Grid>
        </>
    );
}