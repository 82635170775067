import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogTitle from '@mui/material/DialogTitle';
import { 
  Button, 
  IconButton, 
  Typography, 
  TextField } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import MealTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Spinner from "../../components/Spinner/Spinner";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { 
  getApplication, 
  updateApplication,
  setStudentsById
} from "../../redux/students/studentsSlice";
const rejectBody = {
    status: "rejected",
    reject_reason: ""
}


const ApplicationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRejectModel, setIsRejectModel] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [reject, setReject] = useState(rejectBody);
  const [applId, setApplId] = useState()

  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    applicationData,
  } = useSelector((state) => state.students);

  const colR = ["SNo", "Name Of Student", "Email ID", "DOB", "Address", "action"];

  useEffect(() => {
    const res = dispatch(getApplication({limit, offset}));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getApplication({limit, offset, searchText}));
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  const rejectSubmit = () => {
    if(reject?.reject_reason === ""){
      toast.error("Rejection reason required");
      return;
    }
    const res = dispatch(updateApplication(reject));
    res.then((r) =>{
      if(r?.payload?.statusCode === 200){
        setIsDialogOpen(false);
        dispatch(getApplication({limit, offset}));
        toast.success("Application rejected successfully");
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  const confirmApprove = () => {
    const res = dispatch(updateApplication({id: applId, status: 'college_clue_accepted'}));
    res.then((r) =>{
      if(r?.payload?.statusCode === 200){
        setIsDialogOpen(false);
        dispatch(getApplication({limit, offset}));
        toast.success("Application approved successfully");
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleChange = () => {

  }
  return (
    <>
      <Dialog maxWidth={isRejectModel ? "sm" : "xs"} open={isDialogOpen} onClose={handleClose} fullWidth>
        <form onSubmit={handleChange}>
          {isRejectModel && 
            <>
              <DialogTitle
                sx={{
                  color: 'var(--primary-color)',
                  fontWeight:'600'}}>
                  Reason <span style={{
                    fontSize:'14px', 
                    color:'rgb(0, 0, 0, 0.5)', 
                    fontWeight: '400'}}>(mention the reason why are you rejecting)</span>
              </DialogTitle>
              <DialogContent className='for-input'>
                <div style={{
                  position: 'relative'
                }}>
                  <TextField fullWidth 
                    placeholder="message" 
                    variant="outlined"
                    onChange={(e) => {setReject({...reject, reject_reason: e.target.value})}}
                    multiline
                    rows={7}
                    sx={{border: 'none',
                    "&:hover":{border:'none'}
                    }}
                    required/>
                  <div style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8
                  }}>
                    <Button variant="contained"
                      sx={{
                        backgroundColor: 'var(--primary-color)',
                        width: '84px',
                        marginRight: '12px',
                        textTransform: 'capitalize',
                        "&:hover": {
                          backgroundColor: 'var(--primary-color'},
                        }}
                      type="button"
                      onClick={rejectSubmit}>Submit</Button>
                  </div>
                </div>
              </DialogContent>
            </>
          }
          {isAccept && 
            <div>
              <Typography component="p" variant="p" 
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  margin: '28px 0 48px'}}>
                Are you sure you want to<br/> accept the application ?
              </Typography>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                padding: '0px 16px 28px 16px'
              }}>
                <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
                  textTransform: 'capitalize'
                }}>
                  Cancel
                </Button>
                <Button 
                  onClick={() => {confirmApprove()}} 
                  variant="contained" 
                  sx={{
                    backgroundColor: 'var(--primary-color)',
                    width: '84px',
                    marginRight: '12px',
                    textTransform: 'capitalize'
                  }}>
                  Yes
                </Button>
              </div>
            </div>
          }
        </form>
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="students"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <MealTable
          columns={colR}
          where="recipes"
          rows={applicationData && applicationData?.map((item) => {
            const res = {
              ...item,
              action: (
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <IconButton
                        color="inherit"
                        edge="start"
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        }}
                        onClick={() => {
                          setReject({...reject, id: item?._id});
                          setIsAccept(false);
                          setIsRejectModel(true);
                          setIsDialogOpen(true);
                        }}
                    >
                        <DoDisturbIcon sx={{color: '#EF0000'}} />
                        <Typography component="p" variant="p" 
                        sx={{
                            color: '#EF0000',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginTop: '6px'
                            }}>
                        Reject
                        </Typography>
                    </IconButton>
                    <IconButton
                        color="inherit"
                        edge="start"
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 12px 0 4px'
                        }}
                        onClick={() => {
                          setApplId(item?._id);
                          setIsAccept(true);
                          setIsRejectModel(false);
                          setIsDialogOpen(true);
                        }}
                    >
                        <CheckCircleOutlineIcon sx={{color: '#07AB35'}} />
                        <Typography component="p" variant="p" 
                        sx={{
                            color: '#07AB35',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginTop: '6px'
                            }}>
                        Approve
                        </Typography>
                    </IconButton>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={() => {
                          dispatch(setStudentsById(item?._id));
                        }}
                    >
                        <Link to={routes.APPLICATION_DETAIL}>
                        <VisibilityIcon sx={{color: '#3C1CDE'}} />
                        <Typography component="p" variant="p" 
                            sx={{
                            color: '#3C1CDE',
                            fontSize: '18px',
                            fontWeight: 'bold'
                            }}>
                            View
                        </Typography>
                        </Link>
                    </IconButton>
                </div>
              )
            };
            return res;
          })}
        />
      </div>
    </>
  );
};

export default ApplicationList;
