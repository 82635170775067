import axios from "axios";
import endpoint from "../../index.routes";

const getProductByInstitute = async (params) => {
    const { limit, offset, university_id, coaching_id, searchText } = params;
    const response = await axios.get(
      endpoint.INSTITUTE_PROD,
      {
        params: { limit, offset, searchText, university_id, coaching_id },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    return response.data;
};
const addProduct = async (params) => {
  const response = await axios.post(endpoint.INSTITUTE_PROD, params, {
    headers: { token: localStorage.getItem("hp_token") },
  });
  return response.data;
};
const getProductById = async (params) => {
  const response = await axios.get(
    endpoint.INSTITUTE_PROD + "/" + params,
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const productStatusUpdate = async (params) => {
  let id = params.id;
  const response = await axios.put(
    endpoint.INSTITUTE_PROD + "/" + id, {status: params.status},
    {
      headers: { token: localStorage.getItem("hp_token") }
    }
  );
  return response.data;
}

const productUpdate = async (params) => {
  let id = params._id;
  let body = {};
  if(params?.coaching_id){
    body = {
      name: params.name,
      category: params.category,
      product_type: params.product_type,
      payment_mode: params.payment_mode,
      plan: params.plan,
      description: params.description,
      syllabus_url: params.syllabus_url,
      images_url: params.images_url,
      top_faculties: params.top_faculties,
      toppers: params.toppers,
      sub_products: params.sub_products,
      add_ons: params.add_ons
    }
  }else{
    body = {
      name: params.name,
      category: params.category,
      product_type: params.product_type,
      branches: params.branches,
      course_type: params.course_type,
      description: params.description,
      syllabus_url: params.syllabus_url,
      images_url: params.images_url,
      toppers: params.toppers,
      sub_products: params.sub_products,
      add_ons: params.add_ons
    }
  }
  const response = await axios.put(endpoint.INSTITUTE_PROD + "/" + id, body,
    {
      headers: { token: localStorage.getItem("hp_token") }
    }
  );
  return response.data;
}

const instituteProdServices = { 
    getProductByInstitute, 
    addProduct,
    getProductById,
    productStatusUpdate,
    productUpdate
};

export default instituteProdServices;
