import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import coachingServices from "./coachingServices";

const initialState = {
  isCError: false,
  isSuccess: false,
  isCLoading: false,
  cMessage: "",
  coachingData: null,
  totalCount: 0,
  isLoadingforSearch: false,
  isErrorforSearch: false,
  messageforSearch: false,
  searchedSubAdmin: null,
  totalSearchedSubAdmin: null,
  searchText: "",
  coachingById: null,
  coachingId: null,
  newPostResponse: null,
  coachingByToken: null,
  updateCoachingResponse: null,
};


export const getCoaching = createAsyncThunk(
    "coaching/getCoaching",
    async (params, thunkAPI) => {
      try {
        return await coachingServices.getCoaching(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getCoachingById = createAsyncThunk(
    "coaching/getCoachingById",
    async (params, thunkAPI) => {
      try {
        return await coachingServices.getCoachingById(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getCoachingByToken = createAsyncThunk(
  "coaching/getCoachingByToken",
  async (params, thunkAPI) => {
    try {
      return await coachingServices.getCoachingByToken(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCoachingData = createAsyncThunk(
  "coaching/updateCoachingData",
  async (params, thunkAPI) => {
    try{
      return await coachingServices.updateCoachingData(params);
    }catch(error){
      const message = 
        (error.response && 
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
          return thunkAPI.rejectWithValue(message);
    }
  }
);

export const coachingStatusUpdate = createAsyncThunk(
  "coaching/coachingStatusUpdate",
  async (params, thunkAPI) => {
    try{
      return await coachingServices.coachingStatusUpdate(params);
    }catch(error){
      const message = 
        (error.response && 
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
          return thunkAPI.rejectWithValue(message);
    }
  }
);

const coachingSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setCoachingFilterDetails: (state, action) => {
        state.searchText = action.payload;
    },
    setCoachingById: (state, action) => {
        // console.log(action.payload);
        state.coachingId = action.payload;
    },
    setCoachingByIdData: (state, action) => {
      // console.log(action.payload);
      state.coachingById = action.payload;
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoaching.pending, (state) => {
        state.isCLoading = true;
      })
      .addCase(getCoaching.rejected, (state, action) => {
        state.isCLoading = false;
        state.isCError = true;
        state.cMessage = action.payload;
      })
      .addCase(getCoaching.fulfilled, (state, action) => {
        state.isCLoading = false;
        state.isSuccess = true;
        state.isCError = false;
        state.cMessage = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.coachingData = action.payload.data.result;
      })
      .addCase(getCoachingById.pending, (state) => {
        state.isCLoading = true;
      })
      .addCase(getCoachingById.rejected, (state, action) => {
        state.isCLoading = false;
        state.isCError = true;
        state.cMessage = action.payload;
      })
      .addCase(getCoachingById.fulfilled, (state, action) => {
        state.isCLoading = false;
        state.isSuccess = true;
        state.isCError = false;
        state.coachingById = action.payload.data;
        state.cMessage = action.payload.message;
      })
      .addCase(coachingStatusUpdate.pending, (state) => {
        state.isCLoading = true;
      })
      .addCase(coachingStatusUpdate.rejected, (state, action) => {
        state.isCLoading = false;
        state.isCError = true;
        state.cMessage = action.payload;
      })
      .addCase(coachingStatusUpdate.fulfilled, (state, action) => {
        state.isCLoading = false;
        state.isSuccess = true;
        state.isCError = false;
        state.cMessage = action.payload.message;
      })
      .addCase(getCoachingByToken.pending, (state) => {
        state.isCLoading = true;
      })
      .addCase(getCoachingByToken.rejected, (state, action) => {
        state.isCLoading = false;
        state.isCError = true;
        state.cMessage = action.payload;
      })
      .addCase(getCoachingByToken.fulfilled, (state, action) => {
        state.isCLoading = false;
        state.isSuccess = true;
        state.isCError = false;
        state.coachingByToken = action.payload;
        state.cMessage = action.payload.message;
      })
      .addCase(updateCoachingData.pending, (state) => {
        state.isCLoading = true;
      })
      .addCase(updateCoachingData.rejected, (state, action) => {
        state.isCLoading = false;
        state.isCError = true;
        state.cMessage = action.payload;
      })
      .addCase(updateCoachingData.fulfilled, (state, action) => {
        state.isCLoading = false;
        state.isSuccess = true;
        state.isCError = false;
        state.updateCoachingResponse = action.payload;
      })
  },
});

export const { 
    setCoachingFilterDetails, 
    setCoachingByIdData,
    setCoachingById } = coachingSlice.actions;

export default coachingSlice.reducer;
