import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Select,
  MenuItem,
  TextField } from "@mui/material";
import Spinner from "../../components/Spinner/Spinner";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import routes from "../../react_routes_enum";
import { getPartnerById } from "../../redux/partner/partnerSlice";

const useStyles = makeStyles( theme => ({
    startButton: {
        backgroundColor: '#672F8F !important', 
        color: '#fff !important', 
        width: '140px',
        marginTop: '16px !important',
        "&:hover": {
            backgroundColor: "#672F8F !important",
        },
    },
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    }
}))

const experienceData = ["1", "2", "3", "4", "5", "6", "7", "8",]
const partnerType = [
    {
        name: "Coaching",
        value: "coaching",
    },
    {
        name: "Admission Consultancy",
        value: "admission_consultancy",
    }
]
const consDomain = [
    {
        name: "Domestic",
        value: "domestic",
    },
    {
        name: "International",
        value: "international",
    }
]
const levelData = ["UG Program Colleges", "PG Program Colleges", "Entrance Exam"]
const countryList = ["India", "Nepal"]
const cityList = ["Delhi", "Mumbai"]
const domainCatData = ["Medical", "Engineering", "IT", "Civil Services", "Government Exams", "Social Science", "Legal", "Creative Fields", "Commerce"]

const Details = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { 
    isLoading,
    isError, 
    message,
    partnerId,
    partnerById } = useSelector(
    (state) => state.partner
  );

  useEffect(() => {
    if(!partnerId) naviagte(-1)
    const res = dispatch(getPartnerById({partnerId}));
    res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            naviagte(routes.LOGIN);
        }
    })
  }, [partnerId]);

  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;
  
  return (
    <>
        <h3 style={{
          fontSize: '30px',
          textAlign: 'center',
          marginBottom: '0px'
        }}>
          Details
        </h3>
        <div style={{
            marginTop: '3%',
            display: 'flex',
            }}>
            <form style={{
                width: '100%',
            }}>
                <div className="tow-input-form">
                    <div className={classes.marginBot24} style={{
                        width: '100%',
                    }}>
                        <InputLabel className={classes.formInputLabel}>
                            Name
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            variant="outlined" 
                            name="name" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            // onChange={handleChange} 
                            value={partnerById?.name} 
                            required/>
                    </div>
                    <div className={classes.marginBot24} style={{
                        width: '100%',
                        marginLeft: '24px'
                    }}>
                        <InputLabel className={classes.formInputLabel}>
                            Individual or Company
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            variant="outlined" 
                            name="individual_or_company" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            // onChange={handleChange} 
                            value={partnerById?.individual_or_company} 
                            required/>
                    </div>
                </div>
                <div className="tow-input-form">
                    <div className={classes.marginBot24} style={{width: '100%'}}>
                        <InputLabel className={classes.formInputLabel}>
                            Experience
                        </InputLabel>
                        <div>
                            <Select
                                name="experience"
                                value={partnerById?.experience_in_years}
                                // onChange={handleChange}
                                placeholder="Select"
                                autoWidth
                                required
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                }}
                                >
                                    {experienceData && experienceData?.map((item, index) => (
                                        <MenuItem value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                    <div className={classes.marginBot24} 
                        style={{width: '100%', marginLeft: '24px'}}>
                        <InputLabel className={classes.formInputLabel}>
                            Type
                        </InputLabel>
                        <div>
                            <Select
                                name="type"
                                value={partnerById?.partner_type}
                                // onChange={handleChange}
                                placeholder="Select"
                                autoWidth
                                required
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                }}>
                                    {partnerType && partnerType?.map((item, index) => (
                                        <MenuItem value={item.value} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="tow-input-form">
                    <div className={classes.marginBot24} style={{width: '100%'}}>
                        <InputLabel className={classes.formInputLabel}>
                            Domain of consultancy
                        </InputLabel>
                        <div className="form-two-inputs">
                            <Select
                                name="consultancy_domain"
                                value={partnerById?.consultancy_domain}
                                // onChange={handleChange}
                                placeholder="Select"
                                autoWidth
                                required
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                }}>
                                    {consDomain && consDomain?.map((item, index) => (
                                        <MenuItem value={item.value} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                    <div className={classes.marginBot24} 
                        style={{width: '100%', marginLeft: '24px'}}>
                        <InputLabel className={classes.formInputLabel}>
                            Level
                        </InputLabel>
                        <div className="form-two-inputs">
                            <Select
                                name="level"
                                value={partnerById?.level}
                                // onChange={handleChange}
                                placeholder="Select"
                                autoWidth
                                required
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                }}
                                >
                                    {levelData && levelData?.map((item, index) => (
                                        <MenuItem value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="tow-input-form">
                    <div className={classes.marginBot24} style={{width: '100%'}}>
                        <InputLabel className={classes.formInputLabel}>
                            Category domain
                        </InputLabel>
                        <div className="form-two-inputs">
                            <Select
                                name="category_domain"
                                value={partnerById?.category_domain}
                                // onChange={handleChange}
                                placeholder="Select"
                                autoWidth
                                required
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                }}
                                >
                                    {domainCatData && domainCatData?.map((item, index) => (
                                        <MenuItem value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                    <div className={classes.marginBot24} 
                        style={{width: '100%', marginLeft: '24px'}}>
                        <InputLabel className={classes.formInputLabel}>
                            How many colleges do you work with
                        </InputLabel>
                        <div className="form-two-inputs">
                            <Select
                                name="number_of_colleges"
                                value={partnerById?.number_of_colleges}
                                // onChange={handleChange}
                                placeholder="Select"
                                autoWidth
                                required
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                }}>
                                    {experienceData && experienceData?.map((item, index) => (
                                        <MenuItem value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                </div>
                {/* {formInformation?.colleges?.map((item, index) => (
                <div key={index}>
                    <div className={classes.marginBot24}>
                        <InputLabel className={classes.formInputLabel}>
                            Name of College {index+1}
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            name={`college-name-${index}`} 
                            onChange={(e) => handleCollegeInput(e, index)} 
                            value={item.name} 
                            required/>
                    </div>
                    <div className={classes.marginBot24}>
                        <InputLabel className={classes.formInputLabel}>
                            Full Address
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            name={`address-${index}`} 
                            onChange={(e) => handleCollegeInput(e, index)} 
                            value={item.address} 
                            required/>
                    </div>
                    <div className="tow-input-form">
                        <div className={`${classes.marginBot24} div-width`}>
                            <InputLabel className={classes.formInputLabel}>
                                City
                            </InputLabel>
                            <div className="form-two-inputs">
                                <Select
                                    // labelId="demo-simple-select-autowidth-label"
                                    // id="demo-simple-select-autowidth"
                                    name={`city-${index}`}
                                    value={item.city}
                                    onChange={(e) => handleCollegeInput(e, index)}
                                    placeholder="Select"
                                    autoWidth
                                    required
                                    sx={{
                                        width: '100%',
                                        height: '56px',
                                        position: 'relative',
                                    }}
                                    >
                                        {cityList && cityList?.map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                        <div className={`${classes.marginBot24} div-width`}>
                            <InputLabel className={classes.formInputLabel}>
                                Country
                            </InputLabel>
                            <div className="form-two-inputs">
                                <Select
                                    // labelId="demo-simple-select-autowidth-label"
                                    // id="demo-simple-select-autowidth"
                                    name={`country-${index}`}
                                    value={item.country}
                                    onChange={(e) => handleCollegeInput(e, index)}
                                    placeholder="Select"
                                    autoWidth
                                    required
                                    sx={{
                                        width: '100%',
                                        height: '56px',
                                        position: 'relative',
                                    }}
                                    >
                                        {countryList && countryList?.map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>))} */}
                <div className={classes.marginBot24}>
                    <InputLabel className={classes.formInputLabel}>
                        Email ID
                    </InputLabel>
                    <div style={{
                            position: 'relative',
                        }}>
                        <TextField 
                            placeholder="" 
                            type="email" 
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            // disabled={partnerByToken?.data?.email}
                            name="email" 
                            // onChange={handleChange} 
                            value={partnerById?.email} 
                            required/>
                    </div>
                </div>
                {partnerById?.documents &&
                <div className={classes.marginBot24}>
                    <InputLabel className={classes.formInputLabel}>
                        GST Number
                    </InputLabel>
                    <div style={{
                            position: 'relative',
                        }}>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            name="email" 
                            // onChange={handleChange} 
                            value={partnerById?.documents?.gst_number} 
                            required/>
                    </div>
                </div>}
                <InputLabel className={classes.formInputLabel}>
                    Proof Of Work
                </InputLabel>
                {partnerById?.proof_of_work?.map((item, index) => (
                    <div key={index} style={{
                        marginBottom: '8px'
                    }}>
                        {item.split(".")[4] === "pdf" ?
                        <a href={item} target="_blank">
                            {item?.split("-")[3]}
                        </a> :
                        <a href={item} target="_blank">
                            <img src={item} style={{
                                width: '264px',
                                height: '148px',
                                borderRadius: '8px'
                            }} />
                        </a>
                        }
                    </div>
                ))}
            </form>
            {partnerById?.documents && 
            <div style={{
                marginLeft: '24px'
            }}>
                <InputLabel className={classes.formInputLabel}>
                    Documents
                </InputLabel>
                {partnerById?.documents?.id_proof &&
                <div style={{
                    display: 'inline-block',
                    margin: '0px 24px 24px 0px'
                }}>
                    <InputLabel className={classes.formInputLabel}>
                        1. ID Proof
                    </InputLabel>
                    <a href={partnerById?.documents?.id_proof} target="_blank">
                        <img 
                            src={partnerById?.documents?.id_proof} 
                            style={{
                                width: '264px',
                                height: '148px',
                                borderRadius: '8px'
                            }}
                            />
                    </a>
                </div>}
                {partnerById?.documents?.address_proof &&
                <div style={{
                    display: 'inline-block',
                    margin: '0px 24px 24px 0px'
                }}>
                    <InputLabel className={classes.formInputLabel}>
                        2. Address Proof
                    </InputLabel>
                    <a href={partnerById?.documents?.address_proof} target="_blank">
                        <img 
                            src={partnerById?.documents?.address_proof} 
                            style={{
                                width: '264px',
                                height: '148px',
                                borderRadius: '8px'
                            }}
                            />
                    </a>
                </div>}
                {partnerById?.documents?.pan &&
                <div style={{
                    display: 'inline-block',
                    margin: '0px 24px 24px 0px'
                }}>
                    <InputLabel className={classes.formInputLabel}>
                        3. PAN Card
                    </InputLabel>
                    <a href={partnerById?.documents?.pan} target="_blank">
                        <img 
                            src={partnerById?.documents?.pan} 
                            style={{
                                width: '264px',
                                height: '148px',
                                borderRadius: '8px'
                            }}
                            />
                    </a>
                </div>}
                {partnerById?.documents?.business &&
                <div style={{
                    display: 'inline-block',
                    margin: '0px 24px 24px 0px'
                }}>
                    <InputLabel className={classes.formInputLabel}>
                        4. Company or business document
                    </InputLabel>
                    <a href={partnerById?.documents?.business} target="_blank">
                        <img 
                            src={partnerById?.documents?.business} 
                            style={{
                                width: '264px',
                                height: '148px',
                                borderRadius: '8px'
                            }}
                            />
                    </a>
                </div>}
                {partnerById?.documents?.contract_copy &&
                <div style={{
                    display: 'inline-block',
                    margin: '0px 24px 24px 0px'
                }}>
                    <InputLabel className={classes.formInputLabel}>
                        5. Copy of contract with university
                    </InputLabel>
                    <a href={partnerById?.documents?.contract_copy} target="_blank">
                        <img 
                            src={partnerById?.documents?.contract_copy} 
                            style={{
                                width: '264px',
                                height: '148px',
                                borderRadius: '8px'
                            }}
                            />
                    </a>
                </div>}
            </div>}
        </div>
        <div>
            <p>COLLEGES</p>
            <div>
                {partnerById?.colleges?.map((item, index) => (
                    <div key={index} style={{
                        display: 'inline-block',
                        width: '49%',
                        marginLeft: index%2 !== 0 ? '24px' : 0
                    }}>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Name of College {index+1}
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                name={`college-name-${index}`} 
                                // onChange={(e) => handleCollegeInput(e, index)} 
                                value={item.name} 
                                required/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Full Address
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }}
                                name={`address-${index}`} 
                                // onChange={(e) => handleCollegeInput(e, index)} 
                                value={item.address} 
                                required/>
                        </div>
                        <div className="tow-input-form">
                            <div className={classes.marginBot24} style={{
                                width: '100%'
                            }}>
                                <InputLabel className={classes.formInputLabel}>
                                    City
                                </InputLabel>
                                <div className="form-two-inputs">
                                    <Select
                                        // labelId="demo-simple-select-autowidth-label"
                                        // id="demo-simple-select-autowidth"
                                        name={`city-${index}`}
                                        value={item.city}
                                        // onChange={(e) => handleCollegeInput(e, index)}
                                        placeholder="Select"
                                        autoWidth
                                        required
                                        sx={{
                                            width: '100%',
                                            height: '56px',
                                            position: 'relative',
                                        }}
                                        >
                                            {cityList && cityList?.map((item, index) => (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                            <div className={classes.marginBot24} style={{
                                width: '100%',
                                marginLeft: '24px'
                            }}>
                                <InputLabel className={classes.formInputLabel}>
                                    Country
                                </InputLabel>
                                <div className="form-two-inputs">
                                    <Select
                                        name={`country-${index}`}
                                        value={item.country}
                                        // onChange={(e) => handleCollegeInput(e, index)}
                                        placeholder="Select"
                                        autoWidth
                                        required
                                        sx={{
                                            width: '100%',
                                            height: '56px',
                                            position: 'relative',
                                        }}
                                        >
                                            {countryList && countryList?.map((item, index) => (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
  );
};

export default Details;
