import axios from "axios";
import endpoint from "../../index.routes";

const getLeads = async (params) => {
    const { limit, offset, searchText, status } = params;
    const response = await axios.get(endpoint.LEAD,
      {
        params: searchText !== "" ? 
            { limit, offset, searchText, request_status: status } : 
            { limit, offset, request_status: status },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    return response.data;
};
const getLeadById = async (params) => {
  const response = await axios.get(endpoint.LEAD + "/" + params?.id,
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const leadStatusUpdate = async (params) => {
  let id = params.id;
  let body = {}
  if(params.from === "reject"){
    body = {
        request_status: params.status,
        reject_reason: params.reject_reason
      }
  }else{
    body = {
        request_status: params.status,
    }
  }
  
  const response = await axios.put(endpoint.LEAD + "/" + id, body,
    {
      headers: { token: localStorage.getItem("hp_token") }
    }
  );
  return response.data;
}

const leadServices = { 
    getLeads,
    getLeadById,
    leadStatusUpdate
};

export default leadServices;
