import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  Typography, 
  InputLabel,
  TextField } from "@mui/material";
import routes from "../../react_routes_enum";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LoaderIcon from "../../components/loader/Loader";
import { makeStyles } from "@mui/styles";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { validateUrl } from "../../utils/validateUrl";
import { 
    updateData, 
    getUniversityByToken } from "../../redux/universities/universitySlice";
import { getCoachingByToken, updateCoachingData } from "../../redux/coaching/coachingSlice";
import UploadBox from "../../components/UploadBox/UploadBox";
import { toast } from "react-toastify";
import { isEmpty } from "../../utils/isObjectEmpty";
const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    topHeader: {
        fontSize: '34px',
        fontWeight: 'bold'
    },
    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    horizontalLine: {
        width: '200px',
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        height: '52px',
        width: '52px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '800px'
    },
    icon: {
        color: '#fff',
    },
    iconText: {
        color: 'var(--primary-color)',
        fontSize: '18px !important',
        marginTop: '8px !important',
        fontWeight: 'bold',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
        top: -24
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    table: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    },
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
}))

let uploadData = {
    uploads_check: 1,
    uploads: {
        university_logo: "",
        introductory_video: "",
        photo_gallery: [""]
    },
}

let uploadData2 = {
    uploads_check: 1,
    uploads: {
        coaching_logo: "",
        introductory_video: "",
        photo_gallery: [""]
    },
}

const Listing = () => {
  const { 
    type,
    universityByToken,
    universityById } = useSelector(
    (state) => state.university
    );
  const { 
        coachingByToken,
        coachingById } = useSelector(
        (state) => state.coaching
        );
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [uploadInfo, setUploadInfo] = React.useState(
    type === "University" ? uploadData : uploadData2);
  const [gallaryPhotoNumber, setGallaryPhotoNumber] = React.useState(['']);
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  
    const saveAndNext = () => {
        if((universityById?.uploads_check || coachingById?.uploads_check) && !isEdit){
            navigate(routes.COURSE);
        }else{
            let body = {};
            if((!uploadInfo.uploads.university_logo || 
                uploadInfo.uploads.university_logo === "") &&
                (uploadInfo.uploads.coaching_logo === "" ||
                !uploadInfo.uploads.coaching_logo)){
                return toast.error("Logo required");
            }
            if(uploadInfo.uploads.photo_gallery.length === 0 || 
                uploadInfo.uploads.photo_gallery[0] === ""){
                return toast.error("Upload atleast 1 gallery photo");
            }
            if(uploadInfo.uploads.introductory_video && 
                uploadInfo.uploads.introductory_video !== ""){
                if(!validateUrl(uploadInfo.uploads.introductory_video)){
                    return toast.error("Invalid video url");
                }
            }
            if(type === "Coaching"){
                uploadInfo.uploads.introductory_video !== "" ?
                body = {
                    uploads_check: 1,
                    uploads: {
                        coaching_logo: uploadInfo.uploads.coaching_logo,
                        introductory_video: uploadInfo.uploads.introductory_video,
                        photo_gallery: uploadInfo.uploads.photo_gallery
                    }
                }:
                body = {
                    uploads_check: 1,
                    uploads: {
                        coaching_logo: uploadInfo.uploads.coaching_logo,
                        photo_gallery: uploadInfo.uploads.photo_gallery
                    }
                }

            }
            else{
                uploadInfo.uploads.introductory_video !== "" ? 
                body = {
                    uploads_check: 1,
                    uploads: {
                        university_logo: uploadInfo.uploads.university_logo,
                        introductory_video: uploadInfo.uploads.introductory_video,
                        photo_gallery: uploadInfo.uploads.photo_gallery
                    }
                }:
                body = {
                    uploads_check: 1,
                    uploads: {
                        university_logo: uploadInfo.uploads.university_logo,
                        photo_gallery: uploadInfo.uploads.photo_gallery
                    }
                }
            }
            setBtnLoader(true);
            let res = (type === "University" ? 
            dispatch(updateData({body, 
                id: universityById?._id ? universityById?._id : 
                universityByToken?.data?._id})) : 
            dispatch(updateCoachingData({body, 
                id: coachingById?._id ? coachingById?._id : 
                coachingByToken?.data?._id})));
            res.then((r) => {
                setBtnLoader(false);
                if(r.payload.statusCode === 200){
                    toast.success(r?.payload?.message);
                    if(type === "University"){
                        dispatch(getUniversityByToken());
                        navigate(routes.COURSE);
                    }else{
                        dispatch(getCoachingByToken());
                        navigate(routes.COACHING_COURSE);
                    }
                }else{
                    if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                        r?.payload?.message === "Session Expired"){
                        toast.error("Session expired, please login again");
                        localStorage.removeItem("hp_token");
                        localStorage.removeItem("hp_user");
                        navigate(routes.LOGIN);
                        return;
                    }
                    toast.error(r?.payload?.message);
                }
            })
        }
    }
    const addMore = (i) => {
        if(gallaryPhotoNumber.length < 5){
            let array = [...gallaryPhotoNumber];
            array.push('')
            setGallaryPhotoNumber([...array]);
        }else{
            toast.error("Maximum 5 photo allowed")
        }
    }

    const handleImageUploads = (name, value) => {
        if(name === "logo-upload"){
            type === "Coaching" ? setUploadInfo({...uploadInfo, uploads: {...uploadInfo.uploads, coaching_logo: value}}) : 
                setUploadInfo({...uploadInfo, uploads: {...uploadInfo.uploads, university_logo: value}})
        }else{
            let array = gallaryPhotoNumber;
            array[parseInt(name.split("-")[2])] = value;
            setGallaryPhotoNumber([...array]);
            setUploadInfo({...uploadInfo, uploads: {...uploadInfo.uploads, photo_gallery: array}});
        }
    }
    useEffect(() => {
        if(!isEmpty(universityById?.uploads)){
            setUploadInfo({...uploadInfo, uploads: universityById?.uploads});
            setGallaryPhotoNumber(
                universityById?.uploads?.photo_gallery?.length > 0 ?
                universityById?.uploads?.photo_gallery :
                ['']
                )
        }
        if(!isEmpty(coachingById?.uploads)){
            setUploadInfo({...uploadInfo, uploads: coachingById?.uploads});
            setGallaryPhotoNumber(
                coachingById?.uploads?.photo_gallery?.length > 0 ?
                coachingById?.uploads?.photo_gallery :
                ['']
                )
        }
    },[])
    return (
        <>
        <div style={{
            marginTop: '3%'
        }}>
            <div className={classes.topHeading}>
                <div></div>
                <Typography component="h2" variant="h2" 
                    className={classes.topHeader}>Details</Typography>
                <div>
                    <Button sx={{
                        backgroundColor: 'var(--primary-color)',
                        width: '130px'
                    }} variant="contained" onClick={saveAndNext} disabled={btnLoader}>
                        {btnLoader ? <LoaderIcon />  : 
                        (((universityById?.uploads_check || coachingById?.uploads_check) 
                            && !isEdit) ? 
                            'Next' :'Save & Next')
                        }
                    </Button>
                    {(universityById?.uploads_check || 
                        coachingById?.uploads_check) &&
                    <Button variant="outlined" 
                    onClick={() => setIsEdit(!isEdit)} sx={{
                        color: 'var(--primary-color)',
                        marginLeft: '8px'
                        }}>
                        {isEdit ? 'Cancel' : 'Edit'}
                    </Button>}
                </div>
            </div>
            <div className={classes.formIconsBox}>
                <div className={classes.iconBox}>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine}></div>
                        <div className={classes.iconBox2} style={{left: 72}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    }}>
                                <BorderColorIcon fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText}>
                                Create
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 48}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    fontSize: '24px',
                                    backgroundColor: 'var(--primary-color)'
                                    }}>
                                i
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Information
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--primary-color)'}}>
                                <CameraAltIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Uploads
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <MenuBookIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'} }>
                                {type === "University" ? 'Courses' : 'Faculties'}
                            </Typography>
                        </div>
                    </div>
                    {type === "University" &&
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 24}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <BusinessCenterIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                Placement Records
                            </Typography>
                        </div>
                    </div>}
                </div>
            </div>
            <div style={{
                marginTop: '10%',
                display: 'flex',
            }}>
                <div style={{
                    width: '48%',
                    marginRight: '2%'
                }}>
                    <div>
                        <Typography component="h2" variant="h2" 
                            sx={{
                                color: '#000',
                                fontSize: '20px'
                            }}>
                                </Typography>
                        <InputLabel className={classes.formInputLabel}>
                            {type === 'University' ? '1. Upload University Logo' : '1. Upload Coaching Logo'}
                        </InputLabel>
                        
                        <div style={{
                            margin: '5% 0 5% 12%'
                        }}>
                            <UploadBox
                                name="logo-upload"
                                value={type === "Coaching" ? uploadInfo?.uploads?.coaching_logo : 
                                    uploadInfo?.uploads?.university_logo}
                                handleUpload={(name, value) => handleImageUploads(name, value)} 
                            />
                        </div>
                    </div>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            {type === 'University' ? <span>
                                2. Upload University Introductory / Promotional video <span className="optional-text-style">(Optional)</span>
                            </span> : 
                            <span>
                                2. Upload Coaching Introductory / Promotional video <span className="optional-text-style">(Optional)</span>
                            </span>}
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            onChange={(e) => 
                                setUploadInfo({
                                    ...uploadInfo, 
                                    uploads: {
                                        ...uploadInfo.uploads, 
                                        introductory_video: e.target.value
                                    }})
                            } 
                            value={uploadInfo?.uploads?.introductory_video}
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '62px',
                            }} />
                    </div>
                </div>
                <div style={{
                    width: '68%',
                }}>
                    <InputLabel className={classes.formInputLabel}>
                        3. Upload photo-gallery of your institute
                    </InputLabel> 
                    <div style={{
                        display: 'inline-block',
                        width: '100%',
                    }}>
                        {gallaryPhotoNumber?.map((item, index) => (
                            <div key={index} style={{
                                margin: '8px 24px 8px 20px',
                                display: 'inline-block'
                            }}>
                                <UploadBox
                                    name={`gallary-upload-${index}`}
                                    value={item}
                                    handleUpload={(name, value) => handleImageUploads(name, value)} 
                                />
                            </div>
                        ))}
                        <div className="add-more-btn" style={{
                            display: 'inline-block',
                        }}>
                            <Button className={classes.addMore} 
                                sx={{backgroundColor: type === "coaching" ?
                                    'var(--tertiary-color)' : 'var(--primary-color)'}}
                                onClick={() => addMore(uploadInfo?.uploads?.photo_gallery.length)} 
                                variant="contained">
                                + Add More
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Listing;
