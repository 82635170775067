import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  IconButton, 
  Typography } from "@mui/material";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import MealTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Spinner from "../../components/Spinner/Spinner";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from "react-toastify";
import { 
  getUniversities, 
  setUniversityById,
  universityStatusUpdate 
} from "../../redux/universities/universitySlice";
import { 
  getCoaching, 
  coachingStatusUpdate
} from "../../redux/coaching/coachingSlice";

const Rejected = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [instId, setInstId] = useState("");
  const [actionFrom, setActionFrom] = useState("");
  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    universityData 
  } = useSelector((state) => state.university);
  const { type } = useSelector(
    (state) => state.university
  );
  const { 
    isCLoading, 
    isCError, 
    coachingData } = useSelector((state) => state.coaching);
  const colR = ["SNo", "Name of University", "Name of Chairperson", "Phone Number", "Email ID", "Manager Name", "action"];
  const colR2 = ["SNo", "Name of Owner", "Name of Coaching Center", "Phone Number", "Email ID", "action"];

  useEffect(() => {
    let res = null;
    if(type === "University") (res = dispatch(getUniversities({ limit, offset, searchText: "", request_status: "rejected" })));
    if(type === "Coaching") (res = dispatch(getCoaching({ limit, offset, searchText: "", request_status: "rejected" })));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset, type]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      let res = null;
      type === "University" ? (res = dispatch(getUniversities({ limit, offset, searchText, request_status: "rejected" }))) :
        (res = dispatch(getCoaching({ limit, offset, searchText, request_status: "rejected" })))
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  if (isLoading || isCLoading) return <Spinner />;
  if (isError || isCError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const unBlock = () => {
    if(type === "University"){
      const res = dispatch(universityStatusUpdate({id: instId, from: actionFrom, request_status: 'pending'}));
        res.then((r) => {
          if(r.payload.statusCode === 200){
            toast.success("University Sent In Request");
            setIsDialogOpen(false);
            dispatch(getUniversities({ limit, offset, searchText: "", request_status: "rejected" }));
          }else{
            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
              r?.payload?.message === "Session Expired"){
              toast.error("Session expired, please login again");
              localStorage.removeItem("hp_token");
              localStorage.removeItem("hp_user");
              navigate(routes.LOGIN);
              return;
            }
            toast.error(r?.payload?.message);
          }
      })
    }else{
      const res = dispatch(coachingStatusUpdate({id: instId, from: actionFrom, request_status: 'pending'}));
        res.then((r) => {
          if(r.payload.statusCode === 200){
            toast.success("Coaching Sent In Request");
            setIsDialogOpen(false);
            dispatch(getCoaching({ limit, offset, searchText: "", request_status: "rejected" }));
          }else{
            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
              r?.payload?.message === "Session Expired"){
              toast.error("Session expired, please login again");
              localStorage.removeItem("hp_token");
              localStorage.removeItem("hp_user");
              navigate(routes.LOGIN);
              return;
            }
            toast.error(r?.payload?.message);
          }
      })
    }
  }
  return (
    <>
      <Dialog maxWidth="xs" open={isDialogOpen} onClose={handleClose} fullWidth>
        <div>
          <Typography component="p" variant="p" 
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              margin: '28px 0 48px'}}>
            Are you sure you want to<br/> unblock the {type} ?
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: '0px 16px 28px 16px'
          }}>
            <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
              textTransform: 'capitalize'
            }}>
              Cancel
            </Button>
            <Button onClick={() => {unBlock()}} variant="contained" sx={{
              backgroundColor: 'var(--primary-color)',
              width: '84px',
              marginRight: '12px',
              textTransform: 'capitalize'
            }}>
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="meal"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <MealTable
          columns={type === "University" ? colR : colR2}
          where="recipes"
          rows={type === "University" ? (universityData && universityData?.map((item) => {
            const res = {
              ...item,
              action: (
                <div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => {
                        dispatch(setUniversityById(item._id));
                      }}
                    >
                      <Link to={routes.DETAILS} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                      </Link>
                    </IconButton>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '4px'
                      }}
                      onClick={() => {
                        setIsDialogOpen(true)
                        setInstId(item._id);
                        setActionFrom("unblock")
                      }}
                    >
                      <DoDisturbIcon sx={{color: '#EF0000'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#EF0000',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '8px',
                          }}>
                        Unblock
                      </Typography>
                    </IconButton>
                  </div>
                </div>
              )
            };
            return res;
          })): 
          (coachingData && coachingData?.map((item) => {
            const res = {
              ...item,
              action: (
                <div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => {
                        dispatch(setUniversityById(item._id));
                      }}
                    >
                      <Link to={routes.DETAILS} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                      </Link>
                    </IconButton>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '4px'
                      }}
                      onClick={() => {
                        setIsDialogOpen(true)
                        setInstId(item._id);
                        setActionFrom("unblock")
                      }}
                    >
                      <DoDisturbIcon sx={{color: '#EF0000'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#EF0000',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '8px',
                          }}>
                        Unblock
                      </Typography>
                    </IconButton>
                  </div>
                </div>
              )
            };
            return res;
          }))
        }
        />
      </div>
    </>
  );
};

export default Rejected;
