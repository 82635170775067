import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  Typography, 
  InputLabel,
  Select,
  MenuItem,
  TextField } from "@mui/material";
import routes from "../../react_routes_enum";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import validator from "validator";
import JoditEditor from 'jodit-react';
import LoaderIcon from "../../components/loader/Loader";
import { 
    updateData, 
    getUniversityByToken,
    universityStatusUpdate } from "../../redux/universities/universitySlice";
import { getCoachingByToken, 
    updateCoachingData, 
    coachingStatusUpdate } from "../../redux/coaching/coachingSlice";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import { validateUrl } from "../../utils/validateUrl";
import countryListData from "../../data/countryList.json";
const indiaCityList = require("../../data/indiaCity.json");
const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    topHeader: {
        fontSize: '34px',
        fontWeight: 'bold'
    },
    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    horizontalLine: {
        width: '200px',
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        height: '52px',
        width: '52px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '800px'
    },
    icon: {
        color: '#fff',
    },
    iconText: {
        color: 'var(--primary-color)',
        fontSize: '18px !important',
        marginTop: '8px !important',
        fontWeight: 'bold',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
        top: -24
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    table: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    },
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    aboutInstitute: {
        textDecoration: 'underline',
    }
}))

let formData = {
    information_check: 1,
    information: {
        address: "",
        city: "",
        country: "",
        website_link: "",
        about: {
            college: "",
            infrastructure: "",
            why_join_us: ""
        },
        relationship_manager: {
            name: "",
            phone: "",
            email: ""
        },
        courses_count: 0,
        enrolled_students_count: 0,
        placement_percentage: 0
    }
}

var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

const Listing = () => {
  const editor = React.useRef(null);
  const [formInfo, setFormInfo] = useState(formData);
  const [btnLoader, setBtnLoader] = useState(false);
  const [countryList, setCountryList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [coachingCityList, setCoachingCityList] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { type, 
    universityByToken,
    universityById } = useSelector(
    (state) => state.university
  );

  const { 
    coachingById,
    coachingByToken } = useSelector(
    (state) => state.coaching
  );

  const saveAndNext = () => {
    if((universityById?.information || coachingById?.information) && !isEdit){
        navigate(routes.UPLOAD)
    }else{
        if(!validate().status){
            toast.error(validate().message);
        }else{
            let body = formInfo;
            if(type === "Coaching"){
                body =  {
                    information_check: formInfo?.information_check,
                    information: {
                        address: formInfo?.information?.address,
                        city: formInfo?.information?.city,
                        website_link: formInfo?.information?.website_link,
                        about: {
                            college: formInfo?.information?.about?.college,
                            infrastructure: formInfo?.information?.about?.infrastructure,
                            why_join_us: formInfo?.information?.about?.why_join_us
                        },
                        relationship_manager: {
                            name: formInfo?.information?.relationship_manager?.name,
                            phone: formInfo?.information?.relationship_manager?.phone,
                            email: formInfo?.information?.relationship_manager?.email
                        }
                    }
                }
            }
            setBtnLoader(true);
            let res = (type === "University" ? 
                dispatch(updateData({body, 
                    id: universityById?._id ? universityById?._id : 
                    universityByToken?.data?._id})) : 
                dispatch(updateCoachingData({body, id: 
                    coachingById?._id ? coachingById?._id : coachingByToken?.data?._id})));
            res.then((r) => {
                setBtnLoader(false);
                if(r.payload.statusCode === 200){
                    toast.success(r?.payload?.message);
                    if(type === "University"){
                        dispatch(getUniversityByToken());
                        dispatch(universityStatusUpdate({id: universityByToken?.data?._id, from: "approve", request_status: 'registration'}))
                        navigate(routes.UPLOAD)
                    }else{
                        dispatch(getCoachingByToken())
                        dispatch(coachingStatusUpdate({id: coachingByToken?.data?._id, from: "approve", request_status: 'registration'}))
                        navigate(routes.COACHING_UPLOAD)
                    }
                }else{
                    if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                        r?.payload?.message === "Session Expired"){
                        toast.error("Session expired, please login again");
                        localStorage.removeItem("hp_token");
                        localStorage.removeItem("hp_user");
                        navigate(routes.LOGIN);
                        return;
                    }
                    toast.error(r?.payload?.message);
                }
            })
        }
    }
  }

    const handleChange = (e) => {
    e.preventDefault();
    e.target.name === "university" && setFormInfo({...formInfo})
    e.target.name === "city" && setFormInfo({...formInfo, information: {...formInfo.information, city:e.target.value} })
    e.target.name === "country" && setFormInfo({...formInfo, information: {...formInfo.information, country:e.target.value} })
    e.target.name === "address" && setFormInfo({...formInfo, information: {...formInfo.information, address:e.target.value}})
    e.target.name === "website_link" && setFormInfo({...formInfo, information: {...formInfo.information, website_link:e.target.value}})
    e.target.name === "number_of_course" && setFormInfo({...formInfo, information: {
        ...formInfo.information, courses_count: e.target.value && parseInt(e.target.value)}})
    e.target.name === "student_enrolled" && setFormInfo({...formInfo, information: {
        ...formInfo.information, enrolled_students_count: e.target.value && parseInt(e.target.value)}})
    e.target.name === "placement_per" && setFormInfo({...formInfo, information: {
        ...formInfo.information, placement_percentage: e.target.value && parseInt(e.target.value)}})
    e.target.name === "manager" && setFormInfo({
        ...formInfo, information: {
            ...formInfo.information, 
            relationship_manager:{...formInfo.information.relationship_manager, name: e.target.value}}})
    e.target.name === "m_phone" && setFormInfo({
        ...formInfo, information: {
            ...formInfo.information, 
            relationship_manager:{...formInfo.information.relationship_manager, phone: e.target.value}}})
    e.target.name === "m_email" && setFormInfo({
        ...formInfo, information: {
            ...formInfo.information, 
            relationship_manager:{...formInfo.information.relationship_manager, email: e.target.value}}})
    }
    const validate = () => {
        let error = {status: true, message: ""}
        if(!formInfo.information.city || formInfo.information.city === ""){
            error.status = false;
            error.message = "City Required";
            return error;
        }
        if(formInfo.information.address === ""){
            error.status = false;
            error.message = "Address Required";
            return error;
        }
        if(type === "University"){
            if(!formInfo.information.country || formInfo.information.country === ""){
                error.status = false;
                error.message = "Country Required";
                return error;
            }
        }
        if(!formInfo?.information?.about?.college || formInfo?.information?.about?.college === "" || 
            formInfo?.information?.about?.college.length < 6){
            error.status = false;
            error.message = (!formInfo?.information?.about?.college || formInfo?.information?.about?.college === "") ? 
                "About Institute Required" : 
                "About institute must be greater than 6 characters";
            return error;
        }
        if(!formInfo?.information?.about?.infrastructure ||
            formInfo?.information?.about?.infrastructure === "" || 
            formInfo?.information?.about?.infrastructure?.length < 6){
            error.status = false;
            error.message = (!formInfo?.information?.about?.infrastructure || 
                formInfo?.information?.about?.infrastructure === "") ? 
                "Infrastructure Required" : 
                "Infrastructure must be greater than 6 characters";
            return error;
        }
        if(!formInfo?.information?.about?.why_join_us ||
            formInfo?.information?.about?.why_join_us === "" || 
            formInfo?.information?.about?.why_join_us?.length < 6){
            error.status = false;
            error.message = (!formInfo?.information?.about?.why_join_us || 
                formInfo?.information?.about?.why_join_us === "") ? 
                "Why Join Us Required" : 
                "Why join us must be greater than 6 characters";
            return error;
        }
        if(!formInfo?.information?.website_link || 
            formInfo?.information?.website_link === ""){
            error.status = false;
            error.message = "Website Link Required";
            return error;
        }
        if(!validateUrl(formInfo.information.website_link)){
            error.status = false;
            error.message = "Invalid Link Entered";
            return error;
        }
        if(!formInfo?.information?.relationship_manager?.name || 
            formInfo?.information?.relationship_manager?.name === ""){
            error.status = false;
            error.message = "Manager Name Required";
            return error;
        }
        if(formInfo.information.relationship_manager.name.length < 4 || 
                !nameValidator.test(formInfo.information.relationship_manager.name) ||
                    formInfo.information.relationship_manager.name.length > 20){
            formInfo.information.relationship_manager.name.length < 4 ? (error = {status: false, 
                message: "Manager name must be at least 4 characters"}) :
              (error = {status: false, message: "Invalid Manager Name"})
            formInfo.information.relationship_manager.name.length > 20 && (error = {status: false, 
                message: "Manager name must be less than 20 characters"}) 
            return error;
        }
        if(!formInfo?.information?.relationship_manager?.phone || 
            formInfo?.information?.relationship_manager?.phone === "" || 
            formInfo?.information?.relationship_manager?.phone?.length !== 10){
            error.status = false;
            error.message = (!formInfo?.information?.relationship_manager?.phone || 
                formInfo?.information?.relationship_manager?.phone === "") ? 
                "Phone Number Required" : 
                "Invalid Phone Number";
            return error;
        }
        if(!formInfo?.information?.relationship_manager?.email ||
            formInfo?.information?.relationship_manager?.email === ""){
            error.status = false;
            error.message = "Email Id Required";
            return error;
        }
        if(!validator.isEmail(formInfo.information.relationship_manager.email)){
            error.status = false;
            error.message = "Invalid Email";
            return error;
        }
        if(type === "University"){
            if(!formInfo?.information?.courses_count || formInfo?.information?.courses_count === ""){
                error.status = false;
                error.message = "Course Count Required";
                return error;
            }
            if(!formInfo?.information?.enrolled_students_count || formInfo?.information?.enrolled_students_count === ""){
                error.status = false;
                error.message = "Student Enrolled Required";
                return error;
            }
            if(!formInfo?.information?.placement_percentage || formInfo?.information?.placement_percentage === ""){
                error.status = false;
                error.message = "Placement Percent Required";
                return error;
            }
        }
        return error;
    }

    useEffect(() => {
        const res = (type === "Coaching" ? dispatch(getCoachingByToken()) : dispatch(getUniversityByToken()));
        res.then((r) => {
            if(r?.payload?.statusCode === 200){
                if(!universityById && !coachingById){
                    setFormInfo({...formInfo, 
                        information: {...formInfo.information, 
                            address: r?.payload?.data?.address}})
                }
            }
        })
        if(universityById){
            setFormInfo({...formInfo, 
                information: {...universityById.information, 
                    address: universityById?.address}})
        }
        if(coachingById){
            setFormInfo({...formInfo, 
                information: {...coachingById.information, 
                    address: coachingById?.address}})
        }
    },[])
    const textEditor = (text, richType) => {
        richType === "about_college" && setFormInfo({
            ...formInfo, information: {
            ...formInfo.information, 
            about:{...formInfo.information.about, college: text}}})
        richType === "infrastructure" && setFormInfo({
            ...formInfo, information: {
            ...formInfo.information, 
            about:{...formInfo.information.about, infrastructure: text}}})
        richType === "join_us" && setFormInfo({
            ...formInfo, information: {
            ...formInfo.information, 
            about:{...formInfo.information.about, why_join_us: text}}})
    }

    React.useEffect(() => {
        const res = dispatch(getConfigDetails());
        res.then((r) => {
            if(r?.payload?.data){
                r?.payload?.data?.map((item) => {
                    if(item?.configuration_type === "university_countries"){
                        // item?.data?.map((cntry) => {
                        //     if(formInfo?.information?.country && 
                        //         (cntry?.country === formInfo?.information?.country)){
                        //         setCityList(cntry?.city);
                        //     }
                        // })
                        setCountryList(item?.data);
                    }
                })
            }
        })
    }, [])
    
    return (
        <>
        <div style={{
            marginTop: '3%'
        }}>
            <div className={classes.topHeading}>
                <div></div>
                <Typography component="h2" variant="h2" 
                    className={classes.topHeader}>Details</Typography>
                <div>
                    <Button sx={{
                        backgroundColor: 'var(--primary-color)',
                        width: '130px'
                    }} variant="contained" onClick={saveAndNext} disabled={btnLoader}>
                        {btnLoader ? <LoaderIcon />  : 
                        (((universityById?.information || coachingById?.information) && !isEdit) ? 
                        'Next' : 'Save & Next')
                        }
                    </Button>
                    {(universityById?.information || coachingById?.information) && 
                    <Button 
                        onClick={() => setIsEdit(!isEdit)}
                        variant="outlined" 
                        sx={{
                            color: 'var(--primary-color)',
                            marginLeft: '8px'
                        }}>
                        {isEdit ? 'Cancel' : 'Edit'}
                    </Button>}
                </div>
            </div>
            <div className={classes.formIconsBox}>
                <div className={classes.iconBox}>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine}></div>
                        <div className={classes.iconBox2} style={{left: 72}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    fontSize: '32px'
                                    }}>
                                <BorderColorIcon fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText}>
                                Create
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 48}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    fontSize: '24px',
                                    backgroundColor: 'var(--primary-color)'
                                    }}>
                                i
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Information
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <CameraAltIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                Uploads
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <MenuBookIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                {type === "University" ? 'Courses' : 'Faculties'}
                            </Typography>
                        </div>
                    </div>
                    {type === "University" && 
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--disable-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 24}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--disable-color)'}}>
                                <BusinessCenterIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--disable-color)'}}>
                                Placement Records
                            </Typography>
                        </div>
                    </div>}
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10%'
            }}>
                <div style={{
                    width: "48%",
                }}>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        marginBottom: '4%'
                    }}>
                        <div style={{
                            width: '50%',
                            marginRight: '5%',
                        }}>
                            <InputLabel className={classes.formInputLabel}>
                                {type === 'University' ? 'Name of University' :'Name of Institute'}
                            </InputLabel>
                            <TextField 
                                placeholder="XYZ University" 
                                name="university" 
                                type="text" 
                                variant="outlined" 
                                value={type === 'University' ? 
                                    (universityByToken?.data?.university_name ||
                                        universityById?.university_name) : 
                                    (coachingByToken?.data?.coaching_name ||
                                    coachingById?.coaching_name)}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={handleChange} 
                                disabled={(universityByToken?.data?.university_name 
                                    || universityById?.university_name
                                    || coachingByToken?.data?.coaching_name ||
                                    coachingById?.coaching_name) && true}
                                required/>
                        </div>
                        <div style={{
                            width: '40%',
                        }}>
                            <InputLabel className={classes.formInputLabel}>City</InputLabel>
                            {/* <Select
                                required
                                name="city"
                                className="select-class"
                                value={formInfo?.information?.city}
                                onChange={handleChange}
                                placeholder="course"
                                autoWidth
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    position: 'relative',
                                    marginRight: '12px',
                                    border: 'none'
                                }}>
                                    {indiaCityList.map((item, index) => (
                                        <MenuItem 
                                            value={item?.name}
                                            key={index}>
                                            {item?.name}
                                        </MenuItem>
                                    ))} 
                            </Select> */}
                            <TextField 
                                placeholder=""
                                name="city"
                                type="text"
                                required
                                variant="outlined"
                                value={formInfo?.information?.city}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        marginBottom: '4%'
                        }}>
                        <div style={{
                            width: type === 'University' ? '50%' : '100%',
                            marginRight: '5%',
                        }}>
                            <InputLabel 
                                className={classes.formInputLabel}>
                                    Full Address / Google Map Link
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                name="address" 
                                type="text" 
                                variant="outlined" 
                                value={type === 'University' ? 
                                (universityByToken?.data?.address || universityById?.address) : 
                                coachingByToken?.data?.address || coachingById?.address}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={handleChange} 
                                disabled={(
                                    universityByToken?.data?.address || 
                                    universityById?.address ||
                                    coachingByToken?.data?.address ||
                                    coachingById?.address) && true}
                                required/>
                        </div>
                        {type === "University" &&
                            <div style={{
                                width: '40%',
                            }}>
                                <InputLabel className="input-label-text">
                                    Country
                                </InputLabel>
                                <Select
                                    required
                                    name="country"
                                    className="select-class"
                                    value={formInfo?.information?.country}
                                    onChange={handleChange}
                                    placeholder="course"
                                    autoWidth
                                    sx={{
                                        width: '100%',
                                        height: '56px',
                                        position: 'relative',
                                        marginRight: '12px',
                                        border: 'none'
                                    }}
                                    >
                                    {countryListData.map((item, index) => (
                                        <MenuItem 
                                            value={item?.country} 
                                            key={index}>
                                            {item?.country}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>}
                    </div>

                    <div className="info-description" style={{
                        marginRight: '5%'
                    }}>
                        <InputLabel className={`${classes.formInputLabel} ${classes.aboutInstitute}`}>About Institute</InputLabel>
                        <InputLabel className={classes.formInputLabel}>
                            {type === 'University' ? 'About College' : 'About Coaching'}
                        </InputLabel>
                        <div style={{width: '100%', marginBottom: '4%'}}>
                            <JoditEditor 
                                ref={editor}
                                value={formInfo.information.about?.college}
                                onChange={newText => textEditor(newText, "about_college")}
                            />
                        </div>
                        <InputLabel className={classes.formInputLabel}>Infrastructure</InputLabel>
                        <div style={{width: '100%', marginBottom: '4%'}}>
                            <JoditEditor 
                                ref={editor}
                                value={formInfo.information.about?.infrastructure}
                                onChange={newText => textEditor(newText, "infrastructure")}
                            />
                        </div>
                        <InputLabel className={classes.formInputLabel}>Why Join Us</InputLabel>
                        <div style={{width: '100%'}}>
                            <JoditEditor 
                                ref={editor}
                                value={formInfo.information.about?.why_join_us}
                                onChange={newText => textEditor(newText, "join_us")}
                            />
                        </div>
                    </div>
                </div>
                <div style={{
                    width: "48%",
                }}>
                    <div style={{
                        marginBottom: '4%'
                    }}>
                        <InputLabel className={classes.formInputLabel}>
                               {type === 'University' ? 'University Official Website Link' : 'Official Website Link'}
                        </InputLabel>
                        <TextField placeholder="" name="website_link" type="text" required variant="outlined" 
                            value={formInfo?.information?.website_link}
                            sx={{
                                width: '564px',
                                height: '62px'
                            }} onChange={handleChange} />
                    </div>
                    <div style={{
                        marginBottom: '4%'
                    }}>
                        <InputLabel className={classes.formInputLabel}>Relationship Manager Name</InputLabel>
                        <TextField placeholder="" name="manager" type="text" required variant="outlined" 
                            value={formInfo?.information?.relationship_manager?.name}
                            sx={{
                                width: '564px',
                                height: '62px'
                            }} onChange={handleChange} />
                    </div>
                    <div>
                        <InputLabel className={`${classes.formInputLabel} ${classes.aboutInstitute}`}>
                            Relationship Manager Contact Details
                        </InputLabel>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            marginBottom: '4%'
                        }}>
                            <div style={{
                                width: '40%',
                                marginRight: '5%',
                            }}>
                                <InputLabel className={classes.formInputLabel}>Phone Number</InputLabel>
                                <TextField placeholder="" name="m_phone" type="number" required variant="outlined" 
                                    value={formInfo?.information?.relationship_manager?.phone}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} onChange={handleChange} />
                            </div>
                            <div style={{
                                width: '40%',
                            }}>
                                <InputLabel className={classes.formInputLabel}>Email</InputLabel>
                                <TextField placeholder="" name="m_email" required variant="outlined" type="email" 
                                    value={formInfo?.information?.relationship_manager?.email}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} onChange={handleChange} />
                            </div>
                        </div>
                        {type === "University" && <div style={{
                            display: 'flex',
                            width: '100%',
                            marginBottom: '4%'
                        }}>
                            <div style={{
                                width: '40%',
                                marginRight: '5%',
                            }}>
                                <InputLabel className={classes.formInputLabel}>Number Of Course</InputLabel>
                                <TextField placeholder="" name="number_of_course" type="number" required variant="outlined" 
                                    value={formInfo?.information?.courses_count}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} onChange={handleChange} />
                            </div>
                            <div style={{
                                width: '40%',
                            }}>
                                <InputLabel className={classes.formInputLabel}>Number of Students Enrolled</InputLabel>
                                <TextField placeholder="" name="student_enrolled" required variant="outlined" type="number" 
                                    value={formInfo?.information?.enrolled_students_count}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} onChange={handleChange} />
                            </div>
                        </div>}
                        {type === "University" &&
                        <div style={{
                            width: '40%',
                            marginRight: '5%',
                        }}>
                            <InputLabel className={classes.formInputLabel}>Placement Percentage(%)</InputLabel>
                            <TextField placeholder="" name="placement_per" type="number" required variant="outlined" 
                                value={formInfo?.information?.placement_percentage}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} onChange={handleChange} />
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Listing;
