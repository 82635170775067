import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { 
  TextField,
  InputLabel,
  IconButton,
  Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import routes from "../../react_routes_enum";
import DownloadIcon from '@mui/icons-material/Download';
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import UserTable from "../../components/BasicTable/BasicTable";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";
import { 
  addSubAdmin, 
  getSubAdmin, 
  deleteSubAdmin,
  updateSubAdmin
} from "../../redux/sub-admin/subAdminSlice";
import { getContactUs } from "../../redux/students/studentsSlice";
import VisibilityIcon from '@mui/icons-material/Visibility';
import validator from "validator";

var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

let subadmin = {
  name: "",
  phone: "",
  email: "",
  designation: ""
}
const ContactUs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(0);
    const [subAdminInfo, SetSubAdminInfo] = useState(subadmin);
    const [isSubmitAdmin, setIsSubmitAdmin] = useState(false);
    const [contactInfo, setContactInfo] = useState({});
    const [userListView, setUserListView] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isViewDetails, setIsViewDetails] = useState(false);
    const [isEditDetails, setIsEditDetails] = useState(false);
    const [subAdminId, setSubAdminId] = useState("");
    
    const { 
        isError,
        message,
        isLoading,
        totalCount,
        searchText,
        contactUsData } = useSelector((state) => state.students)
  
    const columns = [
        "SNo",
        "name",
        "phone",
        "email",
        "Action",
    ];

    const resetStateForPaginationOfDifferentView = () => {
        setLimit(10);
        setOffset(0);
        setPage(0);
    };

    const handleSearchText = (e) => {
        resetStateForPaginationOfDifferentView();
        if (limit === 10 && offset === 0) {
        searchText !== "" ?
        dispatch(
            getSubAdmin({
            limit: limit,
            offset: offset,
            searchText,
            })):
        dispatch(
            getSubAdmin({
            limit: limit,
            offset: offset,
            }))
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        const res = dispatch(getContactUs({limit, offset}));
        res?.then((r) => {
            if(r.payload.message === "unauthorized" || 
                r.payload.message === "Unauthorized" ||
                r.payload.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
            }
        })
    }, [limit, offset]);

    const inputValue = (e) => {
        e.target.name === "name" && SetSubAdminInfo({...subAdminInfo, name: e.target.value});
        e.target.name === "email" && SetSubAdminInfo({...subAdminInfo, email: e.target.value});
        e.target.name === "phone" && SetSubAdminInfo({...subAdminInfo, phone: e.target.value});
        e.target.name === "designation" && SetSubAdminInfo({...subAdminInfo, designation: e.target.value})
    }

    const validate = () => {
        let error = {status: true, message: ""}
        if(subAdminInfo.name.length < 4 || !nameValidator.test(subAdminInfo.name) || subAdminInfo.name.length > 20){
        subAdminInfo.name.length < 4 ? (error = {status: false, message: "Name must be at least 4 characters"}) :
            (error = {status: false, message: "Invalid Name"})
        subAdminInfo.name.length > 20 && (error = {status: false, message: "Name must be less than 20 characters"})
        return error;
        }
        if(!validator.isEmail(subAdminInfo.email)){
        error.status = false;
        error.message = "Invalid Email";
        return error;
        }
        if(subAdminInfo.phone === "" || subAdminInfo.phone.length !== 10){
        error.status = false;
        error.message = "Invalid Phone Number";
        return error;
        }
        if(subAdminInfo.designation.length < 4 || !nameValidator.test(subAdminInfo.designation) || 
            subAdminInfo.designation.length > 20){
        subAdminInfo.designation.length < 4 ? (error = {status: false, message: "Designation must be at least 4 characters"}) :
            (error = {status: false, message: "Invalid designation"})
        subAdminInfo.designation.length > 20 && (error = {status: false, message: "Designation must be less than 20 characters"})
        return error;
        }
        return error;
    }

    if (isLoading) return <Spinner />;

    if (isError) return <h1 style={{ color: "red" }}>{message}</h1>;
  
    return (
        <div style={{
        marginTop: '5%'
        }}>
        <Dialog maxWidth="sm" open={isDialogOpen} onClose={handleClose} fullWidth>
            <form style={{padding: '0 5% 2% 5%'}}>
            <DialogContent>
                <h3 style={{
                fontSize: '28px',
                textAlign: 'center',
                marginBottom: '0px'
                }}>
                    Detail
                </h3>
                <div style={{
                height: '20px',
                width: '100%'
                }}></div>
                {contactInfo?.name &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Name
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.name} 
                            type="text" 
                            name="name" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.email &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Email
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.email} 
                            type="email" 
                            name="email" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.phone &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Phone Number
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.phone} 
                            type="number" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.message &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Message
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.message} 
                            type="text" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.company_name &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Company Name
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.company_name} 
                            type="text" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.company_website &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Company Website
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.company_website} 
                            type="text" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.office_location &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Company Location
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.office_location} 
                            type="text" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.id_proof &&
                <>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginBottom: '4px'
                        }}>
                            Download Firm Document: 
                        </InputLabel>
                        <a href={contactInfo?.firm_document} target="_blank">
                            <DownloadIcon sx={{color: '#000'}} />
                        </a>
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.id_proof &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Id Proof
                        </InputLabel>
                        <a href={contactInfo?.id_proof} target="_blank">
                            <img 
                                src={contactInfo?.id_proof}
                                height="100"
                                width="100" 
                                />
                        </a>
                    </div>
                </>
                }
            </DialogContent>
            </form>
        </Dialog>
        {userListView && (
            <>
            {/* <SearchTextField
                searchText={searchText}
                handleSearchText={handleSearchText}
                fromWhere="subscribedUsers"
            /> */}
            <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />
            <UserTable
                columns={columns}
                rows={
                    contactUsData &&
                    contactUsData?.map((item) => {
                    const res = {
                    ...item,
                    Action: (
                        <IconButton
                        color="inherit"
                        edge="start"
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        }}
                        onClick={() => {
                            setIsDialogOpen(true)
                            setContactInfo(item)}}>
                        <VisibilityIcon sx={{color: '#3C1CDE'}} />
                        <Typography component="p" variant="p" 
                        sx={{
                            color: '#3C1CDE',
                            fontSize: '18px',
                            fontWeight: 'bold'
                            }}>
                        View
                        </Typography>
                    </IconButton>
                    )
                    };
                    return res;
                })
                }
            />
            </>
        )}
        </div>
    );
};

export default ContactUs;
