import axios from "axios";
import endpoint from "../index.routes";

const getImageUrls = async (params) => {
  
  const { image, setLoading, setError } = params;
  // console.log("service", params);
  setLoading(true);
  // console.log(type image)
  try {
    const formData = new FormData();

    if (Array.isArray(image)) {
      console.log("array");
      image.map((img) => formData.append("image", img));
    } else {
      console.log("string");
      formData.append("image", image);
      formData.append("image", image.gif);
    }

    // formData.append("gif", uploadImages.gif);
    const response = await axios({
      method: "post",
      url: endpoint.UPLOAD_ON_S3,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    setLoading(false);
    return response.data;
  } catch (error) {
    console.log(error);
    setLoading(false);
    setError(true);
  }
};

export default getImageUrls;
