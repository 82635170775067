import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import universityServices from "./universityServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  universityData: null,
  totalCount: 0,
  isLoadingforSearch: false,
  isErrorforSearch: false,
  messageforSearch: false,
  searchedSubAdmin: null,
  totalSearchedSubAdmin: null,
  searchText: "",
  universityById: null,
  verifyResponse: null,
  universityId: null,
  type: "",
  universityByToken: null,
  newPostResponse: null,
  updateUnivResponse: null,
};

export const postNewFormRequest = createAsyncThunk(
  "signup/postSignUpRequest",
  async (params, thunkAPI) => {
    try {
      return await universityServices.postSignUpRequest(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUniversities = createAsyncThunk(
    "universities/getUniversities",
    async (params, thunkAPI) => {
      try {
        return await universityServices.getUniversities(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const verify = createAsyncThunk(
  "verify/verify",
  async (params, thunkAPI) => {
    try {
      return await universityServices.verify(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addListing = createAsyncThunk(
  "universities/addListing",
  async (params, thunkAPI) => {
    try {
      return await universityServices.addListing(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUniversityById = createAsyncThunk(
    "universities/getUniversityById",
    async (params, thunkAPI) => {
      try {
        return await universityServices.getUniversityById(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateUniversity = createAsyncThunk(
  "university/updateUniversity",
  async (params, thunkAPI) => {
    try{
      return await universityServices.updateUniversity(params);
    }catch(error) {
      const message = 
        (error.response && 
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
          return thunkAPI.rejectWithValue(message);
    }
  }
)
export const universityStatusUpdate = createAsyncThunk(
  "universities/universityStatusUpdate",
  async (params, thunkAPI) => {
    try{
      return await universityServices.universityStatusUpdate(params);
    }catch(error){
      const message = 
        (error.response && 
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
          return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateData = createAsyncThunk(
  "update/updateData",
  async (params, thunkAPI) => {
    try{
      return await universityServices.updateData(params);
    }catch(error){
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const getUniversityByToken = createAsyncThunk(
  "university/getUniversityByToken",
  async (params, thunkAPI) => {
    try {
      return await universityServices.getUniversityByToken(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const universitySlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setUnversityFilterDetails: (state, action) => {
        state.searchText = action.payload;
    },
    setIsDropdown: (state, action) => {
      state.isDropdown = action.payload;
  },
    setUniversityById: (state, action) => {
        state.universityId = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setUniversityByIdData: (state, action) => {
      state.universityById = action.payload;
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUniversities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUniversities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUniversities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.universityData = action.payload.data.result;
      })
      .addCase(getUniversityById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUniversityById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUniversityById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.universityById = action.payload.data;
        state.message = action.payload.message;
      })
      .addCase(universityStatusUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(universityStatusUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(universityStatusUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.universityById = action.payload.data;
        state.message = action.payload.message;
      })
      .addCase(addListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addListing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addListing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.newPostResponse = action.payload;
      })
      .addCase(updateUniversity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUniversity.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUniversity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.newPostResponse = action.payload;
      })
      .addCase(verify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.verifyResponse = action.payload;
      })
      .addCase(postNewFormRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postNewFormRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(postNewFormRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.newPostResponse = action.payload;
      })
      .addCase(updateData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.updateUnivResponse = action.payload;
      })
      .addCase(getUniversityByToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUniversityByToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUniversityByToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.universityByToken = action.payload;
      })
  },
});

export const { 
    setUnversityFilterDetails, 
    setUniversityById,
    setUniversityByIdData,
    setIsDropdown,
    setType } = universitySlice.actions;

export default universitySlice.reducer;
