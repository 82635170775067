import axios from "axios";
import endpoint from "../../index.routes";

const addSubAdmin = async (params) => {
  const response = await axios.post(endpoint.SUBADMIN, params, {
    headers: { token: localStorage.getItem("hp_token") },
  });
  return response.data;
};

const getSubAdmin = async (params) => {
    const { limit, offset, searchText } = params;
    const response = await axios.get(
      endpoint.SUBADMIN,
      {
        params: { limit, offset, searchText },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    return response.data;
};

const searchSubAdmin = async (params) => {
    const { searchText } = params;
    const response = await axios.get(
      endpoint.SUBADMIN,
      {
        params: { searchText },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    return response.data;
};

const deleteSubAdmin = async (params) => {
    const response = await axios.delete(
        `${endpoint.SUBADMIN}/${params}`,
        {
            headers: { token: localStorage.getItem("hp_token")},
        }
    );
    return response.data;
}

const updateSubAdmin = async(params) => {
    // console.log("Updating",params);
    const {id, name, designation} = params;
    console.log("Updating",id, name, designation);
    const response = await axios.put(
        `${endpoint.SUBADMIN}/${id}`,
        {
            name: name,
            designation: designation
        },
        {
            headers: { token: localStorage.getItem("hp_token")},
        }
    );
    return response.data;
}
const subAdminServices = { 
    addSubAdmin, 
    getSubAdmin, 
    searchSubAdmin,
    deleteSubAdmin,
    updateSubAdmin
};

export default subAdminServices;
