import * as React from 'react';
import { useSelector } from "react-redux";
import {
    Button,
    Input,
    InputLabel,
    Typography,
    TextField
} from "@mui/material";
import Select from '../Select/Select';
import { makeStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import UploadBox from '../UploadBox/UploadBox';
import JoditEditor from 'jodit-react';

const useStyle = makeStyles({
    marginBot24: {
        marginBottom: '24px',
    },
})

const coachingCat = ["Online", "Offline", "Both"];
const universityCat = ["Engineering", "Medical"];

const AddOns = (props) => {
    const editor = React.useRef(null);
    const { type } = useSelector(
        (state) => state.university
    );
    const classes = useStyle();
    return (
        <div>
            <Typography variant="h3" component="h3" sx={{
                color: type === "University" ? '#672F8F' : 'var(--tertiary-color)',
                fontSize: '24px',
                marginBottom: '8px',
                fontWeight: 'bold'
            }}>
                Addons
            </Typography>
            <div className={[`${classes.marginBot24} form-part-1`]}>
                <div className='four-input-box margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Product Type</InputLabel>
                    <Select 
                        name={`add_on_p_type_${props.index}`}
                        value={props.item?.product_type}
                        option={props.type} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>
                <div className='four-input-box category-input margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Product name</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        value={props.item?.name} 
                        variant="outlined" 
                        name={`add_on_product_name_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
                <div className='four-input-box author-box'>
                    <InputLabel className={classes.formInputLabel}>Author name</InputLabel>
                    {console.log(props.item?.product_type)}
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        disabled={!props.item?.product_type?.includes("Books")}
                        value={props.item?.author_name}
                        name={`add_on_author_name_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
                <div className='four-input-box addons-price'>
                    <InputLabel className={classes.formInputLabel}>Price</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="number" 
                        variant="outlined" 
                        value={props.item?.price}
                        name={`add_on_price_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
            </div>
            <div className={[`${classes.marginBot24} description-box`]}>
                <div className="margin-bot-change semes-box">
                    <Typography component="h2" variant="h2" className="input-label-text">
                        Upload image <span className="optional-text-style">(Optional)</span>
                    </Typography>
                    <div className="product-upload-box">
                        <UploadBox
                            name={`add_on_img_${props.index}`}
                            height={'194px'}
                            width={'220px'}
                            value={props.item?.images_url[0]}
                            handleUpload={(name, value) => props.handleChange(name, value)} />
                    </div>
                </div>
                <div className="description-input-addons">
                    <InputLabel className={classes.formInputLabel}>Description</InputLabel>
                    <JoditEditor 
                        ref={editor}
                        value={props.item?.description}
                        onChange={newText => props.textEditor(newText)}
                    />
                    {/* <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        name={`add_on_desc_${props.index}`}
                        onChange={(e) => props.handleChange(e, props.index)} 
                        value={props.item?.description}
                        rows={6}
                        multiline
                        required/> */}
                </div>
                <div className="branch-course">
                    <div className="four-input-box margin-bot-change">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={props.item?.is_percentage_discount ? 'percentage' : 'flat'}
                            name={`add_on_per_${props.index}`}
                            onChange={(e) => props.handleChange(e, props.index)}
                        >
                            <FormControlLabel 
                                value="percentage" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Percentage Discount" />
                            <FormControlLabel 
                                value="flat" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Flat Discount" />
                        </RadioGroup>
                    </div>
                    <div className="four-input-box course-type-input margin-bot-change">
                        <InputLabel className={classes.formInputLabel}>
                            Offers <span className="optional-text-style">(Optional)</span>
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="number" 
                            variant="outlined" 
                            name={`add_on_offer_${props.index}`} 
                            value={props.item?.discount}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleChange(e, props.index)} 
                            required/>
                        
                    </div>
                </div>
            </div>
            {/* {type === "University" && 
            <div className={[`${classes.marginBot24} form-part-1`]}>
                <div className='four-input-box margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Course name</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        name={`add_ons_course_${props.index}`} 
                        value={props.item?.course_name}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
            </div>} */}
            <div className={[`${classes.marginBot24} form-part-1`]}>
                {type === "University" && 
                <div className='four-input-box margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Course name</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        name={`add_ons_course_${props.index}`} 
                        value={props.item?.course_name}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>}
                <div className={type === 'University' && 'payment-options'}>
                    <InputLabel className={classes.formInputLabel}>
                        How you want to do payment ?
                    </InputLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={props.item?.payment_procedure || props.item?.payment_mode}
                        name={`add_ons_product_payment_process_${props.index}`}
                        onChange={(e) => props.handleChange(e, props.index)}
                    >
                        <FormControlLabel 
                            value="direct_pay" 
                            control={<Radio sx={{
                                color: 'var(--secondary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--secondary-color)',
                                    },
                            }} />} 
                            label="Direct Pay" />
                        <FormControlLabel 
                            value="full_process" 
                            control={<Radio sx={{
                                color: 'var(--secondary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--secondary-color)',
                                    },
                            }} />} 
                            label="Follow Full Process" />
                    </RadioGroup>
                </div>
            </div>
        </div>
    )
}
export default AddOns