import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subAdminServices from "./subAdminServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  subAdminData: null,
  totalCount: 0,
  isLoadingforSearch: false,
  isErrorforSearch: false,
  messageforSearch: false,
  searchedSubAdmin: null,
  totalSearchedSubAdmin: null,
  searchText: ""
};

export const addSubAdmin = createAsyncThunk(
  "addSubAdmin",
  async (params, thunkAPI) => {
    try {
      return await subAdminServices.addSubAdmin(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSubAdmin = createAsyncThunk(
    "subadmin/getSubAdmin",
    async (params, thunkAPI) => {
      try {
        return await subAdminServices.getSubAdmin(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const searchSubAdmin = createAsyncThunk(
    "subadmin/searchSubAdmin",
    async (params, thunkAPI) => {
      try {
        return await subAdminServices.searchSubAdmin(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const deleteSubAdmin = createAsyncThunk(
    "deleteSubAdmin",
    async (params, thunkAPI) => {
      try {
        return await subAdminServices.deleteSubAdmin(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateSubAdmin = createAsyncThunk(
    "updateSubAdmin",
    async (params, thunkAPI) => {
      try {
        return await subAdminServices.updateSubAdmin(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

const subAdminSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setSubadminFilterDetails: (state, action) => {
        state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSubAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSubAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addSubAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.newPostResponse = action.payload;
      })
      .addCase(getSubAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSubAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subAdminData = action.payload.data.result;
        state.message = action.payload.message;
        state.totalCount = action.payload.data.totalCount;
      })
      .addCase(searchSubAdmin.pending, (state) => {
        state.isLoadingforSearch = true;
      })
      .addCase(searchSubAdmin.rejected, (state, action) => {
        state.isLoadingforSearch = false;
        state.isErrorforSearch = true;
        state.messageforSearch = action.payload;
      })
      .addCase(searchSubAdmin.fulfilled, (state, action) => {
        state.isLoadingforSearch = false;
        state.isSuccessforSearch = true;
        state.isErrorforSearch = false;
        console.log(state.searchedSubAdmin);
        console.log(state.totalSearchedSubAdmin);
        if (!state.searchedSubAdmin) {
          state.totalSearchedSubAdmin = { ...action.payload.data.result, qty: 1 };
        } else {
          state.totalSearchedSubAdmin = [
            ...state.searchedSubAdmin,
            ...action.payload.data.result,
          ];
        }
        state.searchedSubAdmin = action.payload.data.result;
      })
      .addCase(deleteSubAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSubAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteSubAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.newPostResponse = action.payload;
      })
      .addCase(updateSubAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSubAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateSubAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.newPostResponse = action.payload;
      })
  },
});

export const { setSubadminFilterDetails } = subAdminSlice.actions;

export default subAdminSlice.reducer;
