import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  IconButton, 
  Typography, 
  TextField } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import MealTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import Spinner from "../../components/Spinner/Spinner";
import NotesIcon from "../../assest/images/notes.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { 
  getUniversities, 
  universityStatusUpdate,
  setUniversityById
} from "../../redux/universities/universitySlice";
import { toast } from "react-toastify";
import LoaderIcon from "../../components/loader/Loader";
import { 
  getCoaching, 
  coachingStatusUpdate
} from "../../redux/coaching/coachingSlice";

let reviewBody = {
  id: "",
  request_status: "review",
  changes: [],
  information_check: 0,
  welcome_screen_check: 1,
  from: "review",
}

const Review = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    universityData
  } = useSelector((state) => state.university);
  const {
    isCLoading, 
    isCError,
    coachingData } = useSelector((state) => state.coaching);
  const { type } = useSelector(
    (state) => state.university
  );
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRejectModel, setIsRejectModel] = useState(false);
  const [isRequestModel, setIsRequestModel] = useState(false);
  const [reviewFormBody, setReviewFormBody] = useState(reviewBody);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isOnApprove, setIsOnApprove] = useState(false);
  const [instId, setInstId] = useState("");
  const [actionFrom, setActionFrom] = useState("");

  const colR = ["SNo", "Name of University", "city", "Full Address", "Website Link", "action"];
  const colR2 = ["SNo", "Name of Coaching Center", "city", "Full Address", "Website Link", "action"];

  useEffect(() => {
    let res = null;
    if(type === "University") (res = dispatch(getUniversities({ limit, offset, searchText: "", request_status: "review" })));
    if(type === "Coaching") (res = dispatch(getCoaching({ limit, offset, searchText: "", request_status: "review" })));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset, type]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      let res = null;
      type === "University" ? (res = dispatch(getUniversities({ limit, offset, searchText, request_status: "review" }))) :
        (res = dispatch(getCoaching({ limit, offset, searchText, request_status: "review" })))
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  if (isLoading || isCLoading) return <Spinner />;
  if (isError || isCError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const approveClicked = (item, from) => {
    let status = "";
    if(type === "University"){
      if(!item?.information_check && !item?.courses_offered_check && !item?.placement_records_check && 
        !item?.uploads_check){
          status = "registration"
        }
      if(!item?.information_check && item?.courses_offered_check && item?.placement_records_check && 
        item?.uploads_check){
          status = "under review"
        }
      if(item?.information_check && item?.courses_offered_check && item?.placement_records_check && 
        item?.uploads_check){
          status = "accepted"
        }
    }
    if(type === "Coaching"){
      if(!item?.information_check && !item?.uploads_check && !item?.faculties_details_check){
        status = "registration"
      }
      if(!item?.information_check && item?.uploads_check && item?.faculties_details_check){
        status = "under review"
      }
      if(item?.information_check && item?.uploads_check && item?.faculties_details_check){
        status = "accepted"
      }
    }
    if(status !== "under review"){
      if(type === "University"){
        let id = item?._id;
        const res = dispatch(universityStatusUpdate({id, from, request_status: status}));
          res.then((r) => {
            if(r.payload.statusCode === 200){
              toast.success(status === "registration" ? "University Sent For Registration" : "Universtiy Accepted");
              dispatch(getUniversities({ limit, offset, searchText: "", request_status: "review" }))
              setIsDialogOpen(false);
            }else{
              if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                r?.payload?.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
                return;
              }
              toast.error(r?.payload?.message);
            }
        })
      }else{
        setBtnLoader(true);
        let id = item?._id;
        const res = dispatch(coachingStatusUpdate({id, from, request_status: status}));
        res.then((r) => {
          setBtnLoader(false);
          if(r.payload.statusCode === 200){
            toast.success(status === "registration" ? "Coaching Sent For Registration" : "Coaching Accepted");
            dispatch(getCoaching({ limit, offset, searchText: "", request_status: "review" }))
            setIsDialogOpen(false);
          }else{
            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
              r?.payload?.message === "Session Expired"){
              toast.error("Session expired, please login again");
              localStorage.removeItem("hp_token");
              localStorage.removeItem("hp_user");
              navigate(routes.LOGIN);
              return;
            }
            toast.error(r?.payload?.message);
          }
        })
      }
    }else{
      toast.error("User does not make any change");
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if(type === "University"){
      setBtnLoader(true);
      const res = dispatch(universityStatusUpdate(reviewFormBody));
      res.then((r) => {
        setBtnLoader(false);
        if(r.payload.statusCode === 200){
          toast.success("University Change Request Submitted");
          dispatch(getUniversities({ limit, offset, searchText: "", request_status: "review" }))
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }else{
      setBtnLoader(true);
      const res = dispatch(coachingStatusUpdate(reviewFormBody));
      res.then((r) => {
        setBtnLoader(false);
        if(r.payload.statusCode === 200){
          toast.success("Coaching Change Request Submitted");
          dispatch(getCoaching({ limit, offset, searchText: "", request_status: "review" }))
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }
  }
  const openApproveAlert = (id, from) => {
    setIsDialogOpen(true);
    setIsOnApprove(true);
    setInstId(id);
    setActionFrom(from);
  }
  return (
    <>
      <Dialog maxWidth={isOnApprove ? "xs" : "md"} open={isDialogOpen} onClose={handleClose} fullWidth>
        {!isOnApprove ? <form onSubmit={handleSubmit} style={{padding: '0 20% 2% 20%'}}>
          <DialogContent>
            <Typography component="h2" variant="h2" 
              sx={{
                color: '#D60000',
                textAlign: 'center',
                fontSize: '28px',
                fontWeight: 'bold',
                margin: '24px 0 48px'}}>
              {isRequestModel ? 'Request changes' : 'Rejected'}
            </Typography>
            <div style={{
              width: '100%',
              border: '1px solid #000000',
              height: '290px'
            }} className="reject-buttons">
              <TextField fullWidth 
                placeholder="Write a reason for rejection" 
                variant="outlined"
                multiline
                rows={7}
                sx={{border: 'none'}}
                required
                onChange={(e) => setReviewFormBody({
                  ...reviewFormBody, changes: e.target.value.split("\n")
                })}/>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '36px 12px 0 0'
              }}>
                {!isRequestModel && <Button 
                  variant="contained" 
                  type="submit" 
                  sx={{
                    backgroundColor: '#DC0707',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '170px',
                    marginRight: '12px',
                    borderRadius: '8px'
                    }}>
                  {'Block'}
                </Button>}
                <Button 
                  variant="contained" 
                  type="submit" 
                  disabled={btnLoader}
                  sx={{
                    backgroundColor: btnLoader ? 'var(--disable-color)' : '#07AB35',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '140px',
                    marginLeft: '12px',
                    borderRadius: '8px'
                    }}>
                  {btnLoader ? <LoaderIcon /> :'Submit'}
                </Button>
              </div>
            </div>
          </DialogContent>
          {/* <DialogActions sx={{justifyContent: 'center'}}>
            <Button 
              variant="contained" 
              type="submit" 
              sx={{
                backgroundColor: '#07AB35',
                textTransform: 'capitalize',
                fontSize: '24px',
                width: '175px'
                }}>
              {'Submit'}
            </Button>
          </DialogActions> */}
        </form>:
        <div>
          <Typography component="p" variant="p" 
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              margin: '28px 0 48px'}}>
            Are you sure you want to<br/> approve the application form?
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: '0px 16px 28px 16px'
          }}>
            <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
              textTransform: 'capitalize'
            }}>
              Cancel
            </Button>
            <Button onClick={() => {approveClicked(instId, actionFrom)}} variant="contained" sx={{
              backgroundColor: 'var(--primary-color)',
              width: '84px',
              marginRight: '12px',
              textTransform: 'capitalize'
            }}>
              Yes
            </Button>
          </div>
        </div>}
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="meal"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <MealTable
          columns={type === "University" ? colR : colR2}
          where="recipes"
          rows={type === "University" ? (universityData && universityData?.map((item) => {
            const res = {
              ...item,
              action: (
                <div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => {
                        dispatch(setUniversityById(item._id));
                      }}
                    >
                      <Link to={routes.DETAILS}>
                        <VisibilityIcon sx={{color: '#3C1CDE'}} />
                        <Typography component="p" variant="p" 
                          sx={{
                            color: '#3C1CDE',
                            fontSize: '18px',
                            fontWeight: 'bold'
                            }}>
                          View
                        </Typography>
                      </Link>
                    </IconButton>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 8px'
                      }}
                      onClick={() => openApproveAlert(item, "approve")}
                    >
                      <CheckCircleOutlineIcon sx={{color: '#07AB35'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#07AB35',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '6px'
                          }}>
                        Approved
                      </Typography>
                    </IconButton>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      onClick={() => {
                        setIsRejectModel(false);
                        setIsDialogOpen(true);
                        setIsRequestModel(true);
                        setIsOnApprove(false);
                        setReviewFormBody({...reviewFormBody, id: item._id})
                      }}
                    >
                      <img src={NotesIcon} alt="notes_logo" width="20" />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#ECAB2D',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '9px'
                          }}>
                        Requested
                      </Typography>
                    </IconButton>
                  </div>
                </div>
              )
            };
            return res;
          })) :
          (coachingData && coachingData?.map((item) => {
            const res = {
              ...item,
              action: (
                <div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => {
                        dispatch(setUniversityById(item._id));
                      }}
                    >
                      <Link to={routes.DETAILS}>
                        <VisibilityIcon sx={{color: '#3C1CDE'}} />
                        <Typography component="p" variant="p" 
                          sx={{
                            color: '#3C1CDE',
                            fontSize: '18px',
                            fontWeight: 'bold'
                            }}>
                          View
                        </Typography>
                      </Link>
                    </IconButton>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 8px'
                      }}
                      onClick={() => openApproveAlert(item, "approve")}
                    >
                      <CheckCircleOutlineIcon sx={{color: '#07AB35'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#07AB35',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '6px'
                          }}>
                        Approved
                      </Typography>
                    </IconButton>
                    <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      onClick={() => {
                        setIsRejectModel(false);
                        setIsDialogOpen(true);
                        setIsRequestModel(true);
                        setReviewFormBody({...reviewFormBody, id: item._id})
                      }}
                    >
                      <img src={NotesIcon} alt="notes_logo" width="20" />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#ECAB2D',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '9px'
                          }}>
                        Requested
                      </Typography>
                    </IconButton>
                  </div>
                </div>
              )
            };
            return res;
          }))
        }
        />
      </div>
    </>
  );
};

export default Review;
