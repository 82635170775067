import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { 
  Button, 
  IconButton, 
  Typography, 
  TextField } from "@mui/material";
import { toast } from "react-toastify";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import InstProdTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import Spinner from "../../components/Spinner/Spinner";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Listing from '../../components/listing/Listing';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoaderIcon from "../../components/loader/Loader";
import { universityStatusUpdate } from "../../redux/universities/universitySlice";
import { coachingStatusUpdate } from "../../redux/coaching/coachingSlice";
import { 
    getProductById,
    productStatusUpdate,
    getProductByInstitute 
  } from "../../redux/instituteProd/instituteProdSlice";

let reviewBody = {
  id: "",
  request_status: "review",
  changes: [],
  information_check: 0,
  from: "review",
}

const InstituteProd = () => {
  const dispatch = useDispatch();
  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    productByInstitute,
    instituteIdForProduct
  } = useSelector((state) => state.institute_prod);
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRequestModel, setIsRequestModel] = useState(false);
  const [isListing, setIsListing] = useState(false);
  const [reviewFormBody, setReviewFormBody] = useState(reviewBody);
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate();

  const colR = ["SNo", "Name of Product", "Category", "Type", "syllabus", "actions"];
  const { type } = useSelector(
    (state) => state.university
  );

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
        let params = (type === "University" ? { limit, offset, university_id: instituteIdForProduct, searchText } :
            { limit, offset, coaching_id: instituteIdForProduct, searchText })
        const res = dispatch(getProductByInstitute(params));
        res?.then((r) => {
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
          }
        })
    }
  };

  if (isLoading) return <Spinner />;
  if (isError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if(type === "University"){
      setBtnLoader(true);
      const res = dispatch(universityStatusUpdate(reviewFormBody));
      res.then((r) => {
        setBtnLoader(false);
        if(r.payload.statusCode === 200){
          toast.success("Rejection Submitted");
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }else{
      setBtnLoader(true);
      const res = dispatch(coachingStatusUpdate(reviewFormBody));
      res.then((r) => {
        setBtnLoader(false);
        if(r.payload.statusCode === 200){
          toast.success("Change Request Submitted");
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }
  }

  const hideUnhideProduct = (id, visibility) => {
    let status = visibility === "visible" ? "hidden" : "visible";
    const res = dispatch(productStatusUpdate({id, status}));
    res.then((r) => {
      if(r.payload.statusCode === 200){ 
        toast.success("Product Status Updated");
        let params = (type === "University" ? { limit, offset, university_id: instituteIdForProduct } :
            { limit, offset, coaching_id: instituteIdForProduct })
        dispatch(getProductByInstitute(params));
        navigate(routes.PRODUCT_LIST);
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })

  }
  const productDetails = (id, type) => {
    const res = dispatch(getProductById(id));
    res.then((r) => {
        if(r.payload.statusCode === 200){
          (type === "edit" ? navigate(routes.Edit_PRODUCT) : 
          navigate(routes.VIEW_PRODUCT))
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
    })
  }

  return (
    <>
      <Dialog maxWidth="lg" open={isDialogOpen} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit} style={{padding: '0 20% 2% 20%'}}>
          <DialogContent>
            <Typography component="h2" variant="h2" 
              sx={{
                color: '#D60000',
                textAlign: 'center',
                fontSize: '46px',
                margin: '24px 0 48px'}}>
              {isRequestModel ? 'Request changes' : 'Rejected'}
            </Typography>
            <div style={{
              width: '100%',
              border: '1px solid #000000',
              height: '290px'
            }} className="reject-buttons">
              <TextField fullWidth 
                placeholder="Write a reason for rejection" 
                variant="outlined"
                multiline
                rows={7}
                sx={{border: 'none'}}
                required
                onChange={(e) => setReviewFormBody({
                  ...reviewFormBody, changes: e.target.value.split("\n")
                })}/>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '36px 12px 0 0'
              }}>
                {!isRequestModel && <Button 
                  variant="contained" 
                  type="submit" 
                  sx={{
                    backgroundColor: '#DC0707',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '170px',
                    marginRight: '12px',
                    borderRadius: '8px'
                    }}>
                  {'Block'}
                </Button>}
                <Button 
                  variant="contained" 
                  type="submit" 
                  disabled={btnLoader}
                  sx={{
                    backgroundColor: btnLoader ? 'var(--disable-color)' : '#07AB35',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '140px',
                    marginLeft: '12px',
                    borderRadius: '8px'
                    }}>
                  {btnLoader ? <LoaderIcon /> :'Submit'}
                </Button>
              </div>
            </div>
          </DialogContent>
        </form>
      </Dialog>
      {isListing ?
        <div>
            <Listing 
                from="product"
                back={(listing) => {setIsListing(listing)}}/>
        </div>:
        <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="product"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <div style={{
          display: 'flex', 
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: '12px',
          }}>
          <Button variant="contained" onClick={() => {
              setIsListing(true)
          }}
          sx={{
              backgroundColor: '#F69220',
              textTransform: 'capitalize',
              borderRadius: '8px'
              }}>
          <ControlPointIcon sx={{marginRight: '4px'}} />
              Add Product
          </Button>
        </div>
        <InstProdTable
          columns={colR}
          where="recipes"
          rows={productByInstitute && productByInstitute?.map((item) => {
            const res = {
              ...item,
              syllabus: (
                <a href={item?.syllabus_url} target="_blank" rel="noreferrer">
                    {item?.syllabus_url.split('/')[3]?.split('-')[1]}
                </a>
              ),
              actions: (
                <div style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                  <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => productDetails(item._id, "view")}
                      sx={{
                          margin: '0 8px 0 0px',
                          display: 'flex',
                          flexDirection: 'column'
                      }}
                  >
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                          sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '4px'
                          }}>
                          View
                      </Typography>
                  </IconButton>
                  <IconButton
                      color="inherit"
                      edge="start"
                      sx={{
                          display: 'flex',
                          flexDirection: 'column'
                      }}
                      onClick={() => hideUnhideProduct(item._id, item.status)}>
                      {item.status === "visible" ? <VisibilityOffIcon sx={{color: '#EF0000'}} /> : 
                        <VisibilityIcon sx={{color: '#07AB35'}} />}
                      <Typography component="p" variant="p" 
                      sx={{
                          color: item.status === "visible" ? '#EF0000' : '#07AB35',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '5px'
                          }}>
                      {item.status === "visible" ? "Hide" : "Unhide"}
                      </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          })}
        />
        </div>
        }
    </>
  );
};

export default InstituteProd;